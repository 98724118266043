import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLanguage } from '@/services/GNRL';
import { NovovilleBadge, Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import { translate } from '../../services/TranslationService';
import { goTo } from '../../shared/actions';

export const ReportItem = ({
  report,
  map,
  onClick = function () {},
  display,
  showImage = true,
  showDescription = false,
}) => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'en' ? 'en-GB' : 'el-GR', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  if (report === null) return null;

  return (
    <div className="flex sm:p-[40px] max-h-[calc(100vh-80px)] sm:pb-0 p-0">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 sm:overflow-auto overflow-y-scroll">
        <div
          key={report.id}
          className={`flex flex-grow gap-[8px] w-full cursor-pointer flex-col lg:flex-row`}
          onClick={() =>
            dispatch(
              goTo('reports', report.id, {
                report: {
                  id: report.id,
                  issue_name: report.issue_name,
                  status_name: report.status_name,
                  address: report.address,
                  photo: report.photo,
                  created_at: report.created_at,
                  description: report.description,
                },
              })
            )
          }
        >
          {showImage && (
            <div className="min-w-[172px] min-h-[90px]">
              <img
                src={report.photo}
                alt={report.issue_name}
                className="w-full h-full sm:w-[172px] sm:h-[134px] rounded-lg object-cover"
              />
            </div>
          )}
          <div className="flex flex-col justify-between gap-[8px] w-full">
            <div className="flex sm:flex-row flex-col sm:items-center md:items-center items-start justify-between gap-[8px]">
              <Typography className="font-open-sans font-semibold text-[16px] leading-[24px] text-left text-gray-800 underline hover:underline p-0 text-wrap">
                {translate(report.issue_name)}
              </Typography>
              <div className="flex flex-row sm:items-center md:items-center items-end justify-between gap-[16px]">
                <NovovilleBadge status={report.status_name} />
                <div>
                  <Typography variant="regular_16" className="text-gray-500 ">
                    {`#${report.id}`}
                  </Typography>
                </div>
              </div>
            </div>
            {showDescription && (
              <div>
                <Typography
                  variant="regular_16"
                  className="text-gray-700 text-wrap"
                >
                  {report.description}
                </Typography>
              </div>
            )}
            <div>
              <Typography
                variant="regular_16"
                className="text-gray-700 text-wrap"
              >
                {report.address}
              </Typography>
            </div>
            <div>
              <Typography
                variant="regular_14"
                className="text-gray-500 text-wrap"
              >
                {`${translate('Reported')} ${formatDate(report.created_at)}`}
              </Typography>
            </div>
          </div>
        </div>
        <Separator className="my-[24px]" />
        {map}
      </div>
    </div>
  );
};

export function getReportIcon(status_name) {
  return '/images2/reports/report_' + status_name + '.png';
}

export const ReportImage = ({ photoUrl }) => {
  if (!photoUrl || photoUrl === '') {
    photoUrl = '/images2/other/Default_image_messages.png';
  }

  return <img className="card-image-thumb" src={photoUrl} alt="report" />;
};
