import { getAuthorityForCurrentApplication } from "../../services/platformCheck"

//Doc:
//The authority is hardcoded for some applications but for some others it isn't.
//Examples: 
//In MyAthensPass the authority should always be considered athens regardless of the user's authority
//As such, userDerivedAuthority will check and return the authority accordingly depending on the use case
//because some components are used across apps ( like the SelectParkingDurationPresenter screen )

//1. Getting the Derived Authority ( this is the main function to be used unless there's a reason to use the user's authority explicitly)

//1.1 hook version
const useDerivedAuthority = () => {
  let userAuthority = useUserAuthority();
  let appAuthority = getAuthorityForCurrentApplication()

  return appAuthority || userAuthority
}

//1.2 function version for redux connect
const getDerivedAuthority = (state) => {
  let userAuthority = getUserAuthority(state);
  let appAuthority = getAuthorityForCurrentApplication()

  return appAuthority || userAuthority

}

//2 Getting the user's authority directly

//2.1 hook version
const useUserAuthority = () => {
}

//2.2 function version for redux connect
const getUserAuthority = (state) => {
  let userAuthority = state?.profileReducer?.profile?.authority?.name
  return userAuthority
}

export {
  //main functions to use
  useDerivedAuthority,
  getDerivedAuthority,
  //more specific functions, make sure you know what you're doing
  useUserAuthority,
  getUserAuthority,
}