const countries = [

    {
        file: '/images2/flags/GR.png',
        code: "GR",
        name: "Greece",
        country_code: "+30"
    },
    {
        file: '/images2/flags/GB.png',
        code: "GB",
        name: "United Kingdom",
        country_code: "+44"
    },
    {
        file: '/images2/flags/CH.png',
        code: "CH",
        name: "Switzerland",
        country_code: "+41"
    },
    {
        file: '/images2/flags/JE.png',
        code: "JE",
        name: "Jersey",
        country_code: "+44"
    },
    {
        file: '/images2/flags/AF.png',
        code: "AF",
        name: "Afghanistan",
        country_code: "+93"
    },
    {
        file: '/images2/flags/AX.png',
        code: "AX",
        name: "Aland Islands",
        country_code: "+358"
    },
    {
        file: '/images2/flags/AL.png',
        code: "AL",
        name: "Albania",
        country_code: "+355"
    },
    {
        file: '/images2/flags/DZ.png',
        code: "DZ",
        name: "Algeria",
        country_code: "+213"
    },
    {
        file: '/images2/flags/AS.png',
        code: "AS",
        name: "AmericanSamoa",
        country_code: "+1684"
    },
    {
        file: '/images2/flags/AO.png',
        code: "AO",
        name: "Angola",
        country_code: "+244"
    },
    {
        file: '/images2/flags/AD.png',
        code: "AD",
        name: "Andorra",
        country_code: "+376"
    },
    {
        file: '/images2/flags/AI.png',
        code: "AI",
        name: "Anguilla",
        country_code: "+1264"
    },
    {
        file: '/images2/flags/AR.png',
        code: "AR",
        name: "Argentina",
        country_code: "+54"
    },
    {
        file: '/images2/flags/AG.png',
        code: "AG",
        name: "Antigua and Barbuda",
        country_code: "+1268"
    },
    {
        file: '/images2/flags/AQ.png',
        code: "AQ",
        name: "Antarctica",
        country_code: "+672"
    },
    {
        file: '/images2/flags/AM.png',
        code: "AM",
        name: "Armenia",
        country_code: "+374"
    },
    {
        file: '/images2/flags/AW.png',
        code: "AW",
        name: "Aruba",
        country_code: "+297"
    },
    {
        file: '/images2/flags/AU.png',
        code: "AU",
        name: "Australia",
        country_code: "+61"
    },
    {
        file: '/images2/flags/AT.png',
        code: "AT",
        name: "Austria",
        country_code: "+43"
    },
    {
        file: '/images2/flags/AZ.png',
        code: "AZ",
        name: "Azerbaijan",
        country_code: "+994"
    },
    {
        file: '/images2/flags/BS.png',
        code: "BS",
        name: "Bahamas",
        country_code: "+1242"
    },
    {
        file: '/images2/flags/BH.png',
        code: "BH",
        name: "Bahrain",
        country_code: "+973"
    },
    {
        file: '/images2/flags/BD.png',
        code: "BD",
        name: "Bangladesh",
        country_code: "+880"
    },
    {
        file: '/images2/flags/BB.png',
        code: "BB",
        name: "Barbados",
        country_code: "+1246"
    },
    {
        file: '/images2/flags/BY.png',
        code: "BY",
        name: "Belarus",
        country_code: "+375"
    },
    {
        file: '/images2/flags/BE.png',
        code: "BE",
        name: "Belgium",
        country_code: "+32"
    },
    {
        file: '/images2/flags/BZ.png',
        code: "BZ",
        name: "Belize",
        country_code: "+501"
    },
    {
        file: '/images2/flags/BJ.png',
        code: "BJ",
        name: "Benin",
        country_code: "+229"
    },
    {
        file: '/images2/flags/BM.png',
        code: "BM",
        name: "Bermuda",
        country_code: "+1441"
    },
    {
        file: '/images2/flags/BT.png',
        code: "BT",
        name: "Bhutan",
        country_code: "+975"
    },
    {
        file: '/images2/flags/BO.png',
        code: "BO",
        name: "Bolivia, Plurinational State of",
        country_code: "+591"
    },
    {
        file: '/images2/flags/BA.png',
        code: "BA",
        name: "Bosnia and Herzegovina",
        country_code: "+387"
    },
    {
        file: '/images2/flags/BW.png',
        code: "BW",
        name: "Botswana",
        country_code: "+267"
    },
    {
        file: '/images2/flags/BR.png',
        code: "BR",
        name: "Brazil",
        country_code: "+55"
    },
    {
        file: '/images2/flags/IO.png',
        code: "IO",
        name: "British Indian Ocean Territory",
        country_code: "+246"
    },
    {
        file: '/images2/flags/BN.png',
        code: "BN",
        name: "Brunei Darussalam",
        country_code: "+673"
    },
    {
        file: '/images2/flags/BG.png',
        code: "BG",
        name: "Bulgaria",
        country_code: "+359"
    },
    {
        file: '/images2/flags/BF.png',
        code: "BF",
        name: "Burkina Faso",
        country_code: "+226"
    },
    {
        file: '/images2/flags/BI.png',
        code: "BI",
        name: "Burundi",
        country_code: "+257"
    },
    {
        file: '/images2/flags/KH.png',
        code: "KH",
        name: "Cambodia",
        country_code: "+855"
    },
    {
        file: '/images2/flags/CM.png',
        code: "CM",
        name: "Cameroon",
        country_code: "+237"
    },
    {
        file: '/images2/flags/CA.png',
        code: "CA",
        name: "Canada",
        country_code: "+1"
    },
    {
        file: '/images2/flags/CV.png',
        code: "CV",
        name: "Cape Verde",
        country_code: "+238"
    },
    {
        file: '/images2/flags/KY.png',
        code: "KY",
        name: "Cayman Islands",
        country_code: "+ 345"
    },
    {
        file: '/images2/flags/CF.png',
        code: "CF",
        name: "Central African Republic",
        country_code: "+236"
    },
    {
        file: '/images2/flags/TD.png',
        code: "TD",
        name: "Chad",
        country_code: "+235"
    },
    {
        file: '/images2/flags/CL.png',
        code: "CL",
        name: "Chile",
        country_code: "+56"
    },
    {
        file: '/images2/flags/CN.png',
        code: "CN",
        name: "China",
        country_code: "+86"
    },
    {
        file: '/images2/flags/CX.png',
        code: "CX",
        name: "Christmas Island",
        country_code: "+61"
    },
    {
        file: '/images2/flags/CC.png',
        code: "CC",
        name: "Cocos (Keeling) Islands",
        country_code: "+61"
    },
    {
        file: '/images2/flags/CO.png',
        code: "CO",
        name: "Colombia",
        country_code: "+57"
    },
    {
        file: '/images2/flags/KM.png',
        code: "KM",
        name: "Comoros",
        country_code: "+269"
    },
    {
        file: '/images2/flags/CG.png',
        code: "CG",
        name: "Congo",
        country_code: "+242"
    },
    {
        file: '/images2/flags/CD.png',
        code: "CD",
        name: "Congo, The Democratic Republic of the Congo",
        country_code: "+243"
    },
    {
        file: '/images2/flags/CK.png',
        code: "CK",
        name: "Cook Islands",
        country_code: "+682"
    },
    {
        file: '/images2/flags/CR.png',
        code: "CR",
        name: "Costa Rica",
        country_code: "+506"
    },
    {
        file: '/images2/flags/CI.png',
        code: "CI",
        name: "Cote d'Ivoire",
        country_code: "+225"
    },
    {
        file: '/images2/flags/HR.png',
        code: "HR",
        name: "Croatia",
        country_code: "+385"
    },
    {
        file: '/images2/flags/CU.png',
        code: "CU",
        name: "Cuba",
        country_code: "+53"
    },
    {
        file: '/images2/flags/CY.png',
        code: "CY",
        name: "Cyprus",
        country_code: "+357"
    },
    {
        file: '/images2/flags/CZ.png',
        code: "CZ",
        name: "Czech Republic",
        country_code: "+420"
    },
    {
        file: '/images2/flags/DK.png',
        code: "DK",
        name: "Denmark",
        country_code: "+45"
    },
    {
        file: '/images2/flags/DJ.png',
        code: "DJ",
        name: "Djibouti",
        country_code: "+253"
    },
    {
        file: '/images2/flags/DM.png',
        code: "DM",
        name: "Dominica",
        country_code: "+1767"
    },
    {
        file: '/images2/flags/DO.png',
        code: "DO",
        name: "Dominican Republic",
        country_code: "+1849"
    },
    {
        file: '/images2/flags/EC.png',
        code: "EC",
        name: "Ecuador",
        country_code: "+593"
    },
    {
        file: '/images2/flags/EG.png',
        code: "EG",
        name: "Egypt",
        country_code: "+20"
    },
    {
        file: '/images2/flags/SV.png',
        code: "SV",
        name: "El Salvador",
        country_code: "+503"
    },
    {
        file: '/images2/flags/GQ.png',
        code: "GQ",
        name: "Equatorial Guinea",
        country_code: "+240"
    },
    {
        file: '/images2/flags/ER.png',
        code: "ER",
        name: "Eritrea",
        country_code: "+291"
    },
    {
        file: '/images2/flags/EE.png',
        code: "EE",
        name: "Estonia",
        country_code: "+372"
    },
    {
        file: '/images2/flags/ET.png',
        code: "ET",
        name: "Ethiopia",
        country_code: "+251"
    },
    {
        file: '/images2/flags/FK.png',
        code: "FK",
        name: "Falkland Islands (Malvinas)",
        country_code: "+500"
    },
    {
        file: '/images2/flags/FO.png',
        code: "FO",
        name: "Faroe Islands",
        country_code: "+298"
    },
    {
        file: '/images2/flags/FJ.png',
        code: "FJ",
        name: "Fiji",
        country_code: "+679"
    },
    {
        file: '/images2/flags/FI.png',
        code: "FI",
        name: "Finland",
        country_code: "+358"
    },
    {
        file: '/images2/flags/FR.png',
        code: "FR",
        name: "France",
        country_code: "+33"
    },
    {
        file: '/images2/flags/GF.png',
        code: "GF",
        name: "French Guiana",
        country_code: "+594"
    },
    {
        file: '/images2/flags/PF.png',
        code: "PF",
        name: "French Polynesia",
        country_code: "+689"
    },
    {
        file: '/images2/flags/GA.png',
        code: "GA",
        name: "Gabon",
        country_code: "+241"
    },
    {
        file: '/images2/flags/GM.png',
        code: "GM",
        name: "Gambia",
        country_code: "+220"
    },
    {
        file: '/images2/flags/DE.png',
        code: "DE",
        name: "Germany",
        country_code: "+49"
    },
    {
        file: '/images2/flags/GE.png',
        code: "GE",
        name: "Georgia",
        country_code: "+995"
    },
    {
        file: '/images2/flags/GH.png',
        code: "GH",
        name: "Ghana",
        country_code: "+233"
    },
    {
        file: '/images2/flags/GI.png',
        code: "GI",
        name: "Gibraltar",
        country_code: "+350"
    },
    {
        file: '/images2/flags/GP.png',
        code: "GP",
        name: "Guadeloupe",
        country_code: "+590"
    },
    {
        file: '/images2/flags/GD.png',
        code: "GD",
        name: "Grenada",
        country_code: "+1473"
    },
    {
        file: '/images2/flags/GL.png',
        code: "GL",
        name: "Greenland",
        country_code: "+299"
    },
    {
        file: '/images2/flags/GU.png',
        code: "GU",
        name: "Guam",
        country_code: "+1671"
    },
    {
        file: '/images2/flags/GT.png',
        code: "GT",
        name: "Guatemala",
        country_code: "+502"
    },
    {
        file: '/images2/flags/GG.png',
        code: "GG",
        name: "Guernsey",
        country_code: "+44"
    },
    {
        file: '/images2/flags/GN.png',
        code: "GN",
        name: "Guinea",
        country_code: "+224"
    },
    {
        file: '/images2/flags/GW.png',
        code: "GW",
        name: "Guinea-Bissau",
        country_code: "+245"
    },
    {
        file: '/images2/flags/GY.png',
        code: "GY",
        name: "Guyana",
        country_code: "+595"
    },
    {
        file: '/images2/flags/HT.png',
        code: "HT",
        name: "Haiti",
        country_code: "+509"
    },
    {
        file: '/images2/flags/VA.png',
        code: "VA",
        name: "Holy See (Vatican City State)",
        country_code: "+379"
    },
    {
        file: '/images2/flags/HN.png',
        code: "HN",
        name: "Honduras",
        country_code: "+504"
    },
    {
        file: '/images2/flags/HK.png',
        code: "HK",
        name: "Hong Kong",
        country_code: "+852"
    },
    {
        file: '/images2/flags/HU.png',
        code: "HU",
        name: "Hungary",
        country_code: "+36"
    },
    {
        file: '/images2/flags/IS.png',
        code: "IS",
        name: "Iceland",
        country_code: "+354"
    },
    {
        file: '/images2/flags/IN.png',
        code: "IN",
        name: "India",
        country_code: "+91"
    },
    {
        file: '/images2/flags/ID.png',
        code: "ID",
        name: "Indonesia",
        country_code: "+62"
    },
    {
        file: '/images2/flags/IQ.png',
        code: "IQ",
        name: "Iraq",
        country_code: "+964"
    },
    {
        file: '/images2/flags/IR.png',
        code: "IR",
        name: "Iran, Islamic Republic of Persian Gulf",
        country_code: "+98"
    },
    {
        file: '/images2/flags/IE.png',
        code: "IE",
        name: "Ireland",
        country_code: "+353"
    },
    {
        file: '/images2/flags/IM.png',
        code: "IM",
        name: "Isle of Man",
        country_code: "+44"
    },
    {
        file: '/images2/flags/IL.png',
        code: "IL",
        name: "Israel",
        country_code: "+972"
    },
    {
        file: '/images2/flags/IT.png',
        code: "IT",
        name: "Italy",
        country_code: "+39"
    },
    {
        file: '/images2/flags/JM.png',
        code: "JM",
        name: "Jamaica",
        country_code: "+1876"
    },
    {
        file: '/images2/flags/JP.png',
        code: "JP",
        name: "Japan",
        country_code: "+81"
    },
    {
        file: '/images2/flags/JO.png',
        code: "JO",
        name: "Jordan",
        country_code: "+962"
    },
    {
        file: '/images2/flags/KZ.png',
        code: "KZ",
        name: "Kazakhstan",
        country_code: "+7"
    },
    {
        file: '/images2/flags/KE.png',
        code: "KE",
        name: "Kenya",
        country_code: "+254"
    },
    {
        file: '/images2/flags/KI.png',
        code: "KI",
        name: "Kiribati",
        country_code: "+686"
    },
    {
        file: '/images2/flags/KP.png',
        code: "KP",
        name: "Korea, Democratic People's Republic of Korea",
        country_code: "+850"
    },
    {
        file: '/images2/flags/KR.png',
        code: "KR",
        name: "Korea, Republic of South Korea",
        country_code: "+82"
    },
    {
        file: '/images2/flags/KW.png',
        code: "KW",
        name: "Kuwait",
        country_code: "+965"
    },
    {
        file: '/images2/flags/KG.png',
        code: "KG",
        name: "Kyrgyzstan",
        country_code: "+996"
    },
    {
        file: '/images2/flags/LA.png',
        code: "LA",
        name: "Laos",
        country_code: "+856"
    },
    {
        file: '/images2/flags/LV.png',
        code: "LV",
        name: "Latvia",
        country_code: "+371"
    },
    {
        file: '/images2/flags/LB.png',
        code: "LB",
        name: "Lebanon",
        country_code: "+961"
    },
    {
        file: '/images2/flags/LS.png',
        code: "LS",
        name: "Lesotho",
        country_code: "+266"
    },
    {
        file: '/images2/flags/LR.png',
        code: "LR",
        name: "Liberia",
        country_code: "+231"
    },
    {
        file: '/images2/flags/LY.png',
        code: "LY",
        name: "Libyan Arab Jamahiriya",
        country_code: "+218"
    },
    {
        file: '/images2/flags/LI.png',
        code: "LI",
        name: "Liechtenstein",
        country_code: "+423"
    },
    {
        file: '/images2/flags/LT.png',
        code: "LT",
        name: "Lithuania",
        country_code: "+370"
    },
    {
        file: '/images2/flags/LU.png',
        code: "LU",
        name: "Luxembourg",
        country_code: "+352"
    },
    {
        file: '/images2/flags/MO.png',
        code: "MO",
        name: "Macao",
        country_code: "+853"
    },
    {
        file: '/images2/flags/MK.png',
        code: "MK",
        name: "Macedonia",
        country_code: "+389"
    },
    {
        file: '/images2/flags/MG.png',
        code: "MG",
        name: "Madagascar",
        country_code: "+261"
    },
    {
        file: '/images2/flags/MW.png',
        code: "MW",
        name: "Malawi",
        country_code: "+265"
    },
    {
        file: '/images2/flags/MY.png',
        code: "MY",
        name: "Malaysia",
        country_code: "+60"
    },
    {
        file: '/images2/flags/MV.png',
        code: "MV",
        name: "Maldives",
        country_code: "+960"
    },
    {
        file: '/images2/flags/ML.png',
        code: "ML",
        name: "Mali",
        country_code: "+223"
    },
    {
        file: '/images2/flags/MT.png',
        code: "MT",
        name: "Malta",
        country_code: "+356"
    },
    {
        file: '/images2/flags/MH.png',
        code: "MH",
        name: "Marshall Islands",
        country_code: "+692"
    },
    {
        file: '/images2/flags/MQ.png',
        code: "MQ",
        name: "Martinique",
        country_code: "+596"
    },
    {
        file: '/images2/flags/MR.png',
        code: "MR",
        name: "Mauritania",
        country_code: "+222"
    },
    {
        file: '/images2/flags/MU.png',
        code: "MU",
        name: "Mauritius",
        country_code: "+230"
    },
    {
        file: '/images2/flags/YT.png',
        code: "YT",
        name: "Mayotte",
        country_code: "+262"
    },
    {
        file: '/images2/flags/MX.png',
        code: "MX",
        name: "Mexico",
        country_code: "+52"
    },
    {
        file: '/images2/flags/FM.png',
        code: "FM",
        name: "Micronesia, Federated States of Micronesia",
        country_code: "+691"
    },
    {
        file: '/images2/flags/MD.png',
        code: "MD",
        name: "Moldova",
        country_code: "+373"
    },
    {
        file: '/images2/flags/MC.png',
        code: "MC",
        name: "Monaco",
        country_code: "+377"
    },
    {
        file: '/images2/flags/MN.png',
        code: "MN",
        name: "Mongolia",
        country_code: "+976"
    },
    {
        file: '/images2/flags/MS.png',
        code: "MS",
        name: "Montserrat",
        country_code: "+1664"
    },
    {
        file: '/images2/flags/ME.png',
        code: "ME",
        name: "Montenegro",
        country_code: "+382"
    },
    {
        file: '/images2/flags/MA.png',
        code: "MA",
        name: "Morocco",
        country_code: "+212"
    },
    {
        file: '/images2/flags/MZ.png',
        code: "MZ",
        name: "Mozambique",
        country_code: "+258"
    },
    {
        file: '/images2/flags/MM.png',
        code: "MM",
        name: "Myanmar",
        country_code: "+95"
    },
    {
        file: '/images2/flags/NA.png',
        code: "NA",
        name: "Namibia",
        country_code: "+264"
    },
    {
        file: '/images2/flags/NR.png',
        code: "NR",
        name: "Nauru",
        country_code: "+674"
    },
    {
        file: '/images2/flags/NP.png',
        code: "NP",
        name: "Nepal",
        country_code: "+977"
    },
    {
        file: '/images2/flags/NL.png',
        code: "NL",
        name: "Netherlands",
        country_code: "+31"
    },
    {
        file: '/images2/flags/AN.png',
        code: "AN",
        name: "Netherlands Antilles",
        country_code: "+599"
    },
    {
        file: '/images2/flags/NC.png',
        code: "NC",
        name: "New Caledonia",
        country_code: "+687"
    },
    {
        file: '/images2/flags/NZ.png',
        code: "NZ",
        name: "New Zealand",
        country_code: "+64"
    },
    {
        file: '/images2/flags/NI.png',
        code: "NI",
        name: "Nicaragua",
        country_code: "+505"
    },
    {
        file: '/images2/flags/NE.png',
        code: "NE",
        name: "Niger",
        country_code: "+227"
    },
    {
        file: '/images2/flags/NG.png',
        code: "NG",
        name: "Nigeria",
        country_code: "+234"
    },
    {
        file: '/images2/flags/NU.png',
        code: "NU",
        name: "Niue",
        country_code: "+683"
    },
    {
        file: '/images2/flags/NF.png',
        code: "NF",
        name: "Norfolk Island",
        country_code: "+672"
    },
    {
        file: '/images2/flags/NO.png',
        code: "NO",
        name: "Norway",
        country_code: "+47"
    },
    {
        file: '/images2/flags/MP.png',
        code: "MP",
        name: "Northern Mariana Islands",
        country_code: "+1670"
    },
    {
        file: '/images2/flags/OM.png',
        code: "OM",
        name: "Oman",
        country_code: "+968"
    },
    {
        file: '/images2/flags/PW.png',
        code: "PW",
        name: "Palau",
        country_code: "+680"
    },
    {
        file: '/images2/flags/PK.png',
        code: "PK",
        name: "Pakistan",
        country_code: "+92"
    },
    {
        file: '/images2/flags/PS.png',
        code: "PS",
        name: "Palestinian Territory, Occupied",
        country_code: "+970"
    },
    {
        file: '/images2/flags/PA.png',
        code: "PA",
        name: "Panama",
        country_code: "+507"
    },
    {
        file: '/images2/flags/PG.png',
        code: "PG",
        name: "Papua New Guinea",
        country_code: "+675"
    },
    {
        file: '/images2/flags/PY.png',
        code: "PY",
        name: "Paraguay",
        country_code: "+595"
    },
    {
        file: '/images2/flags/PE.png',
        code: "PE",
        name: "Peru",
        country_code: "+51"
    },
    {
        file: '/images2/flags/PH.png',
        code: "PH",
        name: "Philippines",
        country_code: "+63"
    },
    {
        file: '/images2/flags/PN.png',
        code: "PN",
        name: "Pitcairn",
        country_code: "+872"
    },
    {
        file: '/images2/flags/PL.png',
        code: "PL",
        name: "Poland",
        country_code: "+48"
    },
    {
        file: '/images2/flags/PT.png',
        code: "PT",
        name: "Portugal",
        country_code: "+351"
    },
    {
        file: '/images2/flags/PR.png',
        code: "PR",
        name: "Puerto Rico",
        country_code: "+1939"
    },
    {
        file: '/images2/flags/QA.png',
        code: "QA",
        name: "Qatar",
        country_code: "+974"
    },
    {
        file: '/images2/flags/RO.png',
        code: "RO",
        name: "Romania",
        country_code: "+40"
    },
    {
        file: '/images2/flags/RU.png',
        code: "RU",
        name: "Russia",
        country_code: "+7"
    },
    {
        file: '/images2/flags/RW.png',
        code: "RW",
        name: "Rwanda",
        country_code: "+250"
    },
    {
        file: '/images2/flags/RE.png',
        code: "RE",
        name: "Reunion",
        country_code: "+262"
    },
    {
        file: '/images2/flags/BL.png',
        code: "BL",
        name: "Saint Barthelemy",
        country_code: "+590"
    },
    {
        file: '/images2/flags/KN.png',
        code: "KN",
        name: "Saint Kitts and Nevis",
        country_code: "+1869"
    },
    {
        file: '/images2/flags/SH.png',
        code: "SH",
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        country_code: "+290"
    },
    {
        file: '/images2/flags/LC.png',
        code: "LC",
        name: "Saint Lucia",
        country_code: "+1758"
    },
    {
        file: '/images2/flags/MF.png',
        code: "MF",
        name: "Saint Martin",
        country_code: "+590"
    },
    {
        file: '/images2/flags/PM.png',
        code: "PM",
        name: "Saint Pierre and Miquelon",
        country_code: "+508"
    },
    {
        file: '/images2/flags/VC.png',
        code: "VC",
        name: "Saint Vincent and the Grenadines",
        country_code: "+1784"
    },
    {
        file: '/images2/flags/WS.png',
        code: "WS",
        name: "Samoa",
        country_code: "+685"
    },
    {
        file: '/images2/flags/ST.png',
        code: "ST",
        name: "Sao Tome and Principe",
        country_code: "+239"
    },
    {
        file: '/images2/flags/SM.png',
        code: "SM",
        name: "San Marino",
        country_code: "+378"
    },
    {
        file: '/images2/flags/SN.png',
        code: "SN",
        name: "Senegal",
        country_code: "+221"
    },
    {
        file: '/images2/flags/SA.png',
        code: "SA",
        name: "Saudi Arabia",
        country_code: "+966"
    },
    {
        file: '/images2/flags/RS.png',
        code: "RS",
        name: "Serbia",
        country_code: "+381"
    },
    {
        file: '/images2/flags/SC.png',
        code: "SC",
        name: "Seychelles",
        country_code: "+248"
    },
    {
        file: '/images2/flags/SL.png',
        code: "SL",
        name: "Sierra Leone",
        country_code: "+232"
    },
    {
        file: '/images2/flags/SG.png',
        code: "SG",
        name: "Singapore",
        country_code: "+65"
    },
    {
        file: '/images2/flags/SK.png',
        code: "SK",
        name: "Slovakia",
        country_code: "+421"
    },
    {
        file: '/images2/flags/SI.png',
        code: "SI",
        name: "Slovenia",
        country_code: "+386"
    },
    {
        file: '/images2/flags/SB.png',
        code: "SB",
        name: "Solomon Islands",
        country_code: "+677"
    },
    {
        file: '/images2/flags/SO.png',
        code: "SO",
        name: "Somalia",
        country_code: "+252"
    },
    {
        file: '/images2/flags/ZA.png',
        code: "ZA",
        name: "South Africa",
        country_code: "+27"
    },
    {
        file: '/images2/flags/SS.png',
        code: "SS",
        name: "South Sudan",
        country_code: "+211"
    },
    {
        file: '/images2/flags/GS.png',
        code: "GS",
        name: "South Georgia and the South Sandwich Islands",
        country_code: "+500"
    },
    {
        file: '/images2/flags/ES.png',
        code: "ES",
        name: "Spain",
        country_code: "+34"
    },
    {
        file: '/images2/flags/LK.png',
        code: "LK",
        name: "Sri Lanka",
        country_code: "+94"
    },
    {
        file: '/images2/flags/SD.png',
        code: "SD",
        name: "Sudan",
        country_code: "+249"
    },
    {
        file: '/images2/flags/SR.png',
        code: "SR",
        name: "Suriname",
        country_code: "+597"
    },
    {
        file: '/images2/flags/SJ.png',
        code: "SJ",
        name: "Svalbard and Jan Mayen",
        country_code: "+47"
    },
    {
        file: '/images2/flags/SZ.png',
        code: "SZ",
        name: "Swaziland",
        country_code: "+268"
    },
    {
        file: '/images2/flags/SE.png',
        code: "SE",
        name: "Sweden",
        country_code: "+46"
    },
    {
        file: '/images2/flags/SY.png',
        code: "SY",
        name: "Syrian Arab Republic",
        country_code: "+963"
    },
    {
        file: '/images2/flags/TW.png',
        code: "TW",
        name: "Taiwan",
        country_code: "+886"
    },
    {
        file: '/images2/flags/TJ.png',
        code: "TJ",
        name: "Tajikistan",
        country_code: "+992"
    },
    {
        file: '/images2/flags/TZ.png',
        code: "TZ",
        name: "Tanzania, United Republic of Tanzania",
        country_code: "+255"
    },
    {
        file: '/images2/flags/TH.png',
        code: "TH",
        name: "Thailand",
        country_code: "+66"
    },
    {
        file: '/images2/flags/TL.png',
        code: "TL",
        name: "Timor-Leste",
        country_code: "+670"
    },
    {
        file: '/images2/flags/TG.png',
        code: "TG",
        name: "Togo",
        country_code: "+228"
    },
    {
        file: '/images2/flags/TK.png',
        code: "TK",
        name: "Tokelau",
        country_code: "+690"
    },
    {
        file: '/images2/flags/TT.png',
        code: "TT",
        name: "Trinidad and Tobago",
        country_code: "+1868"
    },
    {
        file: '/images2/flags/TO.png',
        code: "TO",
        name: "Tonga",
        country_code: "+676"
    },
    {
        file: '/images2/flags/TM.png',
        code: "TM",
        name: "Turkmenistan",
        country_code: "+993"
    },
    {
        file: '/images2/flags/TN.png',
        code: "TN",
        name: "Tunisia",
        country_code: "+216"
    },
    {
        file: '/images2/flags/TR.png',
        code: "TR",
        name: "Turkey",
        country_code: "+90"
    },
    {
        file: '/images2/flags/TC.png',
        code: "TC",
        name: "Turks and Caicos Islands",
        country_code: "+1649"
    },
    {
        file: '/images2/flags/TV.png',
        code: "TV",
        name: "Tuvalu",
        country_code: "+688"
    },
    {
        file: '/images2/flags/UA.png',
        code: "UA",
        name: "Ukraine",
        country_code: "+380"
    },
    {
        file: '/images2/flags/UG.png',
        code: "UG",
        name: "Uganda",
        country_code: "+256"
    },
    {
        file: '/images2/flags/AE.png',
        code: "AE",
        name: "United Arab Emirates",
        country_code: "+971"
    },
    {
        file: '/images2/flags/US.png',
        code: "US",
        name: "United States",
        country_code: "+1"
    },
    {
        file: '/images2/flags/UY.png',
        code: "UY",
        name: "Uruguay",
        country_code: "+598"
    },
    {
        file: '/images2/flags/VE.png',
        code: "VE",
        name: "Venezuela, Bolivarian Republic of Venezuela",
        country_code: "+58"
    },
    {
        file: '/images2/flags/VU.png',
        code: "VU",
        name: "Vanuatu",
        country_code: "+678"
    },
    {
        file: '/images2/flags/UZ.png',
        code: "UZ",
        name: "Uzbekistan",
        country_code: "+998"
    },
    {
        file: '/images2/flags/VN.png',
        code: "VN",
        name: "Vietnam",
        country_code: "+84"
    },
    {
        file: '/images2/flags/VG.png',
        code: "VG",
        name: "Virgin Islands, British",
        country_code: "+1284"
    },
    {
        file: '/images2/flags/WF.png',
        code: "WF",
        name: "Wallis and Futuna",
        country_code: "+681"
    },
    {
        file: '/images2/flags/VI.png',
        code: "VI",
        name: "Virgin Islands, U.S.",
        country_code: "+1340"
    },
    {
        file: '/images2/flags/YE.png',
        code: "YE",
        name: "Yemen",
        country_code: "+967"
    },
    {
        file: '/images2/flags/ZM.png',
        code: "ZM",
        name: "Zambia",
        country_code: "+260"
    },
    {
        file: '/images2/flags/ZW.png',
        code: "ZW",
        name: "Zimbabwe",
        country_code: "+263"
    }]

export default countries