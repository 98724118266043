import React from 'react';
import { Typography } from '@/components/novoville';
import { Checkbox } from '@/components/ui/checkbox';

/**
 * Shows a select list item. Used in lists where items are selectable such as select authority or filters or issue.
 *
 * @param {boolean} isChecked - whether to show a checkbox or not
 * @param {string} text - the text on the element
 * @param {func} onClick - what happens when the element is clicked
 * @param {string} className - additional class names
 */

const MyCityListItem = ({ isChecked, text, value, onClick, className }) => {
  const iconStyle =
    'data-[state=checked]:bg-strongCyan data-[state=checked]:text-gray-800 data-[state=checked]:border-0 rounded-[2px] border-gray-500';

  return (
    <div
      className={`flex items-center p-4 border rounded-lg cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div
        className={`flex items-center justify-center w-6 h-6 mr-4 rounded-[6px]`}
      >
        <Checkbox checked={isChecked} className={`w-6 h-6 ${iconStyle}`} />
      </div>
      <Typography
        variant={`${isChecked ? 'bold_16' : 'regular_16'}`}
        className="text-gray-600"
      >
        {`${text} ${value ? `(${value})` : ''}`}
      </Typography>
    </div>
  );
};

export default MyCityListItem;
