import * as ATypes from "../../shared/actionTypes";

export const calendarReducer = (state, action) => {

    if (!state) {
        state = {
            calendarData: []
        }
    }

    switch (action.type) {
        case ATypes.GET_CALENDAR_RES:
            return {
                ...state,
                calendarData: action.payload,
            };
        default:
            return state;
    }
};