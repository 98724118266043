import React from 'react';
import LanguageSelect from './LanguageSelect';
import '../../output.css';

const AuthHeader = ({ logo, handleLanguageChange, disabled }) => {
  return (
    <div className="mt-[100px] w-screen">
      <div className="fixed top-0 w-full h-[100px] z-50 bg-white flex justify-center items-center border-b-2 border-gray-200">
        <a href={disabled ? '#' : '/'}>
          <img
            src={logo}
            alt="logo"
            className="sm:w-[170px] w-[110px]"
          />
        </a>
        <div className="absolute right-0 pr-4">
          <LanguageSelect onLanguageChange={handleLanguageChange} />
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
