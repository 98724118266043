import * as ATypes from "../../shared/actionTypes";

export const myCityReducer = (state, action) => {

    if (!state) {
        state = {
            reports: [],
            pois: [],
            activeFilters: [{ name: "REPORTS" }, { name: "ADOPT_A_TREE" }],
            placemarks: {}
        }
    }

    switch (action.type) {
        case ATypes.GET_REPORTS_FOR_AUTHORITY_RES:

            return {
                ...state,
                reports: action.payload
            };
        case ATypes.GET_POIS_FOR_AUTHORITY_RES:

            return {
                ...state,
                pois: action.payload
            };

        case ATypes.GET_POI_TYPES_FOR_AUTHORITY_RES:
            const poiTypes = {}
            action.payload.forEach(item => {
                poiTypes[`${item.name}`] = { uri: item.pin_url }
            })
            return {
                ...state,
                poiTypes
            };
        case ATypes.SET_ACTIVE_FILTERS:
            return {
                ...state,
                activeFilters: JSON.parse(JSON.stringify(action.payload))
            };
        case ATypes.SUBSCRIBE_TO_POI_RES:
            return {
                ...state,
                pois: state.pois.map(poi => {
                    if (poi.id === action.payload.poiId) {
                        const a = JSON.parse(JSON.stringify(poi))
                        a.is_subscribed = true;
                        a.user_subscriptions_count = a.user_subscriptions_count + 1;
                        if (action.payload.adoptionId) {
                            a.adoption_id = action.payload.adoptionId;
                        }
                        return a;
                    }
                    return poi;
                })
            }
        case ATypes.WATER_THE_TREE_RES:
            return {
                ...state,
                pois: state.pois.map(poi => {
                    if (poi.id === action.payload) {
                        const a = JSON.parse(JSON.stringify(poi))
                        a.needs_watering = false;
                        return a;
                    }
                    return poi;
                })
            }
        case ATypes.UNSUBSCRIBE_FROM_POI_RES:
            return {
                ...state,
                pois: state.pois.map(poi => {
                    if (poi.id === action.payload) {
                        const a = JSON.parse(JSON.stringify(poi))
                        a.is_subscribed = false;
                        a.user_subscriptions_count = a.user_subscriptions_count - 1;
                        a.adoption_id = null
                        return a;
                    }
                    return poi;
                })
            }
        case ATypes.GET_PLACEMARKS_RES:
            if (action.info === "city_placemarks") {
                return {
                    ...state,
                    placemarks: action.payload
                }
            }
            else {
                return state
            }
        default:
            return state;
    }
};