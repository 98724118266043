import React from 'react'
import { Layer, Source, Feature } from 'react-mapbox-gl';

import InfoWindow from './MyCityInfoWindow'

const styles = {
  clusteredPoints: {
    "circle-pitch-alignment": 'map',
    "circle-color": [
      'step',
      ['get', 'point_count'],
      '#51bbd6',
      100,
      '#f1f075',
      750,
      '#f28cb1',
    ],
    "circle-radius": ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    "circle-opacity": 0.84,
    "circle-stroke-width": 2,
    "circle-stroke-color": 'white',
  },
  clusterCount: {
    "text-field": '{point_count}',
    "text-size": 12,
    "text-font": [
      "DIN Offc Pro Medium",
      "Arial Unicode MS Bold"
    ],
  },
};

const ClustersAndMarkes = props => {

  const {
    zoom,
    goTo,
    markerHtmlArray,
    isFromCity,
    userLocation,
    selectedPoint,
    subscribeToPoi,
    onClickCluster,
    openInfoWindow,
    showInfoWindow,
    closeInfoWindow,
    featureCollection,
    unsubscribeFromPoi,
  } = props
  
  const createLayerWithMarkers = (markerHtmlArray) => {
    return markerHtmlArray.map((marker, index) => {
      return (
        <Layer
          key={marker[0] + index}
          type={'symbol'}
          id={`unclustered-point-${marker[0] + index}`}
          sourceId={'points'}
          filter={('all',
          ['!', ['has', 'point_count']],
          ["==", "iconName", `${marker[0]}`]
        )}
          images={marker}
          layout={{
            "icon-allow-overlap": true,
            "icon-image": `${marker[0]}`
          }}
          onClick={openInfoWindow}
        />
      )
    })

  }

  return (
    <>
      {isFromCity ?
        <>
          <Source
            id={'points'}
            buffer={512}
            geoJsonSource={{
              type: 'geojson',
              data: featureCollection,
              cluster: true,
              clusterMaxZoom: zoom[0],
              clusterRadius: 80,

            }}
          />
          <Layer
            type={'circle'}
            id={`clusters`}
            sourceId={'points'}
            filter={['has', 'point_count']}
            paint={styles.clusteredPoints}
            onClick={onClickCluster}
          />
          <Layer
            type={'symbol'}
            id={`cluster-count`}
            sourceId={'points'}
            filter={["has", "point_count"]}
            layout={styles.clusterCount}
            paint={{ "text-color": 'black' }}
          />
          {createLayerWithMarkers(markerHtmlArray)}
          {showInfoWindow &&
            <InfoWindow
              goTo={goTo}
              markerHtmlArray={markerHtmlArray}
              userLocation={userLocation}
              selectedPoint={selectedPoint}
              subscribeToPoi={subscribeToPoi}
              closeInfoWindow={closeInfoWindow}
              unsubscribeFromPoi={unsubscribeFromPoi}
              coordinates={[parseFloat(selectedPoint.longitude), parseFloat(selectedPoint.latitude)]}
            />
          }
        </>
        :
        <Layer
          type={'symbol'}
          id={`custom-marker`}
          images={markerHtmlArray}
          layout={{
            "icon-allow-overlap": true,
            "icon-image": `${markerHtmlArray[0]}`
          }}
        >
          <Feature coordinates={[parseFloat(selectedPoint.longitude), parseFloat(selectedPoint.latitude)]} />
        </Layer>
      }
    </>
  )
}

export default ClustersAndMarkes



