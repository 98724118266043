export function getMainColor() {
  return "#AABBCC";
}

export function makeid() {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function properlyFormatPollsApiMessages(data) {
  //TODO: CHANGE THIS FUNCTION AS IT CURRENTLY CAUSES SIDEFFECTS
  if (!data) {
    return []; //should this be the one defaulting or defaulting in the reducer?
  }
  let dataToParse = [];
  if (!Array.isArray(data)) {
    dataToParse.push(data)

  }
  else {
    dataToParse = data
  }
  return dataToParse.map(message => {
    if (message.app_type === "POLL") {
      message.questions = [];

      var pollObject = JSON.parse(message.body)
      for (var key in pollObject) {
        if (!isNaN(key)) {

          var ans = pollObject[key].answers
          var answersArray = [];
          for (var indx in ans) {
            var val = ans[indx]
            var key2 = indx
            answersArray.push({ id: key2, value: val })
          }

          pollObject[key].answers = answersArray;
          pollObject[key].id = key;
          message.questions.push(pollObject[key])
        }
      }
      message.pollObject = pollObject;
    }
    return message;
  })
}

//UI

export const usedColors = {
  reports: {
    red: "#e53563",
    yellow: "#f0d247",
    teal: "#29e4e2",
  },
  backgroundGray: "#f5f7fc",
  darkBlue: "#292840",
  // mainColor:"#1fd2d2",
  lightGrayText: "#bac9d9",
  darkText: "#212034",
  pireAppWebColor: '#ed3223'
}

export const isEmailOfUnauth = (email) => {

  return email?.match(/^(?:unauth_)(.+?)(?:@novoville.com)$/)

}