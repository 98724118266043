import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '@/config';
import { goTo } from '@/shared/actions';
import { Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import NoMessages from '../messages/NoMessages';

const Announcements = ({
  announcements = [],
  type,
  title,
  showMore,
  history,
}) => {
  const isScreenMobile = window.innerWidth < config.customMedia.mobile;
  const noAnnouncements = announcements.length === 0;
  const handleShowMore = () => {
    history.push({
      pathname: showMore.to,
      state: { tab: showMore.tab },
    });
  };

  return (
    <div className="flex">
      <div className="rounded-0 flex w-full flex-col gap-[24px] bg-white p-[24px] sm:rounded-[16px] sm:p-[32px]">
        <div className="flex flex-col items-center justify-between sm:flex-row">
          <Typography variant="bold_20" className="text-gray-900">
            {title}
          </Typography>
          {!isScreenMobile && !noAnnouncements && (
            <Typography
              variant="semibold_16"
              className="leading-[24px] text-gray-500 hover:text-gray-700 cursor-pointer hover:underline"
              onClick={handleShowMore}
            >
              {showMore.text}
            </Typography>
          )}
        </div>
        {noAnnouncements ? (
          <NoMessages type={type} />
        ) : (
          announcements.slice(0, 3).map((announcement, index) => (
            <Fragment key={index}>
              <AnnouncementItem
                title={announcement.title}
                type={type}
                date={announcement.created_at}
                content={announcement.body}
                id={announcement.id}
              />
              {index < announcements.slice(0, 3).length - 1 && <Separator />}
            </Fragment>
          ))
        )}
        {isScreenMobile && !noAnnouncements && (
          <Typography
            variant="semibold_16"
            className="leading-[24px] text-gray-500 underline hover:text-gray-7000 cursor-pointer hover:underline"
            onClick={handleShowMore}
          >
            {showMore.text}
          </Typography>
        )}
      </div>
    </div>
  );
};

const AnnouncementItem = ({ title, type, date, content, id }) => {
  const isPoll = type === 'polls';
  const dispatch = useDispatch();
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <div className="flex w-full flex-col bg-white">
      <div className="flex flex-row items-center justify-between gap-[4px] sm:items-center">
        <Typography
          variant="semibold_16"
          className="font-open-sans break-words cursor-pointer p-0 text-left text-[16px] font-semibold leading-[24px] text-gray-800 no-underline hover:underline"
          onClick={() => dispatch(goTo(`messages/${id}`))}
        >
          {title}
        </Typography>
        {!isPoll && (
          <Typography variant="regular_14" className="text-gray-500">
            {formatDate(date)}
          </Typography>
        )}
      </div>
      {!isPoll ? (
        <Typography
          variant="regular_16"
          className="text-gray-700 break-words pt-[4px]"
        >
          {content}
        </Typography>
      ) : (
        <Typography variant="regular_14" className="text-gray-500 pt-[4px]">
          {new Date(date).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          })}
        </Typography>
      )}
    </div>
  );
};

export default withRouter(Announcements);
