import * as ATypes from '../../shared/actionTypes';

export const reportsReducer = (state, action) => {
  if (!state) {
    state = {
      myReports: [],
      loading: true,
      nextUrl: null,
      activeReport: null,
      error: null,
    };
  }

  switch (action.type) {
    case ATypes.GET_MY_REPORTS_RES:
      return {
        ...state,
        myReports: action.payload['data'] ? action.payload['data'] : [],
        nextUrl: action?.payload?.next_page_url,
      };
    case ATypes.GET_SINGLE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ATypes.GET_SINGLE_REPORT_SUCCESS:
      return {
        ...state,
        activeReport: action.payload,
        loading: false,
      };
    case ATypes.GET_SINGLE_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case ATypes.GET_MORE_REPORTS_RES:
      let myReportTempArray = state.myReports;
      myReportTempArray.push(...action?.payload?.data);
      return {
        ...state,
        myReports: myReportTempArray,
        nextUrl: action?.payload?.next_page_url,
      };
    default:
      return state;
  }
};
