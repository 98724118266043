import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@/services/TranslationService';
import { withTranslation } from '../../shared/components/withTranslation';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import NRDescription from './NRDescription';
import NRIssue from './NRIssue';
import NRLocation from './NRLocation';
import NRPhoto from './NRPhoto';
import NewReportNavigationHeader from './NewReportNavigationHeader';
import { purgeReducer } from './actions';

const NewReport = (props) => {
  const { setHeaderTitle } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    setHeaderTitle(translate('newReportLower'));

    return () => {
      dispatch(purgeReducer());
    };
  }, [dispatch]);

  const navigation = useSelector((state) => state.newReportReducer.navigation);

  const getActiveComponent = (navigation) => {
    switch (navigation) {
      case 'SELECT_LOCATION':
        return <NRLocation />;
      case 'SELECT_ISSUE':
        return <NRIssue />;
      case 'ADD_DESCRIPTION':
        return <NRDescription />;
      case 'ADD_PHOTO':
        return <NRPhoto />;
      default:
        return <NRLocation />;
    }
  };

  const activeComponent = getActiveComponent(navigation);

  return (
    <div className="flex flex-column flex-grow calc-100-vh-header">
      <div className="hidden lg:block">
        <NewReportNavigationHeader navigation={navigation} />
      </div>
      {activeComponent}
    </div>
  );
};

export default withTranslation(withNOVOHeader(NewReport));
