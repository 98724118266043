import React, { useState } from 'react';
import { Circle, List } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import { Badge } from '@/components/ui/badge';
import { Form } from '@/components/ui/form';
import { Separator } from '@/components/ui/separator';
import { toast } from '@/components/ui/use-toast';
import { translate } from '../../services/TranslationService';
import { goBackRouter } from '../../shared/actions';
import { withTranslation } from '../../shared/components/withTranslation';
import { postPoll } from './actions';

const messageExpiredOrAnswered = (message) => {
  if (message.pollObject.answered || message.pollObject.expired) {
    return true;
  }
};

const getPollButtonText = (message) => {
  if (message.pollObject.answered) {
    return translate('PollAnswered');
  } else if (message.pollObject.expired) {
    return translate('PollExpired');
  } else {
    return translate('Submit');
  }
};

const PollMessageItem = (props) => {
  const { message, display, className } = props;
  const [loading, setLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const dispatch = useDispatch();

  const messageId = message.id;
  const messageTime = message?.created_at?.substr(0, 10);

  const handleSelectAnswer = (questionId, answerId) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: answerId,
    }));
  };

  const renderQuestions = () => {
    const questions = message.questions ?? [];
    return questions.map((question, index) => {
      if (!question) return null;
      return (
        <React.Fragment key={question.id}>
          <Form>
            <Typography variant="bold_16" className="text-gray-700 py-[16px]">
              {question.question}
            </Typography>
            {renderAnswers(question)}
          </Form>
          {index !== questions.length - 1 && <Separator className="my-4" />}
        </React.Fragment>
      );
    });
  };

  const renderAnswers = (question) => {
    return (
      <div className="flex flex-col space-y-[16px]">
        {question.answers.map((answer) => (
          <label
            key={answer.id}
            className="relative flex items-center cursor-pointer"
          >
            <input
              type="radio"
              name={question.id}
              value={answer.id}
              className="sr-only"
              checked={selectedAnswers[question.id] === answer.id}
              onChange={(e) => {
                handleSelectAnswer(question.id, e.target.value);
              }}
            />
            <span className="inline-block h-4 w-4 rounded-full border border-gray-300 mr-2 flex items-center justify-center">
              {selectedAnswers[question.id] === answer.id && (
                <Circle className="h-2.5 w-2.5 fill-current text-black" />
              )}
            </span>
            <Typography variant="regular_16" className="text-gray-900">
              {answer.value}
            </Typography>
          </label>
        ))}
      </div>
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    dispatch(
      postPoll({
        message_id: messageId,
        answer: selectedAnswers,
        authority: message.authority_name,
      })
    )
      .then(() => {
        setLoading(false);
        toast({
          title: translate('OTHER.success'),
          description: translate('POLL.succesfullySent'),
          variant: 'success',
        });
        dispatch(goBackRouter());
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage =
          err.details && err.details.user_description
            ? err.details.user_description
            : translate('OTHER.genericError');
        toast({
          title: translate('OTHER.errorOcurred'),
          description: errorMessage,
          variant: 'destructive',
        });
      });
  };

  const bodyRender =
    display !== 'SUMMARY' ? (
      <>
        {renderQuestions(message)}
        <div className="text-center self-end sm:w-fit w-full">
          <NovovilleButton
            variant="primary"
            className=" mt-[24px] sm:w-fit w-full"
            loading={loading}
            disabled={messageExpiredOrAnswered(message)}
            onClick={handleSubmit}
          >
            {getPollButtonText(message)}
          </NovovilleButton>
        </div>
      </>
    ) : null;

  const isPollActive =
    !message.pollObject.expired && !message.pollObject.answered;

  const badgeText = isPollActive
    ? translate('Active')
    : translate('BottomSheet_closed');

  return (
    <div
      className={`flex sm:p-[20px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0 ${className}`}
    >
      <div className="flex flex-col sm:p-[32px] p-[24px] bg-white w-full sm:rounded-md rounded-0 sm:overflow-auto overflow-y-scroll">
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <List className="h-5 w-5 text-peach" />
            <Typography variant="semibold_16" className="text-gray-800">
              {translate(message.app_type)}
            </Typography>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Typography variant="regular_14" className="text-gray-500">
              {messageTime}
            </Typography>
            <Badge
              variant="outline"
              className={`bg-badgeSuccess text-black py-[4px] px-[12px] ${
                isPollActive ? 'bg-badgeSuccess' : 'bg-gray-300'
              }`}
            >
              <Typography
                variant="regular_12"
                className="text-gray-800 text-[10px]"
              >
                {badgeText}
              </Typography>
            </Badge>
          </div>
        </div>
        <Separator className="my-[8px] bg-gray-300" />
        <Typography
          variant="bold_20"
          className="text-gray-800 break-words pb-[6px]"
        >
          {message.title}
        </Typography>
        {bodyRender}
      </div>
    </div>
  );
};

export default withTranslation(PollMessageItem);
