export const getProfileFields = (profile, profileFieldsArr) => {

    var profileFieldsObj = {};

    profileFieldsArr.forEach(item => {
        profileFieldsObj[item] = profile[item];
    })

    return profileFieldsObj;

}

export const isEmailDummy = (email) => {
    return email ? email.indexOf("NOVOVILLE_DUMMY_EMAIL_") !== -1 : false;
}

export const isHardcodedAvatar = (val) => {
    return (!val.includes("http"))
}