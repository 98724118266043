import React from 'react';
import { Input } from '@/components/ui/input';
import '../../output.css';

const NovovilleInput = ({
  errorMessage,
  iconRight,
  onIconClick,
  showPassword,
  iconLeft,
  type = 'text',
  ...inputProps
}) => {
  return (
    <div className="relative flex items-center w-full">
      {iconLeft && (
        <button
          type="button"
          onClick={onIconClick}
          className="absolute inset-y-0 left-0 pl-3 flex items-center text-sm leading-5"
        >
          {iconLeft}
        </button>
      )}
      <Input
        className={`flex-1 rounded-md ${
          errorMessage ? 'text-errorRed border-errorRed' : 'border-gray-300'
        } text-[16px] ${iconLeft ? 'pl-10' : ''} ${iconRight ? 'pr-10' : ''}`}
        type={type}
        {...inputProps}
      />
      {iconRight && (
        <button
          type="button"
          onClick={onIconClick}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
        >
          {iconRight}
        </button>
      )}
    </div>
  );
};

export default NovovilleInput;
