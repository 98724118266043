import React, { Component } from "react";
import { isPireApp } from "../../services/platformCheck";

class Splash extends Component {

  getImageSrc = () => {
    if (isPireApp()) {
      return '/images2/pireapp/pireappWebSplash.png'
    }
    return "/images2/other/novovilleLogoBig.png"
  }

  getImageStyle = () => {
    if (isPireApp()) {
      return {
        width: 80,
      }
    }
    return {
      height: 40,
      width: 40
    }
  }

  render() {

    let { heightValue = "100vh" } = this.props;

    return (

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: heightValue, backgroundColor: "#f5f7fc" }}>
        <img style={this.getImageStyle()} className="splashAnimation" src={this.getImageSrc()} />
      </div>
    )
  }
}

export default Splash;
