import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Phone } from 'lucide-react';
import { connect } from 'react-redux';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import { getPhones } from './actions';

class Phonebook extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      loading: false,
    };
    this.props.setHeaderTitle(translate('sideMenu.EmergenyPhones'));
  }

  componentDidMount() {
    const { getPhones } = this.props;
    getPhones();
  }

  render() {
    const { allPhones } = this.props;
    return (
      <div className="flex sm:p-[40px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0">
        <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 sm:overflow-auto overflow-y-scroll">
          {allPhones.map((phone, index) => {
            return (
              <>
                <div className="flex flex-row justify-between items-center rounded-[16px] py-[12px] px-[16px] hover:bg-gray-100">
                  <div className="flex flex-col gap-[4px]">
                    <Typography variant="semibold_16" className="text-gray-800">
                      {phone.name}
                    </Typography>
                    {phone.description && (
                      <Typography
                        variant="regular_14"
                        className="text-gray-700"
                      >
                        {phone.description}
                      </Typography>
                    )}
                    <Typography variant="regular_16" className="text-gray-700">
                      {phone.value}
                    </Typography>
                  </div>
                  <div>
                    <Phone
                      className="w-[20px] h-[20px] text-gray-900 cursor-pointer"
                      onClick={() => window.open(`tel:${phone.value}`)}
                    />
                  </div>
                </div>
                {index !== allPhones.length - 1 && (
                  <Separator className="my-[24px]" />
                )}
              </>
            );
          })}
        </div>
      </div>
    );
  }
}

var mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPhones,
    },
    dispatch
  );

var mapStateToProps = (state) => {
  return {
    allPhones: state.phonesReducer.phones,
  };
};

export default withNOVOHeader(
  connect(mapStateToProps, mapDispatchToProps)(Phonebook)
);
