export const forgotPassword = (email) => {

  const mixpanel = window.mixpanel;

  mixpanel.track(
    "FORGOT PASSWORD",
    { email }
  )
}

//
export const identify = (userID) => {

  const mixpanel = window.mixpanel;

  mixpanel.identify(userID)

}

export const loginEvent = ({ type, email, isUserUnauth, application, error }) => {

  const mixpanel = window.mixpanel;
  mixpanel.track(
    "LOGIN",
    { type, email, application, error }
  )
}

// REVISIT WITH IDENTIFY/ALIAS ETC
export const registration = ({ type, email, firstName, lastName, error = "no error", isUserUnauth, application, userID }) => {

  const mixpanel = window.mixpanel;

  mixpanel.track(
    "REGISTRATION",
    { type, email, firstName, lastName, isUserUnauth, application, error }
  )
}

export const onboardingSuccessEvent = ({ error = "no error", selectedAuth, isUserUnauth, cameHereFrom }) => {

  const mixpanel = window.mixpanel;

  mixpanel.track(
    "REGISTRATION SUCCESS",
    {
      error,
      selectedAuth,
      isUserUnauth,
      cameHereFrom,
    })
}

export const newReportEvent = (success, atState, authority_on_novo, closedApp = false, gotInHereFrom, pressedBack = false, issueCategory, authority) => {

  const mixpanel = window.mixpanel;

  if (success) incrementReportsMade();

  mixpanel.track("NEW REPORT", {
    atState,
    authority_on_novo,
    closedApp,
    gotInHereFrom,
    pressedBack,
    issueCategory,
    authority,
    success,
  })
}

export const messageDetailEvent = (cameFrom, otherData, messageId) => {

  const mixpanel = window.mixpanel;

  mixpanel.track("MESSAGE DETAIL", {
    "cameFrom": cameFrom,
    "otherData": otherData, //its the type
    "messageId": messageId,
  })
}

export const logoutEvent = () => {

  const mixpanel = window.mixpanel;
  mixpanel.reset();
}

export const webChatOpend = (platform, name) => {

  const mixpanel = window.mixpanel;

  mixpanel.track("WEBCHAT_OPENED", {
    "name": name,
  })

}

const incrementReportsMade = () => {

  const mixpanel = window.mixpanel;

  mixpanel.people.increment("reports submitted")

}