import React, { Component } from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';

//TODO URGENT !!!: THIS window.dragingAAA solution needs to change!
//i just dont know what the right way to do that is right now. i can dispatch an action sure but i dont
//want to store this to redux. i need to better understand dragging and continuous rendering updates like this.

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  mapChanged = (center) => {
    console.log(center);
  };
  render() {
    const { onIdle, center, zoom } = this.props;

    return (
      <GoogleMap
        defaultZoom={zoom}
        zoom={zoom}
        center={center}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onDrag={() => {
          window.draggingAAA = true;
        }}
        onChange={({ center }) => {
          this.mapChanged(center);
        }}
        onIdle={function () {
          window.draggingAAA = false;
          var currentCenter = {
            lat: this.getCenter().lat(),
            lng: this.getCenter().lng(),
          };
          geocodeLatLng(currentCenter).then((addressName) => {
            if (!window.draggingAAA) {
              onIdle(currentCenter, addressName);
            }
          });
        }}
      ></GoogleMap>
    );
  }
}

const MyMapComponent = withScriptjs(withGoogleMap(MapComponent));

function geocodeLatLng({ lat, lng }) {
  var geocoder = new window.google.maps.Geocoder();
  var promise = new Promise((res, rej) => {
    var latlng = { lat: lat, lng: lng };
    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (status === 'OK') {
          if (results[0]) {
            var rv = results[0].formatted_address;
            res(rv);
          } else {
            rej(null);
          }
        } else {
          console.warning('Geocoder failed due to: ' + status);
          rej(null);
        }
      }
    });
  });

  return promise;
}

export default MyMapComponent;
