import { getTranslatedLanguageWithUserSettings } from '@/services/GNRL.js';
import WEBAPI from '../../services/WEBAPI.js';
import { getAuthorityForCurrentApplication } from '../../services/platformCheck';
import * as ATypes from '../../shared/actionTypes';

export const getFAQS = () => {
  return (dispatch, getState) => {
    const authority =
      getAuthorityForCurrentApplication() ??
      getState()?.profileReducer?.profile?.authority?.name;
    const language = getTranslatedLanguageWithUserSettings();

    dispatch({
      type: ATypes.GET_FAQS_REQ,
    });

    return WEBAPI.getFAQS(authority, language).then((res) => {
      dispatch({
        type: ATypes.GET_FAQS_RES,
        payload: res['result'],
      });
    });
  };
};
