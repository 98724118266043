import React, { Component } from 'react';
import ReactMapboxGl, { GeoJSONLayer, ZoomControl } from 'react-mapbox-gl';
import { styles } from './styles';
import ClustersAndMarkers from './MyCityMapPresenter';

const MapBox = ReactMapboxGl({
  scrollZoom: false,
  renderChildrenInPortal: true,
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

class MyCityMapContainer extends Component {
  state = {
    map: {},
    isLoading: true,
    styles: styles.basic,
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (this?.state?.map?.resize) {
        this?.state?.map?.resize();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', null);
  }

  renderAthorityPolygon() {
    const { placemarks } = this.props;
    if (placemarks?.features?.length > 0) {
      return (
        <GeoJSONLayer
          data={placemarks}
          fillPaint={{
            'fill-color': 'rgb(136,136,136)',
            'fill-opacity': 0.3,
            'fill-outline-color': 'rgb(120,120,120)',
          }}
        />
      );
    }
    return null;
  }

  render() {
    const {
      zoom,
      goTo,
      center,
      onClick,
      markerHtmlArray,
      isFromCity,
      userLocation,
      selectedPoint,
      subscribeToPoi,
      onClickCluster,
      openInfoWindow,
      showInfoWindow,
      isFromNewReport,
      closeInfoWindow,
      zoomControlClick,
      featureCollection,
      unsubscribeFromPoi,
      fitBounds,
      zoomControl,
    } = this.props;

    return (
      <>
        <MapBox
          zoom={zoom}
          center={center}
          style={this.state.styles}
          className={this.props.className}
          onStyleLoad={(map) => this.setState({ isLoading: false, map: map })}
          containerStyle={{ minHeight: isFromCity ? 450 : 190, flexGrow: 1 }}
          onClick={(map, e) => (isFromCity ? e.preventDefault() : onClick())}
          fitBounds={fitBounds}
        >
          {!this.isLoading && (
            <>
              <ClustersAndMarkers
                zoom={zoom}
                goTo={goTo}
                markerHtmlArray={markerHtmlArray}
                isFromCity={isFromCity}
                userLocation={userLocation}
                selectedPoint={selectedPoint}
                subscribeToPoi={subscribeToPoi}
                onClickCluster={onClickCluster}
                openInfoWindow={openInfoWindow}
                showInfoWindow={showInfoWindow}
                isFromNewReport={isFromNewReport}
                closeInfoWindow={closeInfoWindow}
                featureCollection={featureCollection}
                unsubscribeFromPoi={unsubscribeFromPoi}
              />
              {this.renderAthorityPolygon()}
            </>
          )}
          {!zoomControl && (
            <ZoomControl
              onControlClick={(map, zoomDiff) => {
                if (zoomDiff > 0)
                  map.zoomIn({ level: map.getZoom() + zoomDiff });
                else {
                  map.zoomOut({ level: map.getZoom() - zoomDiff });
                  showInfoWindow ? zoomControlClick() : null;
                }
              }}
            />
          )}
        </MapBox>
      </>
    );
  }
}
export default MyCityMapContainer;
