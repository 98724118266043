import React, { Component } from "react";
import NOVOLoader from "../NOVOLoader";

//TODO: This is wrong. Change it to a different button type since the display is so different.
class Button extends Component {
    render() {
        const {
            buttonText,
            loading,
            disabled,
            onClick,
            onPress,//TODO: adapteer pattern. see below
            //
            hidden,
            buttonIcon,
            buttonClass = 'btn btn-min-half btn-large btn-gradient-black btn-shadow border-radius-5',
            identifier,
            addedStyle,
        } = this.props;

        //TODO: eventuallye refactor the below ADAPTER PATTERN
        let onButtonPress = onClick || onPress

        if (hidden) {
            return null;
        }

        let buttonContent;
        if (buttonIcon) buttonContent = <span className="btn-icon"><img className="button-icon pointer" src={buttonIcon} /></span>;
        else buttonContent = <span className="btn-text">{buttonText}</span>;
        if (loading) {
            buttonContent = <NOVOLoader size={21} />
        }

        return (
            <div
                id="novobutton"
                style={{
                    opacity: disabled ? 0.2 : 1,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // maxWidth: 500,
                    ...addedStyle
                }}
                className={`${buttonClass} ${identifier ? identifier : ''}`}
                onClick={(e) => {
                    if (!disabled && !loading) {
                        onButtonPress();
                    }
                    e.preventDefault()
                }}>
                {buttonContent}
            </div>
        );
    }
}

export default Button;
