import * as ATypes from '../../shared/actionTypes';
import { properlyFormatPollsApiMessages } from '../../services/NOVOGNRL';

export const homeReducer = (state, action) => {
  if (!state) {
    state = {
      messages: [],
      reports: [],
      mycity: [],
      statistics: [],
      my_trees: [],
      my_repairs: [],
      my_tenements: [],
      cover_image: '/images2/other/Default_image_header.png',
      loading: true,
      cameFrom: null,
    };
  }

  switch (action.type) {
    case ATypes.SET_HOME_DATA:
      action.payload.messages = properlyFormatPollsApiMessages(
        action.payload.messages
      );
      return {
        ...state,
        ...action.payload,
        cover_image:
          action.payload.cover_image === ''
            ? '/images2/other/Default_image_header.png'
            : action.payload.cover_image,
        loading: false,
      };
    case ATypes.SET_CAME_FROM:
      return {
        ...state,
        cameFrom: action.payload,
      };
    default:
      return state;
  }
};
