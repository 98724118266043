import React, { useEffect, useState } from 'react';
import {
  Calendar,
  CircleHelp,
  CircleUserRound,
  FileText,
  MapPin,
  Megaphone,
  PanelsTopLeft,
  Phone,
  X,
} from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import config from '@/config';
import { getCameFrom } from '@/services/store';
import { Typography } from '@/components/novoville';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import novoville_logo from '../../resources/novoville_logo.svg';
import { translate } from '../../services/TranslationService';
import { goTo } from '../../shared/actions';
import SidemenuItem from './SideMenuItem';
import { toggleSideMenu } from './actions';

const SideMenu = () => {
  const dispatch = useDispatch();
  const { isOpen, currentLocation, modules, municipality } = useSelector(
    (state) => ({
      isOpen: state.sideMenuReducer?.isOpen,
      currentLocation: state.router?.location?.pathname || '/',
      municipality: state?.profileReducer?.profile?.authority?.name,
      modules: state.otherReducer?.modules,
    })
  );

  const allTranslatedAuthorities = useSelector(
    (state) => state.translationsReducer.translations.authorities
  );

  const [currentMunicipality, setCurrentMunicipality] = useState('');

  useEffect(() => {
    const translatedMunicipality = allTranslatedAuthorities[municipality];
    setCurrentMunicipality(translatedMunicipality);
  }, [allTranslatedAuthorities, municipality]);

  const iconStyles = (route) => 'h-[24px] mr-[12px]';

  const createSidemenuEntries = (modulesEnabled, goAndToggle) => {
    let entries = [];
    modulesEnabled = modulesEnabled.map((item) => item.name);

    const menuItems = [
      {
        key: '1',
        onClick: () => goAndToggle('home'),
        icon: <PanelsTopLeft className={iconStyles('home')} />,
        name: translate('sideMenu.Activity'),
        route: 'home',
      },
      ...(modulesEnabled.includes('messages')
        ? [
            {
              key: '2',
              onClick: () => goAndToggle('messages'),
              icon: <Megaphone className={iconStyles('messages')} />,
              name: translate('sideMenu.Messages'),
              route: 'messages',
            },
          ]
        : []),
      ...(modulesEnabled.includes('mycity')
        ? [
            {
              key: '3',
              onClick: () => goAndToggle('myCity'),
              icon: <MapPin className={iconStyles('myCity')} />,
              name: translate('sideMenu.MyCity'),
              route: 'myCity',
            },
          ]
        : []),
      ...(modulesEnabled.includes('reports')
        ? [
            {
              key: '4',
              onClick: () => goAndToggle('reports'),
              icon: <FileText className={iconStyles('reports')} />,
              name: translate('sideMenu.MyReports'),
              route: 'reports',
            },
          ]
        : []),
      ...(modulesEnabled.includes('phones')
        ? [
            {
              key: '5',
              onClick: () => goAndToggle('phonebook'),
              icon: <Phone className={iconStyles('phonebook')} />,
              name: translate('sideMenu.EmergenyPhones'),
              route: 'phonebook',
            },
          ]
        : []),
      ...(modulesEnabled.includes('faqs')
        ? [
            {
              key: '6',
              onClick: () => goAndToggle('faqs'),
              icon: <CircleHelp className={iconStyles('faqs')} />,
              name: translate('sideMenu.FAQ'),
              route: 'faqs',
            },
          ]
        : []),
      ...(modulesEnabled.includes('calendar')
        ? [
            {
              key: '7',
              onClick: () => goAndToggle('calendar'),
              icon: <Calendar className={iconStyles('calendar')} />,
              name: translate('sideMenu.Calendar'),
              route: 'calendar',
            },
          ]
        : []),
      {
        key: '8',
        onClick: () => goAndToggle('profile'),
        icon: <CircleUserRound className={iconStyles('profile')} />,
        name: translate('sideMenu.Profile'),
        route: 'profile',
      },
    ];

    entries = entries.concat(
      menuItems.map((item) => <SidemenuItem key={item.key} {...item} />)
    );

    return entries;
  };

  const goAndToggle = (location) => {
    dispatch(goTo(location));
  };

  const cameFrom = getCameFrom();

  if (
    currentLocation === '/' ||
    currentLocation === '/login' ||
    currentLocation === '/register' ||
    (currentLocation === '/authoritySelection' && cameFrom === 'register') ||
    currentLocation === '/addPhone' ||
    currentLocation === '/verifyEmail' ||
    currentLocation === '/tutorial'
  ) {
    return null;
  }

  let sideMenuItems = createSidemenuEntries(modules, goAndToggle);

  const isScreenMobile = window.innerWidth < config.customMedia.mobile;

  if (isScreenMobile) {
    return (
      <div className="grid grid-cols-2 rounded-tr-[8px]">
        <Sheet
          className="rounded-[8px]"
          key="left"
          open={isOpen}
          onOpenChange={() => dispatch(toggleSideMenu())}
        >
          <SheetContent side="left">
            <div
              className={
                'flex flex-col rounded-tr-[8px] bg-white transition-all duration-300'
              }
            >
              <div className="flex h-[80px] w-full flex-row items-center justify-between border-b border-gray-200">
                <div
                  className="ml-5 cursor-pointer"
                  onClick={() => dispatch(toggleSideMenu())}
                >
                  <X className="h-[24px] w-[24px]" />
                </div>
                <div className=" flex flex-col items-start justify-center">
                  <img src={novoville_logo} alt="logo" className="w-[84px]" />
                </div>
                <div className="mr-5 block w-[18px] sm:hidden" />
              </div>
              <div className="flex-1 pt-[8px]">{sideMenuItems}</div>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col border-r border-gray-200 bg-white sm:w-[224px] lg:w-[310px] md:w-[224px] ${
        isOpen ? 'w-[224px]' : 'w-[0]'
      } transition-all duration-300`}
    >
      <div className="h-[80px] w-full border-b border-gray-200 p-[24px]">
        <div
          className=" flex flex-col items-start justify-center cursor-pointer"
          onClick={() => dispatch(goTo('home'))}
        >
          <img src={novoville_logo} alt="logo" className="w-[116px]" />
          <Typography variant="semibold_14" className="text-gray-600">
            {currentMunicipality}
          </Typography>
        </div>
      </div>
      <div className="flex-1 pt-[8px]">{sideMenuItems}</div>
    </div>
  );
};

export default SideMenu;
