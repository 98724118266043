import React from "react";
import { translate } from "../../services/TranslationService";

export function withTranslation(CMP) {
    var hoc = class extends React.Component {
        render() {
            return <CMP {...this.props} translate={translate} />
        }
    };
    return hoc;
}

