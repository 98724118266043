//OTHER
export const NOTHING = 'NOTHING';
export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_RESPONSE = 'GET_COUNTRIES_RESPONSE';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE';
export const SET_INTERNET_STATUS = 'SET_INTERNET_STATUS';
export const SET_DYNAMIC_LINKS_PARAMS = 'SET_DYNAMIC_LINKS_PARAMS';
export const DELETE_DYNAMIC_LINKS_PARAMS = 'DELETE_DYNAMIC_LINKS_PARAMS';
export const RESET_DYNAMIC_LINKS_PARAMS = 'RESET_DYNAMIC_LINKS_PARAMS';
export const POST_ERROR = 'POST_ERROR';

export const GET_AUTHORITIES_REQUEST = 'GET_AUTHORITIES_REQUEST';
export const GET_AUTHORITIES_RESPONSE = 'GET_AUTHORITIES_RESPONSE';
export const GET_PROPERTY_FACTORS_REQUEST = 'GET_PROPERTY_FACTORS_REQUEST';
export const GET_PROPERTY_FACTORS_RESPONSE = 'GET_PROPERTY_FACTORS_RESPONE';
export const GET_TRANSLATIONS_REQUEST = 'GET_TRANSLATIONS_REQUEST';
export const GET_TRANSLATIONS_RESPONSE = 'GET_TRANSLATIONS_RESPONSE';
export const POST_REGISTER_REQ = 'POST_REGISTER_REQ';
export const POST_REGISTER_RES = 'POST_REGISTER_RES';
export const POST_LOGIN_REQ = 'POST_LOGIN_REQ';
export const POST_LOGIN_RES = 'POST_LOGIN_RES';
export const POST_ACCOUNT_REQ = 'POST_ACCOUNT_REQ';
export const POST_ACCOUNT_RES = 'POST_ACCOUNT_RES';
export const SET_ORIGINAL_URL_PARAMS = 'SET_ORIGINAL_URL_PARAMS';
export const UNSET_ORIGINAL_URL_PARAMS = 'UNSET_ORIGINAL_URL_PARAMS';
export const GET_MODULES_REQ = 'GET_MODULES_REQ';
export const GET_MODULES_RES = 'GET_MODULES_RES';
export const GET_PERMISSIONS_REQ = 'GET_PERMISSIONS_REQ';
export const GET_PERMISSIONS_RES = 'GET_PERMISSIONS_RES';
export const GET_AUTHORITY_DETAILS_REQ = 'GET_AUTHORITY_DETAILS_REQ';
export const GET_AUTHORITY_DETAILS_RES = 'GET_AUTHORITY_DETAILS_RES';

export const REFRESH_TOKEN_RES = 'REFRESH_TOKEN_RES';

//PROFILE

export const CHANGE_PROFILE = '';
export const POST_PROFILE_REQ = 'POST_PROFILE_REQ';
export const POST_PROFILE_RES = 'POST_PROFILE_RES';
export const GET_PROFILE_REQ = 'GET_PROFILE_REQ';
export const GET_PROFILE_RES = 'GET_PROFILE_RES';
export const DELETE_ACCOUNT_REQ_REQ = 'DELETE_ACCOUNT_REQ_REQ';
export const DELETE_ACCOUNT_REQ_RES = 'DELETE_ACCOUNT_REQ_RES';

//SELECT AUTH

export const SET_SELECTED_AUTHORITY = 'SET_SELECTED_AUTHORITY';

//MESSAGES
export const GET_MESSAGES_REQ = 'GET_MESSAGES_REQ';
export const GET_MESSAGES_RES = 'GET_MESSAGES_RES';
export const GET_MORE_MESSAGES_REQ = 'GET_MORE_MESSAGES_REQ';
export const GET_MORE_MESSAGES_RES = 'GET_MORE_MESSAGES_RES';
export const POST_POLL_REQ = 'POST_POLL_REQ';
export const POST_POLL_RES = 'POST_POLL_RES';
export const GET_SINGLE_MESSAGE_REQ = 'GET_SINGLE_MESSAGE_REQ';
export const GET_SINGLE_MESSAGE_RES = 'GET_SINGLE_MESSAGE_RES';

//REPORTS
export const GET_MY_REPORTS_REQ = 'GET_MY_REPORTS_REQ';
export const GET_MY_REPORTS_RES = 'GET_MY_REPORTS_RES';
export const GET_SINGLE_REPORT_REQUEST = 'GET_SINGLE_REPORT_REQUEST';
export const GET_SINGLE_REPORT_SUCCESS = 'GET_SINGLE_REPORT_SUCCESS';
export const GET_SINGLE_REPORT_FAILURE = 'GET_SINGLE_REPORT_FAILURE';
export const GET_MORE_REPORTS_REQ = 'GET_MORE_REPORTS_REQ';
export const GET_MORE_REPORTS_RES = 'GET_MORE_REPORTS_RES';
//FAQS
export const GET_FAQS_REQ = 'GET_FAQS_REQ';
export const GET_FAQS_RES = 'GET_FAQS_RES';

//PHONES
export const GET_PHONES_REQ = 'GET_PHONES_REQ';
export const GET_PHONES_RES = 'GET_PHONES_RES';

//SIDEMENU
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';

//MYCITY
export const GET_REPORTS_FOR_AUTHORITY_REQ = 'GET_REPORTS_FOR_AUTHORITY_REQ';
export const GET_REPORTS_FOR_AUTHORITY_RES = 'GET_REPORTS_FOR_AUTHORITY_RES';
export const GET_POIS_FOR_AUTHORITY_REQ = 'GET_POIS_FOR_AUTHORITY_REQ';
export const GET_POIS_FOR_AUTHORITY_RES = 'GET_POIS_FOR_AUTHORITY_RES';
export const GET_POI_TYPES_FOR_AUTHORITY_REQ =
  'GET_POI_TYPES_FOR_AUTHORITY_REQ';
export const GET_POI_TYPES_FOR_AUTHORITY_RES =
  'GET_POI_TYPES_FOR_AUTHORITY_RES';
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';

//HOME
export const GET_HOME_DATA = 'GET_HOME_DATA';
export const SET_HOME_DATA = 'SET_HOME_DATA';
export const POST_AUTHORITY_PROMPT_OPTIONS_REQ =
  'POST_AUTHORITY_PROMPT_OPTIONS_REQ';
export const SET_USER_DECLINED_CHANGING_AUTHORITY =
  'SET_USER_DECLINED_CHANGING_AUTHORITY';
export const SET_CAME_FROM = 'SET_CAME_FROM';

//NEW REPORT
export const NR_SET_NAVIGATION = 'NR_SET_NAVIGATION';
export const NR_SET_SELECTED_AUTHORITY = 'NR_SET_SELECTED_AUTHORITY';
export const NR_SET_SELECTED_AUTHORITY_ISSUES =
  'NR_SET_SELECTED_AUTHORITY_ISSUES';
export const NR_SET_SELECTED_ISSUE = 'NR_SET_SELECTED_ISSUE';
export const NR_SET_DESCRIPTION = 'NR_SET_DESCRIPTION';
export const NR_SET_URGENT = 'NR_SET_URGENT';
export const NR_ADD_PHOTO = 'NR_ADD_PHOTO';
export const NR_POST_REPORT = 'NR_POST_REPORT';
export const POST_REPORT_REQ = 'POST_REPORT_REQ';
export const POST_REPORT_RESP = 'POST_REPORT_RESP';
export const NR_SET_NEW_CENTER_AND_ADDRESS = 'NR_SET_NEW_CENTER_AND_ADDRESS';
export const NR_PURGE_REDUCER = 'NR_PURGE_REDUCER';
// export const INCREMENT_STEPS_COMPLETED = "INCREMENT_STEPS_COMPLETED";

//SYSTEM
export const GET_SYSTEM_STATUS = 'GET_SYSTEM_STATUS';
export const SET_FORGOT_PASS_URL = 'SET_FORGOT_PASS_URL';
export const SET_SUPPORTED_SOCIAL_NETWORK_AUTH =
  'SET_SUPPORTED_SOCIAL_NETWORK_AUTH';

//FORMS
export const GET_FORMS_REQ = 'GET_FORMS_REQ';
export const GET_FORMS_RES = 'GET_FORMS_RES';
export const GET_SUPPORTED_FORMS_REQ = 'GET_SUPPORTED_FORMS_REQ';
export const GET_SUPPORTED_FORMS_RES = 'GET_SUPPORTED_FORMS_RES';
export const POST_FORM_REQ = 'POST_FORM_REQ';
export const POST_FORM_RES = 'POST_FORM_RES';
export const POST_FORM_PAYMENT_RES = 'POST_FORM_PAYMENT_RES';
export const POST_FORM_PAYMENT_REQ = 'POST_FORM_PAYMENT_REQ';
export const GET_FINE_DETAILS_REQ = 'GET_FINE_DETAILS_REQ';
export const GET_FINE_DETAILS_RES = 'GET_FINE_DETAILS_RES';

//CALENDAR
export const GET_CALENDAR_REQ = 'GET_CALENDAR_REQ';
export const GET_CALENDAR_RES = 'GET_CALENDAR_RES';

//PARKING
export const GET_TICKETS_RES = 'GET_TICKETS_RES';
export const POST_ACTIVATE_TIKCET_TIME_RES = 'POST_ACTIVATE_TIKCET_TIME_RES';
export const GET_GEOZONES_REQ = 'GET_GEOZONES_REQ';
export const GET_GEOZONES_RES = 'GET_GEOZONES_RES';
export const GET_PLACEMARKS_REQ = 'GET_PLACEMARKS_REQ';
export const GET_PLACEMARKS_RES = 'GET_PLACEMARKS_RES';
export const GET_PRICING_FOR_CARPARK_ZONE_RES =
  'GET_PRICING_FOR_CARPARK_ZONE_RES';
export const GET_ACTIVE_TICKET_REQ = 'GET_ACTIVE_TICKET_REQ';
export const GET_ACTIVE_TICKET_RES = 'GET_ACTIVE_TICKET_RES';
export const SET_PARKING_MODE = 'SET_PARKING_MODE';

//WALLET
export const GET_WALLET_RES = 'GET_WALLET_RES';
export const GET_WALLET_TRANSACTIONS_RES = 'GET_WALLET_TRANSACTIONS_RES';
export const GET_WALLET_HISTORY_RES = 'GET_WALLET_HISTORY_RES';

export const GET_MORE_WALLET_TRANSACTIONS_REQ =
  'GET_MORE_WALLET_TRANSACTIONS_REQ';
export const GET_MORE_WALLET_TRANSACTIONS_RES =
  'GET_MORE_WALLET_TRANSACTIONS_RES';
export const GET_MORE_WALLET_HISTORY_REQ = 'GET_MORE_WALLET_HISTORY_REQ';
export const GET_MORE_WALLET_HISTORY_RES = 'GET_MORE_WALLET_HISTORY_RES';
export const GET_WALLET_TOPUP_OPTIONS_REQ = 'GET_WALLET_TOPUP_OPTIONS_REQ';
export const GET_WALLET_TOPUP_OPTIONS_RES = 'GET_WALLET_TOPUP_OPTIONS_RES';
export const TOPUP_WALLET_REQ = 'TOPUP_WALLET_REQ';
export const TOPUP_WALLET_RES = 'TOPUP_WALLET_RES';
export const UPDATE_BILLING_INFO_REQ = 'UPDATE_BILLING_INFO_REQ';
export const UPDATE_BILLING_INFO_RES = 'UPDATE_BILLING_INFO_RES';

//CARDS
export const GET_CARDS_REQ = 'GET_CARDS_REQ';
export const GET_CARDS_RES = 'GET_CARDS_RES';
export const DELETE_SELECTED_CARD = 'DELETE_SELECTED_CARD';
export const SELECT_CARD_FROM_WALLET = 'SELECT_CARD_FROM_WALLET';

//VERIFICATION
export const POST_VERIFY_REQ_REQ = 'POST_VERIFY_REQ_REQ';
export const POST_VERIFY_REQ_RES = 'POST_VERIFY_REQ_RES';
export const POST_ACTUAL_VERIFY_REQ = 'POST_ACTUAL_VERIFY_REQ';
export const POST_ACTUAL_VERIFY_RES = 'POST_ACTUAL_VERIFY_RES';
export const CHECK_EMAIL_VERIFY_RES = 'CHECK_EMAIL_VERIFY_RES';
export const CHECK_MOBILE_VERIFY_RES = 'CHECK_MOBILE_VERIFY_RES';

export const SUBSCRIBE_TO_POI_RES = 'SUBSCRIBE_TO_POI_RES';
export const UNSUBSCRIBE_FROM_POI_RES = 'UNSUBSCRIBE_FROM_POI_RES';
export const WATER_THE_TREE_RES = 'WATER_THE_TREE_RES';

export const SET_AUTH_TOKEN_FROM_OLD_APP = 'SET_AUTH_TOKEN_FROM_OLD_APP';
export const SET_UUID_FOR_PUSHWOOSH = 'SET_UUID_FOR_PUSHWOOSH';
export const SET_BADGE_NUMBER = 'SET_BADGE_NUMBER';

//DISCUSSIONS

export const GET_DISCUSSIONS_REQ = 'GET_DISCUSSIONS_REQ';
export const GET_DISCUSSIONS_RES = 'GET_DISCUSSIONS_RES';

//SHARED REPAIRS

export const GET_BUILDINGS_REQ = 'GET_BUILDINGS_REQ';
export const GET_BUILDINGS_RES = 'GET_BUILDINGS_RES';

export const POST_BUILDING_REQ = 'POST_BUILDING_REQ';
export const POST_BUILDING_RES = 'POST_BUILDING_RES';

export const UPDATE_BUILDING_REQ = 'UPDATE_BUILDING_REQ';
export const UPDATE_BUILDING_RES = 'UPDATE_BUILDING_RES';

export const LEAVE_TENEMENT_REQ = 'LEAVE_TENEMENT_REQ';
export const LEAVE_TENEMENT_RES = 'LEAVE_TENEMENT_RES';

export const POST_APARTMENT_REQ = 'POST_APARTMENT_REQ';
export const POST_APARTMENT_RES = 'POST_APARTMENT_RES';
export const UPDATE_APARTMENT_REQ = 'UPDATE_APARTMENT_REQ';
export const UPDATE_APARTMENT_RES = 'UPDATE_APARTMENT_RES';
export const ASSOCIATE_APARTMENT_REQ = 'ASSOCIATE_APARTMENT_REQ';
export const ASSOCIATE_APARTMENT_RES = 'ASSOCIATE_APARTMENT_RES';
export const CHANGE_ROLE_TYPE_REQ = 'CHANGE_ROLE_TYPE_REQ';
export const CHANGE_ROLE_TYPE_RES = 'CHANGE_ROLE_TYPE_RES';
export const DELETE_APARTMENT_REQ = 'DELETE_APARTMENT_REQ';
export const DELETE_APARTMENT_RES = 'DELETE_APARTMENT_RES';
export const GET_MORE_APARTMENTS_REQ = 'GET_MORE_APARTMENTS_REQ';
export const GET_MORE_APARTMENTS_RES = 'GET_MORE_APARTMENTS_RES';

export const GET_INVITES_REQ = 'GET_INVITES_REQ';
export const GET_INVITES_RES = 'GET_INVITES_RES';
export const POST_INVITE_REQ = 'POST_INVITE_REQ';
export const POST_INVITE_RES = 'POST_INVITE_RES';
export const INVITE_TRADER_REQ = 'INVITE_TRADER_REQ';
export const INVITE_TRADER_RES = 'INVITE_TRADER_RES';

export const POST_ACCEPT_INVITE_REQ = 'POST_ACCEPT_INVITE_REQ';
export const POST_ACCEPT_INVITE_RES = 'POST_ACCEPT_INVITE_RES';

export const GET_REQUEST_REQ = 'GET_REQUEST_REQ';
export const GET_REQUEST_RES = 'GET_REQUEST_RES';
export const GET_MY_REQUEST_REQ = 'GET_MY_REQUEST_REQ';
export const GET_MY_REQUEST_RES = 'GET_MY_REQUEST_RES';
export const POST_APPROVE_REQUEST_REQ = 'POST_APPROVE_REQUEST_REQ';
export const POST_APPROVE_REQUEST_RES = 'POST_APPROVE_REQUEST_RES';
export const POST_REQUEST_TO_JOIN_REQ = 'POST_REQUEST_TO_JOIN_REQ';
export const POST_REQUEST_TO_JOIN_RES = 'POST_REQUEST_TO_JOIN_RES';

export const GET_REPAIRS_REQ = 'GET_REPAIRS_REQ';
export const GET_REPAIRS_RES = 'GET_REPAIRS_RES';
export const GET_CONTRACTORS_REQ = 'GET_CONTRACTORS_REQ';
export const GET_CONTRACTORS_RES = 'GET_CONTRACTORS_RES';
export const SET_NAVIGATION_PARAMS = 'SET_NAVIGATION_PARAMS';
export const SET_REPAIR_LOCATION = 'SET_REPAIR_LOCATION';
export const SET_REPAIR_TYPE = 'SET_REPAIR_TYPE';
export const SET_REPAIR_PHOTOS = 'SET_REPAIR_PHOTOS';
export const SET_REPAIR_GENERAL_DATA = 'SET_REPAIR_GENERAL_DATA';
export const SET_SUPPORT_SERVICE = 'SET_SUPPORT_SERVICE';
export const SET_REPAIR_MORE_DETAILS = 'SET_REPAIR_MORE_DETAILS';
export const SET_REPAIR_IS_TEMPLATE = 'SET_REPAIR_IS_TEMPLATE';
export const GET_REPAIR_LOCATIONS_AND_TYPES_TREE_REQ =
  'GET_REPAIR_LOCATIONS_AND_TYPES_TREE_REQ';
export const GET_REPAIR_LOCATIONS_AND_TYPES_TREE_RES =
  'GET_REPAIR_LOCATIONS_AND_TYPES_TREE_RES';
export const GET_JOB_TEMPLATES_REQ = 'GET_JOB_TEMPLATES_REQ';
export const GET_JOB_TEMPLATES_RES = 'GET_JOB_TEMPLATES_RES';
export const POST_REPAIR_REQ = 'POST_REPAIR_REQ';
export const POST_REPAIR_RES = 'POST_REPAIR_RES';
export const POST_APARTMENT_IN_REPAIR_REQ = 'POST_APARTMENT_IN_REPAIR_REQ';
export const POST_APARTMENT_IN_REPAIR_RES = 'POST_APARTMENT_IN_REPAIR_RES';
export const DELETE_REPAIR_REQ = 'DELETE_REPAIR_REQ';
export const DELETE_REPAIR_RES = 'DELETE_REPAIR_RES';

export const RESET_REPAIR = 'RESET_REPAIR';

export const GET_SINGLE_REPAIR_REQ = 'GET_SINGLE_REPAIR_REQ';
export const GET_SINGLE_REPAIR_RES = 'GET_SINGLE_REPAIR_RES';

export const GET_SINGLE_BUILDING_REQ = 'GET_SINGLE_BUILDING_REQ';
export const GET_SINGLE_BUILDING_RES = 'GET_SINGLE_BUILDING_RES';
export const GET_BUILDING_REQ = 'GET_BUILDING_REQ';
export const GET_BUILDING_RES = 'GET_BUILDING_RES';

export const POST_VOTE_IN_REPAIR_REQ = 'POST_VOTE_IN_REPAIR_REQ';
export const POST_VOTE_IN_REPAIR_RES = 'POST_VOTE_IN_REPAIR_RES';
export const POST_VOTE_FOR_ANOTHER_IN_REPAIR_REQ =
  'POST_VOTE_FOR_ANOTHER_IN_REPAIR_REQ';
export const POST_VOTE_FOR_ANOTHER_IN_REPAIR_RES =
  'POST_VOTE_FOR_ANOTHER_IN_REPAIR_RES';

export const GET_BUILDING_APARTMENTS_REQ = 'GET_BUILDING_APARTMENTS_REQ';
export const GET_BUILDING_APARTMENTS_RES = 'GET_BUILDING_APARTMENTS_RES';
export const GET_BUILDING_REPAIRS_REQ = 'GET_BUILDING_REPAIRS_REQ';
export const GET_BUILDING_REPAIRS_RES = 'GET_BUILDING_REPAIRS_RES';
export const GET_BUILDING_INVITES_REQ = 'GET_BUILDING_INVITES_REQ';
export const GET_BUILDING_INVITES_RES = 'GET_BUILDING_INVITES_RES';
export const GET_JOIN_REQUESTS_REQ = 'GET_JOIN_REQUESTS_REQ';
export const GET_JOIN_REQUESTS_RES = 'GET_JOIN_REQUESTS_RES';
export const DELETE_IMAGE_SHARED_REPAIRS_REQ =
  'DELETE_IMAGE_SHARED_REPAIRS_REQ';
export const DELETE_IMAGE_SHARED_REPAIRS_RES =
  'DELETE_IMAGE_SHARED_REPAIRS_RES';

export const GET_FAQS_SHARED_REPAIRS_REQ = 'GET_FAQS_SHARED_REPAIRS_REQ';
export const GET_FAQS_SHARED_REPAIRS_RES = 'GET_FAQS_SHARED_REPAIRS_RES';

export const GET_CHAT_HISTORY_REQ = 'GET_CHAT_HISTORY_REQ';
export const GET_CHAT_HISTORY_RES = 'GET_CHAT_HISTORY_RES';
export const POST_CHAT_MESSAGE_REQ = 'POST_CHAT_MESSAGE_REQ';
export const POST_CHAT_MESSAGE_RES = 'POST_CHAT_MESSAGE_RES';
export const RESET_CHAT_HISTORY = 'RESET_CHAT_HISTORY';

export const POST_TOPUP_AND_PAY_RES = 'POST_TOPUP_AND_PAY_RES';
export const POST_AUTHORIZE_PAYMENT_REQ = 'POST_AUTHORIZE_PAYMENT_REQ';
export const POST_AUTHORIZE_PAYMENT_RES = 'POST_AUTHORIZE_PAYMENT_RES';
export const POST_APPLY_FOR_MODULR_ACCOUNT_REQ =
  'POST_APPLY_FOR_MODULR_ACCOUNT_REQ';
export const POST_APPLY_FOR_MODULR_ACCOUNT_RES =
  'POST_APPLY_FOR_MODULR_ACCOUNT_RES';
export const POST_ACCEPT_DECLARATION_REQ = 'POST_ACCEPT_DECLARATION_REQ';
export const POST_ACCEPT_DECLARATION_RES = 'POST_ACCEPT_DECLARATION_RES';
export const POST_CLOSE_VOTE_REQ = 'POST_CLOSE_VOTE_REQ';
export const POST_CLOSE_VOTE_RES = 'POST_CLOSE_VOTE_RES';

//VOLUNTEERING

export const GET_MATCHES_NOMATCH_REQ = 'GET_MATCHES_NOMATCH_REQ';
export const GET_MATCHES_NOMATCH_RES = 'GET_MATCHES_NOMATCH_RES';

export const GET_MATCHES_MINE_REQ = 'GET_MATCHES_MINE_REQ';
export const GET_MATCHES_MINE_RES = 'GET_MATCHES_MINE_RES';

export const PATCH_ACCEPT_MATCH_REQ = 'PATCH_ACCEPT_MATCH_REQ';
export const PATCH_ACCEPT_MATCH_RES = 'PATCH_ACCEPT_MATCH_RES';

export const PATCH_DROP_MATCH_REQ = 'PATCH_DROP_MATCH_REQ';
export const PATCH_DROP_MATCH_RES = 'PATCH_DROP_MATCH_RES';

export const GET_LOGFORMS_REQ = 'GET_LOGFORMS_REQ';
export const GET_LOGFORMS_RES = 'GET_LOGFORMS_RES';

export const GET_FINES_LIST_REQ = 'GET_FINES_LIST_REQ';
export const GET_FINES_LIST_RES = 'GET_FINES_LIST_RES';
export const CHECK_FINE_IS_PAID_REQ = 'CHECK_FINE_IS_PAID_REQ';
export const CHECK_FINE_IS_PAID_RES = 'CHECK_FINE_IS_PAID_RES';
export const PAY_A_FINE_REQ = 'PAY_A_FINE_REQ';
export const PAY_A_FINE_RES = 'PAY_A_FINE_RES';

export const GET_BILLS_LIST_REQ = 'GET_BILLS_LIST_REQ';
export const GET_BILLS_LIST_RES = 'GET_BILLS_LIST_RES';
export const CHECK_BILL_IS_PAID_REQ = 'CHECK_BILL_IS_PAID_REQ';
export const CHECK_BILL_IS_PAID_RES = 'CHECK_BILL_IS_PAID_RES';
export const PAY_A_BILL_REQ = 'PAY_A_BILL_REQ';
export const PAY_A_BILL_RES = 'PAY_A_BILL_RES';

//SERVICE_USER
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';
export const POST_FINE_REQ = 'POST_FINE_REQ';
export const POST_FINE_RES = 'POST_FINE_RES';
export const GET_FINE_TYPES_REQ = 'GET_FINE_TYPES_REQ';
export const GET_FINE_TYPES_RES = 'GET_FINE_TYPES_RES';
export const GET_FINES_HISTORY_REQ = 'GET_FINES_HISTORY_REQ';
export const GET_FINES_HISTORY_RES = 'GET_FINES_HISTORY_RES';
export const GET_TICKET_STATUS_RES = 'GET_TICKET_STATUS_RES';
export const GET_TICKET_STATUS_REQ = 'GET_TICKET_STATUS_REQ';
export const GET_DRIVER_PRESENCE_STATUS_REQ = 'GET_DRIVER_PRESENCE_STATUS_REQ';
export const GET_DRIVER_PRESENCE_STATUS_RES = 'GET_DRIVER_PRESENCE_STATUS_RES';
export const SET_PRINTER_NAME = 'SET_PRINTER_NAME';
export const SET_AUTO_PRINT = 'SET_AUTO_PRINT';
export const FINES_HISTORY_ERROR = 'FINES_HISTORY_ERROR';
export const GET_SINGLE_FINE_REQ = 'GET_SINGLE_FINE_REQ';
export const GET_SINGLE_FINE_RES = 'GET_SINGLE_FINE_RES';
export const SET_VEHICLE_DATA = 'SET_VEHICLE_DATA';
export const SET_OWENER_DATA = 'SET_OWENER_DATA';
export const SET_DATE_LOCATION_AND_PHOTO = 'SET_DATE_LOCATION_AND_PHOTO';
export const SET_VIOLATIONS_DATA = 'SET_VIOLATIONS_DATA';
export const RESET_FINE = 'RESET_FINE';
export const SET_FINE_TYPES = 'SET_FINE_TYPES';
export const SET_PENALTY_ACTIONS = 'SET_PENALTY_ACTIONS';
export const GET_AMMOUNT_AND_PENALTYDAYS_REQ =
  'GET_AMMOUNT_AND_PENALTYDAYS_REQ';
export const GET_AMMOUNT_AND_PENALTYDAYS_RES =
  'GET_AMMOUNT_AND_PENALTYDAYS_RES';
export const SET_REMARKS_AND_AUTHORITY_REMARKS =
  'SET_REMARKS_AND_AUTHORITY_REMARKS';
export const APPLY_EGRITOS_RECOMMENDATIONS_REQ =
  'APPLY_EGRITOS_RECOMMENDATIONS_REQ';
export const APPLY_EGRITOS_RECOMMENDATIONS_RES =
  'APPLY_EGRITOS_RECOMMENDATIONS_RES';
export const SET_SESSION_DOMAIN = 'SET_SESSION_DOMAIN';
export const RESET_SESSION_DOMAIN = 'RESET_SESSION_DOMAIN';
export const GET_TEMPLATES_REQ = 'GET_TEMPLATES_REQ';
export const GET_TEMPLATES_RES = 'GET_TEMPLATES_RES';
export const SET_ONBOARDING_AUTHORITIES = 'SET_ONBOARDING_AUTHORITIES';
export const SET_TEMPLATE_BASE_ON_AUTHORITY = 'SET_TEMPLATE_BASE_ON_AUTHORITY';
export const GET_MORE_FINES_HISTORY_RES = 'GET_MORE_FINES_HISTORY_RES';

//ACTION CANDIDATES SUSPICIOUS PARKING SPOTS

export const GET_ACTION_CANDIDATES_REQ = 'GET_ACTION_CANDIDATES_REQ';
export const GET_ACTION_CANDIDATES_RES = 'GET_ACTION_CANDIDATES_RES';
