import { getLanguage } from "../services/GRNLwithoutDep";
import {
    GET_COUNTRIES_REQUEST,
    GET_COUNTRIES_RESPONSE,
    SET_SELECTED_COUNTRY,
    SET_SELECTED_AUTHORITY,
    GET_AUTHORITIES_REQUEST,
    GET_AUTHORITIES_RESPONSE,
    GET_PROPERTY_FACTORS_RESPONSE,
    GET_TRANSLATIONS_REQUEST,
    GET_TRANSLATIONS_RESPONSE,
    SET_ORIGINAL_URL_PARAMS,
    UNSET_ORIGINAL_URL_PARAMS,
    GET_MODULES_RES,
    GET_PERMISSIONS_RES,
    GET_AUTHORITY_DETAILS_RES,
    GET_TICKETS_RES,
    SET_BADGE_NUMBER,
    SET_SELECTED_LANGUAGE,
    GET_GEOZONES_RES,
    SET_INTERNET_STATUS,
    GET_PLACEMARKS_RES,
    SET_DYNAMIC_LINKS_PARAMS,
    RESET_DYNAMIC_LINKS_PARAMS,
    DELETE_DYNAMIC_LINKS_PARAMS,
    SET_PARKING_MODE,
    SET_SUPPORTED_SOCIAL_NETWORK_AUTH,
    SET_USER_DECLINED_CHANGING_AUTHORITY,
} from "./actionTypes";

export const countriesReducer = (state, action) => {

    const defaultState = {
        property_factors_for_selected_country: [],
        loading: false,
        selected_country: getLanguage() || "el"
    }

    if (!state) {
        state = defaultState
    }

    switch (action.type) {
        case GET_COUNTRIES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_COUNTRIES_RESPONSE:
            return {
                ...state,
                loading: false,
                countries: action.payload
            };
        case GET_AUTHORITIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_AUTHORITIES_RESPONSE:
            return {
                ...state,
                loading: false,
                authorities_for_selected_country: action.payload.authoritiesForCountry
            };
        case GET_PROPERTY_FACTORS_RESPONSE:
            return {
                ...state,
                property_factors_for_selected_country: action.payload.propertyFactorsForCountry || []
            }
        case SET_SELECTED_COUNTRY:
            return {
                ...state,
                loading: false,
                selected_country: action.payload,
                selected_authority: null
            };
        case SET_SELECTED_AUTHORITY:
            return {
                ...state,
                loading: false,
                selected_authority: action.payload
            };

        case "persist/PURGE":
            return {
                countries: state.countries,
                authorities_for_selected_country: state.authorities_for_selected_country,
                selected_country: state.selected_country,
                loading: false,
            }

        default:
            return state;
    }
};


export const translationsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_TRANSLATIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_TRANSLATIONS_RESPONSE:
            return {
                ...state,
                loading: false,
                translations: action.payload
            };
        default:
            return state;
    }
};
export const settingsReducer = (state, action) => {
    if (!state) {
        state = {
            selectedLanguage: null,
            dynamicLinksParams: {},
            authorityRoutes: {},
        }
    }
    switch (action.type) {
        case SET_SELECTED_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            }
        case SET_DYNAMIC_LINKS_PARAMS:
            return {
                ...state,
                dynamicLinksParams: action.payload
            }
        case DELETE_DYNAMIC_LINKS_PARAMS:
            return {
                ...state,
                dynamicLinksParams: action.payload
            }
        case RESET_DYNAMIC_LINKS_PARAMS:
            return {
                ...state,
                dynamicLinksParams: {}
            }
        case SET_USER_DECLINED_CHANGING_AUTHORITY:
            return {
                ...state,
                authorityRoutes: {
                    ...state.authorityRoutes,
                    ...action.payload
                }
            };
        default:
            return state
    }
}

export const otherReducer = (state, action) => {
    if (!state) {
        state = {
            params: { redirect_url: null },
            modules: [/* 
                { name: "messages" },
                { name: "reports" },
                { name: "mycity" },
                { name: "faqs" },
                { name: "phones" },
                { name: "pois" },
                { name: "calendar" },
                { name: "statistics" },
                { name: "social" }, */
                // { name: "forms" },
            ],
            badgeNumber: null,
            selectedLanguage: null,
            hasInternet: false,
            allowAuthWithSocial: {}
        }
    }
    switch (action.type) {

        case SET_BADGE_NUMBER:
            return {
                ...state,
                badgeNumber: action.payload,
            }
        case SET_ORIGINAL_URL_PARAMS:
            return {
                ...state,
                params: action.payload
            };
        case UNSET_ORIGINAL_URL_PARAMS:
            return {
                ...state,
                params: { ...state.params, redirect_url: null }
            };
        case GET_MODULES_RES:
            return {
                ...state,
                modules: action.payload,
            }
        case GET_AUTHORITY_DETAILS_RES:
            return {
                ...state,
                authorityDetails: action.payload
            }
        case SET_INTERNET_STATUS:
            return {
                ...state,
                isConnectedToInternet: action.payload
            }
        case SET_SUPPORTED_SOCIAL_NETWORK_AUTH:
            return {
                ...state,
                allowAuthWithSocial: action.payload.allowAuthWithSocial
            }

        //TODO: TOASK: CHANGE THIS. ITS PROBLEMATIC BUT I DIDNT KNOW OF ANY OTHER WAY TO APPROACH IT
        case "GOBACKDATASTORE":
            console.log("EOEOEOE")
            return {
                ...state,
                goBackData: action.payload
            }
        case "CLEANGOBACKDATA":
            return {
                ...state,
                goBackData: undefined
            }

        default:
            return state;
    }
}

export const permissionsReducer = (state, action) => {
    const defaultState = {
        permissions: []
    }

    if (!state) {
        state = defaultState
    }


    switch (action.type) {
        case GET_PERMISSIONS_RES:
            return {
                ...state,
                permissions: action.payload,
            }
        default:
            return { ...state }

    }
}

export const parkingReducer = (state, action) => {

    let defaultState = {
        ticketData: {}
    }

    if (!state) {
        state = defaultState;
    }
    switch (action.type) {
        case GET_TICKETS_RES:
            return {
                ...state,
                ticketData: action.payload || {}
            };
        case GET_GEOZONES_RES:
            return {
                ...state,
                geozones: action.payload
            }
        case GET_PLACEMARKS_RES:
            if (action.info === "parking_placemarks") {
                return {
                    ...state,
                    ...action.payload,
                }
            } else {
                return state
            }
        case "GET_CAR_PARKS_RES":
            return {
                ...state,
                carParks: action.payload
            }
        case SET_PARKING_MODE:
            return {
                ...state,
                parkingMode: action.payload
            }
        case "persist/PURGE":
            return defaultState;

        default:
            return state;
    }
}

export const parkingAvailabilityReducer = (state, action) => {

    let defaultState = {
        availability: null
    }

    if (!state) {
        state = defaultState;
    }
    switch (action.type) {
        case "GET_PARKING_AVAILABILITY_RES":
            return {
                ...state,
                availability: action.payload
            }

        case "persist/PURGE":
            return defaultState;

        default:
            return state;
    }
}