import * as ATypes from "../../shared/actionTypes";

export const faqsReducer = (state, action) => {

    if (!state) {
        state = {
            faqs: []
        }
    }

    switch (action.type) {
        case ATypes.GET_FAQS_RES:
            var a =
                {
                    ...state,
                    faqs: action.payload
                }
            return a;
        default:
            return state;
    }
};