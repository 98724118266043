import WEBAPI from "../../services/WEBAPI.js";
import * as ATypes from "../../shared/actionTypes";

export const getPhones = (authorityName) => {

    return (dispatch, getState) => {
        dispatch({
            type: ATypes.GET_PHONES_REQ
        });

        return WEBAPI.getPhones(authorityName)
            .then(res => {
                dispatch({
                    type: ATypes.GET_PHONES_RES,
                    payload: res.result
                });
            })

    };
};