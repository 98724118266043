import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { ArrowLeft, ChevronRight, Search } from 'lucide-react';
import { connect } from 'react-redux';
import { NovovilleInput, Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import { looseStrCmpr } from '../../services/GNRL';
import { translate } from '../../services/TranslationService';
import InformationScreen from '../../shared/components/InformationScreen/InformationScreen';
import { withTranslation } from '../../shared/components/withTranslation';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import Splash from '../startup/Splash';
import { getFAQS } from './actions';

// Turn this attorcity of navigation to react-router v6
class FAQS extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      loading: true,
      nodeRef: undefined,
      nodeRefSearch: undefined,
      shouldShowBack: false,
      searchBox: '',
      selectedNode: '',
    };
    this.changeDisplayNode = this.changeDisplayNode.bind(this);
    this.goToPreviousNode = this.goToPreviousNode.bind(this);
    this.props.setHeaderTitle(translate('sideMenu.FAQ'));
  }

  componentDidMount() {
    this.fetchFAQs();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authority !== this.props.authority) {
      this.fetchFAQs();
    }
  }

  fetchFAQs() {
    const { getFAQS } = this.props;
    this.setState({ loading: true });
    getFAQS().then(() => {
      if (!this.state.nodeRef && this.props.faqsTree) {
        this.setState({ nodeRef: this.props.faqsTree });
      }
      this.setState({ loading: false });
    });
  }

  changeDisplayNode(item) {
    if (this.state.searchBox === '') {
      this.setState({
        nodeRef: item,
        shouldShowBack: !!item.parentRef,
        selectedNode: item.title,
      });
    } else {
      this.setState({
        nodeRefSearch: item,
        shouldShowBack: !!item.parentRef,
        selectedNode: item.title,
      });
    }
  }

  goToPreviousNode() {
    if (this.state.searchBox === '') {
      if (this.state.nodeRef.parent_id === null) {
        return;
      }
      this.changeDisplayNode(this.state.nodeRef.parentRef);
    } else {
      if (this.state.nodeRefSearch.parent_id === null) {
        return;
      }
      this.changeDisplayNode(this.state.nodeRefSearch.parentRef);
    }
  }

  searchBarChanged(textValue) {
    if (textValue === '') {
      this.setState({
        searchBox: '',
        nodeRefSearch: undefined,
        shouldShowBack: false,
        selectedNode: '',
        nodeRef: this.props.faqsTree,
        noSearchResults: false,
      });
    } else {
      const allNodes = [
        ...this.props.allLeafNodes,
        ...this.props.faqsTree.childrenRef,
      ];

      const filteredNodes = allNodes
        .map((item) => {
          return { ...item, parentRef: undefined };
        })
        .filter((faq) => {
          return looseStrCmpr(faq.title, textValue);
        });

      let tempNode;
      let noSearchResults = false;
      if (filteredNodes.length > 0) {
        tempNode = {
          childrenRef: filteredNodes,
          parent_id: null,
          body: [],
          title: '',
        };
      } else {
        tempNode = {
          childrenRef: [],
          parent_id: null,
          body: [],
          title: '',
        };
        noSearchResults = true;
      }

      const shouldShowBack =
        tempNode.childrenRef.length > 0 && tempNode.parent_id !== null;

      this.setState({
        searchBox: textValue,
        nodeRefSearch: tempNode,
        shouldShowBack: shouldShowBack,
        selectedNode: tempNode.title,
        noSearchResults: noSearchResults,
      });
    }
  }

  renderNode(displayNode) {
    if (!displayNode) {
      return null;
    }

    if (!displayNode.childrenRef || displayNode.childrenRef.length === 0) {
      return (
        <>
          {this.state.selectedNode && this.state.selectedNode !== 'No data' && (
            <Typography variant="bold_20" className="text-gray-900 pb-[24px]">
              {this.state.selectedNode || displayNode.title}
            </Typography>
          )}
          {displayNode.body.length > 0 && (
            <div className="flex flex-col py-[12px] px-[16px] bg-gray-50 rounded-[12px]">
              {displayNode.body.map((item, index) => {
                if (item.type === 'text') {
                  return (
                    <div key={`text-${index}`}>
                      <Typography
                        variant="semibold_16"
                        className="text-gray-800"
                      >
                        {item.value}
                      </Typography>
                    </div>
                  );
                }

                if (item.type === 'list') {
                  return (
                    <div key={`list-${index}`}>
                      {item.values.map((listItem, listIndex) => (
                        <div
                          className="flex items-center"
                          key={`list-item-${listIndex}`}
                        >
                          <Typography
                            variant="semibold_16"
                            className="text-gray-800"
                          >
                            {`${listIndex + 1}. ` + listItem.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  );
                }

                return null;
              })}
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="flex flex-col">
          {this.state.selectedNode && this.state.selectedNode !== 'No data' && (
            <Typography variant="bold_20" className="text-gray-900 pb-[24px]">
              {this.state.selectedNode || displayNode.title}
            </Typography>
          )}
          {displayNode?.childrenRef?.map((faq, index) => {
            let that = this;
            return (
              <div
                key={faq.title + index}
                onClick={(item) => {
                  that.changeDisplayNode(faq);
                }}
              >
                <div className="flex items-center justify-between hover:bg-gray-100 cursor-pointer rounded-[12px]">
                  <Typography
                    variant="semibold_16"
                    className="text-gray-800 py-[12px] px-[16px]"
                  >
                    {faq.title}
                  </Typography>
                  <div className="flex items-center pr-[16px]">
                    <ChevronRight className="w-4 h-4 text-gray-900" />
                  </div>
                </div>
                {index < displayNode?.childrenRef?.length - 1 && (
                  <Separator className="bg-gray-300 my-[24px]" />
                )}
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    let {
      shouldShowBack,
      searchBox,
      loading,
      nodeRefSearch,
      nodeRef,
      noSearchResults,
    } = this.state;
    const { faqs } = this.props;

    if (loading) {
      return <Splash />;
    }

    let displayContent;
    if (faqs.length === 0) {
      displayContent = <InformationScreen />;
    } else {
      let displayNode = nodeRef;

      if (searchBox !== '') {
        displayNode = nodeRefSearch;
      }

      displayContent = (
        <>
          <NovovilleInput
            iconLeft={<Search className="w-4 h-4 text-gray-500" />}
            placeholder={translate('Search')}
            value={searchBox}
            onChange={(e) => {
              this.searchBarChanged(e.target.value);
            }}
          />
          <div
            onClick={() => {
              this.goToPreviousNode();
            }}
            className="cursor-pointer"
          >
            {shouldShowBack && (
              <div
                className={`flex items-center gap-2 text-gray-600 ${shouldShowBack ? 'pt-[26px]' : ''}`}
              >
                <ArrowLeft className="w-4 h-4" />
                <Typography
                  variant="semibold_14"
                  className="text-gray-600 hover:underline"
                >
                  {translate('BACK')}
                </Typography>
              </div>
            )}
          </div>
          <div className="flex flex-col pt-[24px]">
            {noSearchResults ? (
              <Typography variant="bold_20" className="text-gray-900 pb-[24px]">
                {translate('no_items')}
              </Typography>
            ) : (
              this.renderNode(displayNode)
            )}
          </div>
        </>
      );
    }

    return (
      <div className="flex sm:p-[40px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0">
        <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 sm:overflow-auto overflow-y-scroll">
          {displayContent}
        </div>
      </div>
    );
  }
}

var mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFAQS,
    },
    dispatch
  );

var mapStateToProps = (state) => {
  return {
    faqs: state.faqsReducer.faqs,
    faqsTree: constructTreeFromFAQs(state.faqsReducer.faqs),
    allLeafNodes: getLeafNodesFromTree(state.faqsReducer.faqs),
    authority: state.profileReducer.profile.authority.name,
  };
};

export default withTranslation(
  withNOVOHeader(connect(mapStateToProps, mapDispatchToProps)(FAQS))
);

function constructTreeFromFAQs(faqsOld) {
  let faqs = JSON.parse(JSON.stringify(faqsOld));
  for (let i = 0; i < faqs.length; i++) {
    for (let j = 0; j < faqs.length - 1; j++) {
      if (faqs[j] && faqs[i]) {
        if (faqs[j]?.id === faqs[i]?.parent_id) {
          if (!faqs[j].childrenRef) {
            faqs[j].childrenRef = [];
          }
          faqs[j].childrenRef.push(faqs[i]);
          faqs[i].parentRef = faqs[j];
          break;
        }
      }
    }
  }

  var firstNodes = faqs.filter((faq) => {
    return faq?.parent_id === 0;
  });

  let parentNode = {
    childrenRef: firstNodes,
    parent_id: null,
    body: [],
    title: '',
  };

  parentNode.childrenRef.map((faq) => {
    return (faq.parentRef = parentNode);
  });

  return parentNode;
}

function getLeafNodesFromTree(faqsOld) {
  let faqs = JSON.parse(JSON.stringify(faqsOld));
  return faqs.filter((faq) => {
    return faq.children.length === 0;
  });
}
