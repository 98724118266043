import React from 'react';
import { CheckCircle, CircleAlert, Clock5 } from 'lucide-react';
import { useDispatch } from 'react-redux';
import config from '@/config';
import { goTo } from '@/shared/actions';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';

const CityReports = ({ solved, pending, inProgress }) => {
  const isScreenMobile = window.innerWidth < config.customMedia.mobile;
  const dispatch = useDispatch();
  return (
    <div className="flex">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
        <div className="flex sm:flex-row md:flex-row flex-col items-center justify-between">
          <Typography variant="bold_20" className="text-gray-900">
            {translate('City reports')}
          </Typography>
          {!isScreenMobile && (
            <Typography
              variant="semibold_16"
              className="leading-[24px] text-gray-500 hover:text-gray-700 cursor-pointer hover:underline"
              onClick={() => dispatch(goTo('myCity'))}
            >
              {translate('See all city reports')}
            </Typography>
          )}
        </div>
        <div className="flex sm:flex-row md:flex-row flex-col sm:gap-[24px] md:gap-[24px] gap-[16px]">
          <div className="flex flex-col grow bg-gray-50 p-[24px] rounded-[16px] items-center justify-center">
            <div className="flex bg-strongCyan rounded-full mb-[8px] w-[40px] h-[40px] flex items-center justify-center">
              <CheckCircle className="w-[24px] h-[24px] text-white" />
            </div>
            <Typography variant="bold_32" className="text-gray-900 pb-[4px]">
              {solved}
            </Typography>
            <Typography variant="semibold_16" className="text-gray-700">
              {translate('Solved reports')}
            </Typography>
          </div>
          <div className="flex flex-col grow bg-gray-50 p-[24px] rounded-[16px] items-center justify-center">
            <div className="flex bg-orange rounded-full mb-[8px] w-[40px] h-[40px] flex items-center justify-center">
              <CircleAlert className="w-[24px] h-[24px] text-white" />
            </div>
            <Typography variant="bold_32" className="text-gray-900 pb-[4px]">
              {inProgress}
            </Typography>
            <Typography variant="semibold_16" className="text-gray-700">
              {translate('In progress reports')}
            </Typography>
          </div>
          <div className="flex flex-col grow bg-gray-50 p-[24px] rounded-[16px] items-center justify-center">
            <div className="flex bg-peach rounded-full mb-[8px] w-[40px] h-[40px] flex items-center justify-center">
              <Clock5 className="w-[24px] h-[24px] text-white" />
            </div>
            <Typography variant="bold_32" className="text-gray-900 pb-[4px]">
              {pending}
            </Typography>
            <Typography variant="semibold_16" className="text-gray-700">
              {translate('Pending reports')}
            </Typography>
          </div>
        </div>
        {isScreenMobile && (
          <Typography
            variant="semibold_16"
            className="leading-[24px] text-gray-500 hover:text-gray-700 cursor-pointer hover:underline"
            onClick={() => dispatch(goTo('myCity'))}
          >
            {translate('See all city reports')}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default CityReports;
