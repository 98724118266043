import * as ATypes from "../../shared/actionTypes";

export const phonesReducer = (state, action) => {

    if (!state) {
        state = {
            phones: []
        }
    }

    switch (action.type) {
        case ATypes.GET_PHONES_RES:
            var a =
                {
                    ...state,
                    phones: action.payload.data
                }

            return a;
        default:
            return state;
    }
};