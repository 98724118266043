import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import '../../output.css';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../../shared/actions';
import { getLanguage } from '@/services/GNRL';
import { translate } from '@/services/TranslationService';

const LanguageSelect = ({ onLanguageChange, useFullName = false }) => {
  const dispatch = useDispatch();

  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    const initialLanguage = getLanguage();
    console.log('initialLanguage', initialLanguage);
    setSelectedLanguage(initialLanguage);
  }, []);

  const onLanguageChanged = async (selectedLanguage) => {
    await dispatch(setLanguage(selectedLanguage));
    onLanguageChange(selectedLanguage);
    setSelectedLanguage(selectedLanguage);
  };

  const languageShortName = (code) => {
    return code === 'en' ? translate('En') : translate('Gr');
  };

  const languageFullName = (code) => {
    return code === 'en' ? translate('English') : translate('Greek');
  };

  return (
    <Select
      className="self-end"
      onValueChange={(e) => {
        console.log('Language changed:', e);
        onLanguageChanged(e);
      }}
    >
      <SelectTrigger
        className={`flex flex-row border-[1px] border-[#CBD5E1] focus:border-[#35D8B7] rounded-md ${
          useFullName ? '' : 'sm:w-[200px]'
        }`}
      >
        <SelectValue
          className="pr-[20px]"
          placeholder={
            <>
              <span className="sm:hidden pr-[10px]">
                {useFullName
                  ? languageFullName(selectedLanguage)
                  : languageShortName(selectedLanguage)}
              </span>
              <span className="hidden sm:block">
                {languageFullName(selectedLanguage)}
              </span>
            </>
          }
        >
          <>
            <span className="sm:hidden pr-[10px]">
              {useFullName
                ? languageFullName(selectedLanguage)
                : languageShortName(selectedLanguage)}
            </span>
            <span className="hidden sm:block">
              {languageFullName(selectedLanguage)}
            </span>
          </>
        </SelectValue>
      </SelectTrigger>
      <SelectContent style={{ position: 'relative', zIndex: 100 }}>
        <SelectGroup>
          <SelectItem value="en">{translate('English')}</SelectItem>
          <SelectItem value="gr">{translate('Greek')}</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default LanguageSelect;
