import * as ATypes from '../../shared/actionTypes';
import WEBAPI from '../../services/WEBAPI';
import { translateAuthority } from '../../services/TranslationService';
import { getLanguage } from '../../services/GNRL';

export const setSelectedAuthority = (authName) => (dispatch, getState) => {
  dispatch({
    type: ATypes.SET_SELECTED_AUTHORITY,
    payload: authName,
  });
};

export const setSelectedCountry = (countryName) => (dispatch, getState) => {
  var selectedCountry = getState().countriesReducer.selected_country;
  const inPropertyFactor =
    getState().profileReducer.profile.in_property_factors;
  var countries = getState().countriesReducer?.countries || [];
  if (selectedCountry === countryName && countryName !== undefined) {
    return new Promise((resolve, reject) => {
      resolve('Country the same');
    });
  }

  if (!countries.find((item) => item.country_iso_code === selectedCountry)) {
    countryName = 'en';
  }
  //TOASK: + TODO: Determine which country this browser is from. Where should this method be? NOVOGNRL?
  if (!countryName && !selectedCountry) {
    let languageToSet = getLanguage();
    if (languageToSet !== 'el' && languageToSet !== 'en') {
      languageToSet = 'en';
    }

    countryName = languageToSet;
  }
  if (countryName) {
    dispatch({
      type: ATypes.SET_SELECTED_COUNTRY,
      payload: countryName,
    });
  }
  //ALSO get Authorities as setting a country will always request authorities
  if (inPropertyFactor)
    return dispatch(getPropertyFactorsForCountry(inPropertyFactor)).then(() => {
      return dispatch(getAllAuthoritiesForCountry(inPropertyFactor));
    });
  else {
    return dispatch(getAllAuthoritiesForCountry(inPropertyFactor));
  }
};

export const getPropertyFactorsForCountry = () => (dispatch, getState) => {
  dispatch({
    type: ATypes.GET_PROPERTY_FACTORS_REQUEST,
  });

  var selectedCountry = getState().countriesReducer.selected_country;

  return WEBAPI.getPropertyFactorsForCountry(selectedCountry).then((res) => {
    if (res.result[`${selectedCountry}`]) {
      const propertyFactorsForCountry = Object.values(
        res.result[`${selectedCountry}`]
      )?.map((name) => {
        console.log(name);
        debugger;
        return {
          name,
          translatedName: translateAuthority(name),
        };
      });
      dispatch({
        type: ATypes.GET_PROPERTY_FACTORS_RESPONSE,
        payload: {
          propertyFactorsForCountry,
        },
      });
    } else {
      dispatch({
        type: ATypes.GET_PROPERTY_FACTORS_RESPONSE,
        payload: {},
      });
    }
  });
};

// TOASK: THERES A DEPENDENCY ON THIS. SHOULD WE SOMEHOW DENOTE THAT THIS DEPENDS ON
// A SPECIFIC PART OF THE STATE OR?
export const getAllAuthoritiesForCountry = () => (dispatch, getState) => {
  dispatch({
    type: ATypes.GET_AUTHORITIES_REQUEST,
  });
  var selectedCountry = getState().countriesReducer.selected_country;
  return WEBAPI.getAllAuthoritiesForCountry(selectedCountry).then((res) => {
    var authoritiesForCountryTemp = Object.keys(res.result).map(
      (key) => res.result[key]
    );

    if (authoritiesForCountryTemp.length === 0) {
      dispatch({
        type: ATypes.GET_AUTHORITIES_RESPONSE,
        payload: {
          authoritiesForCountry: [],
        },
      });
      return;
    }

    var authoritiesForCountry = Object.keys(authoritiesForCountryTemp[0])
      .map((key) => {
        return authoritiesForCountryTemp[0][key];
      })
      .map((authority) => {
        var authorityObject = {};
        authorityObject.name = authority;
        authorityObject.translatedName = translateAuthority(
          authorityObject.name
        );
        return authorityObject;
      });
    dispatch({
      type: ATypes.GET_AUTHORITIES_RESPONSE,
      payload: {
        authoritiesForCountry,
      },
    });
    // dispatch(push("/login"));
  });
};
