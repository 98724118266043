import React from 'react';
import { createRoot } from 'react-dom/client';
import AppContainer from './features/AppContainer';
import store from './services/store';
import { initializeLanguage } from './shared/actions';

const persistedLanguage = localStorage.getItem('language') || 'en';
store.dispatch(initializeLanguage(persistedLanguage));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppContainer />);
