import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import {
  NovovilleButton,
  NovovilleInput,
  Typography,
} from '@/components/novoville';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { toast } from '@/components/ui/use-toast';
import EmailVerification from '../../resources/EmailVerification.svg';
import novoville_logo from '../../resources/novoville_logo.svg';
import { areFieldsValid, getLanguage } from '../../services/GNRL';
import { translate } from '../../services/TranslationService';
import { goBackRouter, verifyRequest } from '../../shared/actions';
import AuthHeader from '../AuthHeader/AuthHeader';
import { changeAndPostProfile } from '../profile/actions';
import { isEmailDummy } from '../profile/profileUtilities';
import { checkVerification } from './actions';

const AddEmail = ({
  emailVerified,
  dontAddDirectlyToProfile,
  invokedAsPopup,
}) => {
  const dispatch = useDispatch();

  const emailDummy = useSelector((state) => state.profileReducer.profile.email);
  const prefillEmail = isEmailDummy(emailDummy) ? '' : emailDummy;
  const socialMediaLogin = useSelector((state) => state.authReducer.login_type);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(prefillEmail);
  const [processState, setProcessState] = useState('VERIFY_REQUEST');
  const [requestSentForEmail, setRequestSentForEmail] = useState(null);
  const [language, setLanguage] = useState(getLanguage());
  const [isEmailValid, setIsEmailValid] = useState(
    areFieldsValid({ email: prefillEmail })
  );
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [recaptchaKey, setRecaptchaKey] = useState(0);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    setRecaptchaKey((prevKey) => prevKey + 1);
  };

  const recaptchaRef = useRef();

  const onChange = (field, value) => {
    if (value.length > 100) return;
    setEmail(value);
    setIsEmailValid(areFieldsValid({ email: value }));
  };

  const onCaptchaChange = (captchaResponse) => {
    setCaptchaResponse(captchaResponse);
  };

  const onCaptchaExpired = () => {
    setCaptchaResponse('');
  };

  const clickedVerify = () => {
    const captchaValueParam = recaptchaRef.current.getValue();

    setLoading(true);
    let emailSentInRequest = email;
    dispatch(
      verifyRequest(
        email,
        undefined,
        process.env.REACT_APP_NV_CAPTCHA_KEY,
        captchaValueParam
      )
    )
      .then((res) => {
        setLoading(false);
        setProcessState('VERIFY');
        setRequestSentForEmail(emailSentInRequest);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(goBackRouter({ verifiedEmail: emailSentInRequest }));
        toast({
          variant: 'destructive',
          title: translate('OTHER.errorOcurred'),
          description: err?.details?.user_description,
        });
      });
  };

  const handleVerification = () => {
    setLoading(true);
    dispatch(checkVerification(requestSentForEmail))
      .then((res) => {
        let isVerified = res.result[Object.keys(res.result)[0]];
        if (isVerified) {
          toast({
            title: translate('OTHER.success'),
            description: translate('email_verified'),
            variant: 'success',
          });
          if (socialMediaLogin !== 'email') {
            if (invokedAsPopup) {
              emailVerified(requestSentForEmail);
            } else {
              dispatch(goBackRouter());
            }
          } else {
            if (dontAddDirectlyToProfile) {
              setLoading(false);
              setProcessState('ADD_TO_PROFILE_PROMPT');
            } else {
              dispatch(
                changeAndPostProfile({ email: requestSentForEmail })
              ).then((res) => {
                if (invokedAsPopup) {
                  emailVerified(requestSentForEmail);
                } else {
                  dispatch(goBackRouter());
                }
              });
            }
          }
        } else {
          let err = new Error(translate('OTHER.errorOcurred'));
          err.details = { user_description: translate('OTHER.errorOcurred') };
          throw err;
        }
      })
      .catch((err) => {
        setLoading(false);
        toast({
          variant: 'destructive',
          title: translate('OTHER.errorOcurred'),
          description: err.details.user_description,
        });
      });
  };

  const renderVerifyRequest = (description) => {
    return (
      <div>
        <CardHeader className="text-center p-0 pb-[32px]">
          <CardTitle>
            <Typography variant="semibold_24">
              {translate('email_verification')}
            </Typography>
          </CardTitle>
          <CardDescription className="mt-0">
            <Typography variant="regular_16">{description}</Typography>
          </CardDescription>
        </CardHeader>
        <CardContent className="p-0">
          <div className="pb-[32px] grid gap-2">
            <Label htmlFor="email" className={'leading-[20px] font-medium'}>
              Email
            </Label>
            <NovovilleInput
              id="email"
              type="email"
              placeholder="Email"
              name="email"
              autocomplete="email"
              autofocus={true}
              className="drop-shadow-md"
              value={email}
              onChange={(e) => onChange('email', e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter className="p-0 flex flex-col gap-[32px] justify-center items-center">
          <ReCAPTCHA
            key={recaptchaKey}
            style={{
              justifyContent: 'center',
            }}
            className="drop-shadow-md"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_NV_CAPTCHA_KEY}
            onChange={onCaptchaChange}
            onExpired={onCaptchaExpired}
            hl={language === 'gr' ? 'el' : 'en'}
          />
          <NovovilleButton
            disabled={!isEmailValid || loading || !captchaResponse}
            onClick={clickedVerify}
            className="drop-shadow-md"
            variant="primary"
            text={translate(
              'EMAILVERIFICATION_step1_profile_inform_update_send_verification_button'
            )}
          />
        </CardFooter>
      </div>
    );
  };

  const renderVerify = (description) => {
    return (
      <div>
        <CardHeader className="text-center p-0 pb-[32px] flex flex-col items-center">
          <img
            src={EmailVerification}
            alt="Email Verification"
            className="w-[133px] h-[133px] pb-[32px] drop-shadow-md"
          />
          <CardTitle className="text-3xl">
            <Typography variant="semibold_24">
              {translate('confirm_verification')}
            </Typography>
          </CardTitle>
          <CardDescription className="mt-0">
            <Typography variant="regular_16">{description}</Typography>
          </CardDescription>
        </CardHeader>
        <CardContent className="p-0">
          <div className="pb-[32px] grid gap-2">
            <Label htmlFor="email" className={'leading-[20px] font-medium'}>
              Email
            </Label>
            <NovovilleInput
              className="drop-shadow-md "
              id="email"
              type="email"
              placeholder={translate('email')}
              autocomplete="email"
              autofocus={true}
              value={email}
              onChange={(e) => onChange('email', e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter className="p-0 flex flex-col justify-center items-end">
          <NovovilleButton
            className="drop-shadow-md"
            disabled={!isEmailValid || loading}
            onClick={handleVerification}
            variant="primary"
            text={translate('confirm_verification')}
          />
        </CardFooter>
      </div>
    );
  };

  let displayContent;
  let description;
  if (processState === 'VERIFY_REQUEST') {
    if (socialMediaLogin !== 'email') {
      description = translate('EMAILVERIFICATION_step1_verify_social_media');
    } else {
      description = translate('EMAILVERIFICATION_step1_form');
    }
    displayContent = renderVerifyRequest(description);
  } else {
    description = translate('EMAILVERIFICATION_step2');
    displayContent = renderVerify(description);
  }

  return (
    <div className="flex flex-wrap bg-white">
      <div className="h-[400vh] w-screen min-h-[100vh] bg-white overflow-hidden fixed" />
      <AuthHeader
        logo={novoville_logo}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="min-h-[calc(100vh-100px)] w-screen self-center flex flex-col justify-center sm:py-[74px] sm:px-8">
        <Card className="self-center w-full sm:p-[40px] max-w-[468px] drop-shadow-none shadow-none text-gray-800 px-[24px] border-none">
          <section key={'profile'} className="flex flex-column flex-grow">
            <div className="flex flex-column  flex-grow flex-top-bottom-margin">
              {displayContent}
            </div>
          </section>
        </Card>
      </div>
    </div>
  );
};

export default AddEmail;
