import React from 'react';
import { Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import FaceBookIcon from '../../../src/resources/Facebook.svg';
import GoogleIcon from '../../../src/resources/Google.svg';
import '../../output.css';

const socialStyles =
  'flex justify-center items-center gap-2 w-full px-4 py-2 text-gray-800 bg-white hover:bg-white hover:text-gray-800 border-[1px] border-gray-300';
const defaultStyles =
  'px-[16px] py-[8px] m-0 text-[14px] w-full leading-[24px] font-medium text-gray-800 bg-stringCyan hover:bg-strongCyan hover:text-gray-800 disabled:bg-gray-300 disabled:text-gray-800';
const secondaryVariantStyles =
  'px-4 py-2 w-full bg-white rounded-md border border-slate-200 text-slate-800 text-sm font-semibold font-[Open_Sans] leading-tight hover:bg-slate-100 disabled:opacity-40';
const primaryVariantStyles =
  'px-4 py-2 w-full bg-teal-400 rounded-md text-slate-800 text-sm font-semibold font-[Open_Sans] leading-tight hover:bg-teal-200 disabled:bg-teal-400 disabled:opacity-50';
const linkVariantStyles =
  'bg-white bg-opacity-0 rounded-md text-blueLink hover:text-blue font-semibold font-[Open_Sans] leading-tight';

const NovovilleButton = React.forwardRef(
  (
    { disabled, className, children, social, text, variant, loading, ...props },
    ref
  ) => {
    const isExternal =
      props.to?.startsWith('http://') || props.to?.startsWith('https://');

    const renderSocialContent = (social) => {
      switch (social) {
        case 'Facebook':
          return (
            <>
              <img
                src={FaceBookIcon}
                className="h-5 w-5"
                alt="Continue with Facebook"
              />
              {text}
            </>
          );
        case 'Google':
          return (
            <>
              <img
                src={GoogleIcon}
                className="h-5 w-5"
                alt="Continue with Google"
              />
              {text}
            </>
          );
        default:
          return children || text;
      }
    };

    const styles = {
      primary: primaryVariantStyles,
      secondary: secondaryVariantStyles,
      link: linkVariantStyles,
      social: socialStyles,
    };

    let buttonStyles =
      variant && styles[variant] ? styles[variant] : defaultStyles;

    const content = loading ? (
      <Loader2 className="animate-spin h-4 w-4" />
    ) : (
      renderSocialContent(social)
    );

    if (variant === 'link') {
      if (isExternal) {
        return (
          <a
            href={props.to}
            target="_blank"
            rel="noopener noreferrer"
            className={`${buttonStyles} flex justify-center items-center gap-2.5 ${className}`}
            {...props}
          >
            {content}
          </a>
        );
      } else {
        return (
          <Link
            className={`${buttonStyles} flex justify-center items-center gap-2.5 ${className}`}
            {...props}
          >
            {content}
          </Link>
        );
      }
    }
    return (
      <Button
        disabled={disabled || loading}
        className={`${buttonStyles} flex justify-center items-center gap-2.5 ${className}`}
        {...props}
      >
        {content}
      </Button>
    );
  }
);

export default NovovilleButton;
