import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavigationHeader from '../../shared/components/NavigationHeader';
import { toggleSideMenu } from './actions';

export function withNOVOHeader(CMP) {
  var hoc = class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        title: 'Default Title',
      };
    }

    setHeaderTitle(title) {
      this.setState({
        title,
      });
    }

    componentDidMount() {
      if (this.props.isOpen) {
        requestAnimationFrame(this.props.toggleSideMenu);
      }
    }

    render() {
      const { isOpen } = this.props;
      return (
        <div
          key="novoheader"
          id={'main'}
          className={`${
            isOpen
              ? 'column flex-column sideMenuOpen flex'
              : 'column flex-column flex'
          } w-min`}
        >
          <NavigationHeader title={this.state.title} isOpen={isOpen} />
          <CMP
            {...this.props}
            setHeaderTitle={(title) => {
              this.setHeaderTitle(title);
            }}
          />
        </div>
      );
    }
  };

  var mapStateToProps = (state) => ({
    isOpen: state.sideMenuReducer?.isOpen,
  });

  var mapDispathToProps = (dispatch) =>
    bindActionCreators(
      {
        toggleSideMenu,
      },
      dispatch
    );

  return connect(mapStateToProps, mapDispathToProps)(hoc);
}
