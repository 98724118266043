import WEBAPI from '../../services/WEBAPI.js';
import * as ATypes from '../../shared/actionTypes';
import { setSelectedCountry } from '../authoritySelection/actions';
import {
  translateAuthority,
  translateCountry,
} from '../../services/TranslationService';
import {
  getModules,
  getAuthorityDetails,
  getPermissions,
} from '../../shared/actions';
import { checkVerification as checkEmailVerification } from '../addEmail/actions.js';
const { logout } = global;

export const changeAndPostProfile = (profileObject) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.POST_PROFILE_REQ,
    });

    return addRequiredFieldsInProfileIfMissing(getState(), profileObject).then(
      (profileObject) => {
        return WEBAPI.postProfile(profileObject).then((res) => {
          dispatch({
            type: ATypes.POST_PROFILE_RES,
          });

          return dispatch(getProfile()).then(() => {
            return dispatch(getAuthorityDetails())
              .then(() => {
                return dispatch(getModules());
              })
              .then(() => {
                return dispatch(getPermissions());
              });
          });
        });
      }
    );
  };
};

export const postUserProfile = (profileObject) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.POST_PROFILE_REQ,
    });

    return addRequiredFieldsInProfileIfMissing(getState(), profileObject).then(
      (profileObject) => {
        return WEBAPI.postProfile(profileObject).then((res) => {
          dispatch({
            type: ATypes.POST_PROFILE_RES,
          });

          return dispatch(getProfile());
        });
      }
    );
  };
};

export const getProfile = ({ preserveProfileAuthorityAndCountry } = {}) => {
  //preserveProfileAuthorityAndCountry. When a user is registering with social media while being an unauth
  //user (unauth_whatever@novoville.com) or when logging in with an account that has no authority, there is a moment
  //due to how loginProcess works (because it uses getProfile) where since he has no authority, the reducer will be filled
  //with an empty authority. This is okay but since a lot of mounted components assume that there is an authority present in the profile
  //since we re already in the app, and are mounted due to DrawerNavigator, the app will crash. For this reason, since this is a transient step
  //that only lasts for a few seconds or however long the network request takes for us to automatically set their authority to the one they picked
  //as an unauth user, we are refraining from changing the authority in this scenario so that the app will continue to operate. A better idea could be
  //to revisit all of the app components that assume an authority and make them not render with one in mind however this would take a long time
  //and is not really practical as it would dirty the code as well. TODO: Revist this but it seems like a good enough compromise for now.

  return (dispatch, getState) => {
    dispatch({
      type: ATypes.GET_PROFILE_REQ,
    });

    return WEBAPI.getProfile()
      .then((res) => {
        //since the API is not returning all the information about the authority
        //we need to enrich it here
        let authority = res['result'].authority;
        if (authority) {
          authority.translatedName = translateAuthority(authority.name);
        }
        let country = res['result'].country;
        if (country) {
          country.translatedName = translateCountry(country.name);
        }

        dispatch({
          type: ATypes.GET_PROFILE_RES,
          payload: {
            profile: res['result'],
            preserveProfileAuthorityAndCountry,
          },
        });

        return dispatch(
          setSelectedCountry(res['result'].country.country_iso_code)
        ).then(() => {
          return JSON.parse(
            JSON.stringify({
              ...res['result'],
              authority,
              country,
            })
          );
        });
      })
      .then(async (res) => {
        const email = res?.email;
        const phoneNumber = res?.mobile;
        const loginType = getState()?.authReducer?.login_type;
        const isEmailVerified = getState()?.profileReducer?.hasVerifiedEmail;

        if (phoneNumber) {
          dispatch({
            type: ATypes.CHECK_MOBILE_VERIFY_RES,
            payload: true,
          });
        }

        if (loginType !== 'email') {
          dispatch({
            type: ATypes.CHECK_EMAIL_VERIFY_RES,
            payload: true,
          });
        } else {
          if (!isEmailVerified) await dispatch(checkEmailVerification(email));
        }

        return res;
      });
  };
};

export const doesUserHaveAuthoritySetRemotely = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'CHECK_IF_AUTHORITY_SET_REMOTELY',
    });

    return WEBAPI.getProfile().then((res) => {
      if (res['result'] && res['result'].authority && res['result'].country) {
        let authority = res['result'].authority;
        if (authority) {
          authority.translatedName = translateAuthority(authority.name);
        }
        let country = res['result'].country;
        if (country) {
          country.translatedName = translateCountry(country.name);
        }
        return {
          authority,
          country,
        };
      } else {
        return null;
      }
    });
  };
};

function addRequiredFieldsInProfileIfMissing(state, profileObject) {
  return new Promise((res, rej) => {
    if (!profileObject.hasOwnProperty('email')) {
      profileObject.email = state.profileReducer.profile.email;
    }

    // if (!profileObject.hasOwnProperty("name")) {
    //     profileObject.name = state.profileReducer.profile.name;
    // }

    // if (!profileObject.hasOwnProperty("surname")) {
    //     profileObject.surname = state.profileReducer.profile.surname;
    // }

    // if (!profileObject.hasOwnProperty("authority") && state.profileReducer.profile.authority.name) {
    //     profileObject.authority = state.profileReducer.profile.authority.name;
    // }

    // if (!profileObject.hasOwnProperty("country") && state.profileReducer.profile.country.country_iso_code) {
    //     profileObject.country = state.profileReducer.profile.country.country_iso_code;
    // }

    //if avatar is url make it into a file TODO move this to a sepearte fun
    if (
      profileObject.hasOwnProperty('avatar') &&
      typeof profileObject.avatar === 'string'
    ) {
      fetch(profileObject.avatar)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          profileObject.avatar = blob;
          res(profileObject);
        });
    } else {
      res(profileObject);
    }
  });
}

export const deleteAccount = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.DELETE_ACCOUNT_REQ_REQ,
    });

    return WEBAPI.deleteAccount()
      .then((res) => {
        dispatch({
          type: ATypes.DELETE_ACCOUNT_REQ_RES,
        });
        return res;
      })
      .then(() => {
        return logout();
      });
  };
};
