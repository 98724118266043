import WEBAPI from '../../services/WEBAPI.js';
import * as ATypes from '../../shared/actionTypes';

export const getMessages = (authorityName, messageId, typesArray) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.GET_MESSAGES_REQ,
    });

    return WEBAPI.getMessages(authorityName, messageId, typesArray).then(
      (res) => {
        dispatch({
          type: ATypes.GET_MESSAGES_RES,
          payload: res,
        });
        return res.result;
      }
    );
  };
};

export const getMoreMessages = (url) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.GET_MORE_MESSAGES_REQ,
    });

    return WEBAPI.getMoreMessages(url).then((res) => {
      dispatch({
        type: ATypes.GET_MORE_MESSAGES_RES,
        payload: res,
      });
    });
  };
};

export const getSingleMessage = (messageId, authorityId) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.GET_SINGLE_MESSAGE_REQ,
    });

    var authorityName;
    if (!authorityId) {
      authorityName = getState().profileReducer.profile.authority.name;
    } else {
      authorityName = authorityId;
    }

    return WEBAPI.getMessages(authorityName, messageId).then((res) => {
      dispatch({
        type: ATypes.GET_SINGLE_MESSAGE_RES,
        payload: res,
      });
      return res.result;
    });
  };
};

export const postPoll = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.POST_POLL_REQ,
    });

    return WEBAPI.postPoll(data).then((res) => {
      dispatch({
        type: ATypes.POST_POLL_RES,
        payload: data,
      });
      //TODO: TOREMOVE BAD BAD BAD
      //see ATypes.POST_POLL_RES reducer, need to restructure reducers
      // so for now adding this one line. fix soon!
      dispatch(getMessages());
      return res;
    });
  };
};

export const setApplicationBadge = (num) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.SET_BADGE_NUMBER,
      payload: num,
    });
  };
};
