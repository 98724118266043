import WEBAPI from '../../services/WEBAPI.js';
import * as ATypes from '../../shared/actionTypes';

export const getReports = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.GET_MY_REPORTS_REQ,
    });

    return WEBAPI.getReports().then((res) => {
      dispatch({
        type: ATypes.GET_MY_REPORTS_RES,
        payload: res['result'],
      });
    });
  };
};

export const getSingleReport = (reportId, authName) => {
  return (dispatch, getState) => {
    const state = getState();
    const authorityName = authName
      ? authName
      : state.profileReducer.profile.authority.name;

    const existingReport = state.reportsReducer.myReports.find(
      (report) => report.id === reportId
    );

    if (existingReport) {
      dispatch({
        type: ATypes.GET_SINGLE_REPORT_SUCCESS,
        payload: existingReport,
      });
      return Promise.resolve(existingReport);
    }

    dispatch({
      type: ATypes.GET_SINGLE_REPORT_REQUEST,
    });

    return WEBAPI.getReports(authorityName, reportId)
      .then((res) => {
        dispatch({
          type: ATypes.GET_SINGLE_REPORT_SUCCESS,
          payload: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: ATypes.GET_SINGLE_REPORT_FAILURE,
          error,
        });
      });
  };
};

export const getMoreReports = (nextUrl) => {
  return (dispatch) => {
    dispatch({
      type: ATypes.GET_MORE_REPORTS_REQ,
    });

    return WEBAPI.getMoreReports(nextUrl).then((res) => {
      dispatch({
        type: ATypes.GET_MORE_REPORTS_RES,
        payload: res?.result,
      });
    });
  };
};
