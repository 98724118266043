import FacebookIcon from '@/resources/FacebookIcon';
import XIcon from '@/resources/XIcon';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Typography } from '@/components/novoville';
import { translate } from '../../services/TranslationService';
import { goTo } from '../../shared/actions';
import { withTranslation } from '../../shared/components/withTranslation';
import { ReportItem } from '../reports/ReportItem';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import Splash from '../startup/Splash';
import Announcements from './Announcements';
import CityReports from './CityReports';
import MyReportsSection from './MyReportsSection';
import NewReportSection from './NewReportSection';
// import Statistics from './Statistics';
import { getHomeData } from './actions';

class Home extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.state = {
      loading: true,
    };
    this.props.setHeaderTitle(translate('sideMenu.Activity'));
  }

  componentDidMount() {
    const { getHomeData } = this.props;
    getHomeData().then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const {
      goTo,
      reports,
      statistics,
      translate,
      noCompleted,
      noPending,
      noInProgress,
      reportModuleEnabled,
      statisticsModuleEnabled,
      allMessages,
    } = this.props;

    const polls = allMessages.filter((message) => message.app_type === 'POLL');
    const announcements = allMessages.filter(
      (message) => message.app_type === 'ANNOUNCEMENT'
    );
    const events = allMessages.filter(
      (message) => message.app_type === 'EVENT'
    );

    if (this.state.loading) {
      return <Splash />;
    }

    return (
      <div className="flex flex-col overflow-auto h-full sm:gap-[24px] gap-[16px] sm:p-[24px] p-0">
        {reportModuleEnabled && <NewReportSection />}
        {reportModuleEnabled && <MyReportsSection reports={reports} />}
        <CityReports
          solved={noCompleted}
          pending={noPending}
          inProgress={noInProgress}
        />
        <Announcements
          type="announcements"
          announcements={announcements}
          title={translate('Announcements')}
          showMore={{
            text: translate('See all announcements'),
            to: '/messages',
            tab: 'ANNOUNCEMENT',
          }}
        />
        <Announcements
          type="events"
          announcements={events}
          title={translate('Events')}
          showMore={{
            text: translate('See all events'),
            to: '/messages',
            tab: 'EVENT',
          }}
          style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
        />
        <Announcements
          type="polls"
          announcements={polls}
          title={translate('Polls')}
          showMore={{
            text: translate('See all polls'),
            to: '/messages',
            tab: 'POLL',
          }}
          style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
        />
        {/* TODO: fix statistics module with new library*/}
        {/* {statisticsModuleEnabled && (
        <Statistics key={Math.random()} statistics={statistics} />
      )} */}
        <SocialSection
          translate={translate}
          socialMediaLinks={this.props.socialMediaLinks}
        />
      </div>
    );
  }
}

const SocialSection = (props) => {
  let { facebook, twitter, instagram, youtube } = false;

  if (props.socialMediaLinks !== null) {
    facebook = props.socialMediaLinks.facebook;
    twitter = props.socialMediaLinks.twitter;
    instagram = props.socialMediaLinks.instagram;
    youtube = props.socialMediaLinks.youtube;
  }

  if (!facebook && !twitter && !instagram && !youtube) {
    return null;
  }

  return (
    <div className="flex items-center justify-center flex-row w-full py-[24px]">
      <div className="flex flex-col items-center justify-center gap-[16px]">
        <Typography variant="regular_16" className="text-gray-700">
          {translate('yourAuthOnSM')}
        </Typography>
        <div className="flex flex-row gap-[32px]">
          <a rel="noreferrer" target="_blank" href={facebook}>
            <FacebookIcon />
          </a>
          <a rel="noreferrer" target="_blank" href={twitter}>
            <XIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo,
      getHomeData,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    allMessages: state.messagesReducer.messages,
    reports: state.homeReducer.reports,
    myCityReports: state.homeReducer.mycity,
    statistics: state.homeReducer.statistics,
    noCompleted: state.homeReducer.no_completed,
    noPending: state.homeReducer.no_pending,
    noInProgress: state.homeReducer.no_inprogress,
    reportModuleEnabled:
      state.otherReducer.modules.filter((item) => item.name === 'reports')
        .length === 1,
    statisticsModuleEnabled:
      state.otherReducer.modules.filter((item) => item.name === 'statistics')
        .length === 1,
    socialMediaLinks: state.homeReducer.social_nets,
  };
};

export default withTranslation(
  withNOVOHeader(connect(mapStateToProps, mapDispatchToProps)(Home))
);
