import React, { useEffect, useState } from 'react';
import { CheckIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { looseStrCmpr } from '../../services/GNRL';
import { withTranslation } from '../../shared/components/withTranslation';
import { setNewReportNavigation, setSelectedIssue } from './actions';

const NRIssue = ({ translate }) => {
  const dispatch = useDispatch();
  const { issues, selectedIssue, loading } = useSelector((state) => ({
    issues: state.newReportReducer.selectedAuthorityIssues,
    selectedIssue: state.newReportReducer.selectedIssue,
  }));

  const [searchBox, setSearchBox] = useState('');
  const [filteredIssues, setFilteredIssues] = useState(issues);

  useEffect(() => {
    setFilteredIssues(issues);
  }, [issues]);

  const searchBarChanged = (textValue) => {
    const filtered = issues.filter((item) =>
      looseStrCmpr(translate(item.translatedName), textValue)
    );
    setSearchBox(textValue);
    setFilteredIssues(filtered);
  };

  const issueList = (issues) => {
    return issues.map((issue) => {
      const isChecked = issue.name === selectedIssue;
      return (
        <CommandItem
          key={issue.name}
          onSelect={() => {
            console.log('Selecting issue:', issue.name);
            dispatch(setSelectedIssue(issue.name));
          }}
        >
          <div className="flex items-center">
            {isChecked ? (
              <CheckIcon className="mr-2 h-4 w-4" />
            ) : (
              <span className="mr-2 h-4 w-4" />
            )}
            <Typography variant="semibold_14" className="text-gray-700">
              {translate(issue.translatedName)}
            </Typography>
          </div>
        </CommandItem>
      );
    });
  };

  return (
    <div className="flex sm:p-[40px] sm:pt-[32px] sm:h-[640px] h-[calc(100vh-80px)]  sm:pb-0 p-0">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="bold_20" className="text-gray-800">
            {translate('Select Issue')}
          </Typography>
          <Typography
            variant="regular_14"
            className="text-gray-500 lg:hidden block"
          >
            {`${translate('Step')} 2/4`}
          </Typography>
        </div>
        <Command className="rounded-lg border shadow-md h-fit">
          <CommandInput
            placeholder={translate('Select Issue')}
            onChange={(e) => searchBarChanged(e.value)}
          />
          <CommandList className="max-h-full overflow-y-auto">
            <CommandEmpty>{translate('No results found')}</CommandEmpty>
            {issueList(filteredIssues)}
          </CommandList>
        </Command>
        <div className="flex flex-col justify-end h-full sm:h-fit">
          <NovovilleButton
            variant="primary"
            className="lg:w-[140px] py-[16px] w-full"
            onClick={() => dispatch(setNewReportNavigation('ADD_DESCRIPTION'))}
            disabled={!selectedIssue}
            loading={loading}
          >
            {translate('NEXT')}
          </NovovilleButton>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(NRIssue);
