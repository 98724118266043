import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { getLanguage } from '@/services/GNRL';
import { translate } from '@/services/TranslationService';
import WEBAPI from '@/services/WEBAPI';
import { NovovilleButton, Typography } from '@/components/novoville';
import { Card } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import DeleteModal from '../modal/DeleteModal';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';

const Privacy = (props) => {
  const [checked, setChecked] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { setHeaderTitle } = props;
  useEffect(() => {
    setHeaderTitle(translate('DataPrivacy'));
    WEBAPI.getSubscriptions().then((response) => {
      if (response.result.length > 0) {
        setChecked(response.result[0].is_subscribed === 1);
      }
    });
  }, []);

  const changeSubscription = (value) => {
    setChecked(value);

    WEBAPI.patchSubscriptions({
      data: {
        subscription_name: 'email_subscription',
        subscription_value: value ? 1 : 0,
        application: 'novoville',
      },
    }).catch((error) => {
      setChecked(!value);
    });
  };

  const privacyLink = (lang) => {
    if (lang === 'en') {
      return 'https://novoville.com/privacy-policy/';
    }
    return 'https://novoville.com/el/%CF%80%CE%BF%CE%BB%CE%B9%CF%84%CE%B9%CE%BA%CE%AE-%CE%B1%CF%80%CE%BF%CF%81%CF%81%CE%AE%CF%84%CE%BF%CF%85/';
  };

  return (
    <div className="flex flex-wrap w-full h-fit justify-center sm:p-[40px] p-0 sm:bg-transparent bg-white">
      <Card className="w-full min-w-[300px] sm:p-[40px] p-[24px] drop-shadow-none shadow-none text-gray-800 bg-white border-none">
        <div className="max-w-[753px]">
          <Typography variant="regular_16" className="pb-[32px]">
            {translate('AboutPrivacy')}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={privacyLink(getLanguage())}
              className="inline-flex items-center text-blueLink hover:underline"
            >
              {translate('LearnMore')}
            </a>
          </Typography>
          <Typography variant="regular_16">
            {translate('ControlYourSubscriptions')}
          </Typography>
          <div className="flex flex-row items-center gap-[8px] pt-[32px] pb-[12px]">
            <Checkbox
              className="w-[24px] h-[24px] data-[state=checked]:bg-teal-400 data-[state=checked]:text-gray-800"
              checked={checked}
              onCheckedChange={(newChecked) => changeSubscription(newChecked)}
            />
            <Typography variant="semibold_16" className="text-gray-900">
              {translate('subscriptionContact').replace(
                '%%appName%%',
                'Novoville'
              )}
            </Typography>
          </div>
          <Typography variant="regular_14" className="text-gray-500 pb-[32px]">
            {translate('subscribingNewsletterAgreement')}
          </Typography>
          <NovovilleButton
            onClick={() => setIsDeleteModalOpen(true)}
            variant="primary"
            className="w-fit bg-errorRed text-white hover:bg-[#D32F2F]"
          >
            {translate('DeleteAccount')}
          </NovovilleButton>
        </div>
      </Card>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </div>
  );
};

export default withNOVOHeader(withRouter(Privacy));
