import WEBAPI from "../../services/WEBAPI.js";
import * as ATypes from "../../shared/actionTypes";
import { login } from "../login/actions"
import { changeAndPostProfile } from "../profile/actions"
import { goToAuthoritySelection, goTo } from "../../shared/actions"
import { get } from "lodash";

import * as AnalyticsActions from "../../services/analyticsActions";

export const register = (email, password, name, surname, application, skipRegistration, captchaKey, captchaValueParam) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.POST_REGISTER_REQ,
      info: "with skip registration" + !!skipRegistration //just info for printing, trying this out
    });

    const payload = {
      email,
      password,
      name,
      surname,
      application,
      skipRegistration
    }

    if (captchaKey && captchaValueParam) {
      payload.captchaKey = captchaKey;
      payload.captchaValueParam = captchaValueParam;
    }

    return WEBAPI.postRegister(email, password, name, surname, application, skipRegistration, captchaKey && captchaKey, captchaValueParam && captchaValueParam)
      .then(res => {

        AnalyticsActions.registration({
          type: "email", //its always email because the social registration events are handled as a loggin
          email,
          firstName: name,
          lastName: surname,
          isUserUnauth: skipRegistration,
          application,
        })

        dispatch({
          type: ATypes.POST_REGISTER_RES,
          payload: {
            user_sso_id: res.result.user_sso_id
          }
        });
      })
      .catch(err => {
        AnalyticsActions.registration({
          type: "email", //its always email because the social registration events are handled as a loggin
          email,
          firstName: name,
          lastName: surname,
          isUserUnauth: skipRegistration,
          application,
          error: get(err, "details.user_description", "An error ocurred"),
        })
        throw err;
      })
  };
};

export const postAccount = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.POST_ACCOUNT_REQ
    });
    return WEBAPI.postAccount()
      .then(res => {
        dispatch({
          type: ATypes.POST_ACCOUNT_RES,
          payload: {
            user_uuid: res.result.uuid
          }
        });
        return res;
      })
  };
};

//COMBINED ACTIONS
//this is only used by the webapp
export const registerProcessAndRoute = (email, password, name, surname, captchaKey, captchaValueParam) => {

  return (dispatch, getState) => {
    return dispatch(registerProcess(email, password, name, surname, undefined, undefined, undefined, captchaKey, captchaValueParam)).then(() => {
      let setAuthorityBecauseOfURLParam = get(getState(), "otherReducer.params.setAuthority");
      let setCountryBecauseOfURLParam = get(getState(), "otherReducer.params.setCountry");
      let goToPageBecauseOfUrlParam = get(getState(), "otherReducer.params.goToPage")

      if (
        setAuthorityBecauseOfURLParam
        &&
        setCountryBecauseOfURLParam
        &&
        goToPageBecauseOfUrlParam
      ) {
        dispatch({
          type: ATypes.UNSET_ORIGINAL_URL_PARAMS
        })

        //TODO: REMOVE THIS. NEEDS RESTRUCTURING FOR THE FLOW WHERE WE AUTOMATICALLY
        //ADD AN AUTHORITY. THE REASON THIS EXISTS HERE IS BECAUSE WE WANT THE REGISTER EVENT
        //TO HAPPEN BEFORE THE REGISTRATION_SUCCESS
        setTimeout(() => {
          AnalyticsActions.onboardingSuccessEvent({ selectedAuth: setAuthorityBecauseOfURLParam });
        }, 100)
        return dispatch(goTo(goToPageBecauseOfUrlParam))
      } else {
        return dispatch(goToAuthoritySelection())
      }
    })
  }
}

export const registerProcess = (email, password, name, surname, application, skipRegistration, isUserUnauth, captchaKey, captchaValueParam) => {
  return (dispatch, getState) => {
    // DEPENDING ON WETHER WE GOT URL PARAMS OR NOT THE REGISTER PROCESS CHANGES
    let setAuthorityBecauseOfURLParam = get(getState(), "otherReducer.params.setAuthority");
    let setCountryBecauseOfURLParam = get(getState(), "otherReducer.params.setCountry");
    let goToPageBecauseOfUrlParam = get(getState(), "otherReducer.params.goToPage")

    let profileDataToSend = {
      name,
      surname,
      email,
      password,
    }

    if (setAuthorityBecauseOfURLParam && setCountryBecauseOfURLParam) {
      profileDataToSend = { ...profileDataToSend, authority: setAuthorityBecauseOfURLParam, country: setCountryBecauseOfURLParam }
    }

    return dispatch(register(email, password, name, surname, application, skipRegistration, captchaKey, captchaValueParam))
      .then(() => {

        let extraParams = [];
        if (isUserUnauth) {
          extraParams.push(get(getState(), "profileReducer.profile.authority"))
          extraParams.push(get(getState(), "profileReducer.profile.country"))
        }

        return dispatch(login(email, password, "email", application, isUserUnauth, ...extraParams))
      })
      .then(() => {
        if (!skipRegistration) {
          // dispatch(verifyRequest(email, application, captchaKey, captchaValueParam))
        }
        let accountPromise = dispatch(postAccount())

      })
      .then(() => dispatch(changeAndPostProfile(profileDataToSend)))
  }
}

export const setAuthTokenFromOldApp = (token, user_sso_id, login_type) => {

  return (dispatch, getState) => {
    dispatch({
      type: ATypes.SET_AUTH_TOKEN_FROM_OLD_APP,
      payload: {
        token,
        user_sso_id,
        login_type: !login_type ? "email" : login_type,
      }
    })
  }
}