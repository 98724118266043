import React from "react";

const MyReportsIcon = () => (
  <svg width="114" height="125" viewBox="0 0 114 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_702_16985)">
      <path d="M101.344 6H12.6562C9.80853 6 7.5 8.19964 7.5 10.913V114.087C7.5 116.8 9.80853 119 12.6562 119H101.344C104.191 119 106.5 116.8 106.5 114.087V10.913C106.5 8.19964 104.191 6 101.344 6Z" fill="white" />
    </g>
    <path d="M46.6875 20.7383H19.875C18.1664 20.7383 16.7812 22.0581 16.7812 23.6861C16.7812 25.3141 18.1664 26.6339 19.875 26.6339H46.6875C48.3961 26.6339 49.7812 25.3141 49.7812 23.6861C49.7812 22.0581 48.3961 20.7383 46.6875 20.7383Z" fill="#CBD5E1" />
    <path d="M65.25 33.5117H19.875C18.1664 33.5117 16.7812 34.8315 16.7812 36.4595C16.7812 38.0876 18.1664 39.4074 19.875 39.4074H65.25C66.9586 39.4074 68.3437 38.0876 68.3437 36.4595C68.3437 34.8315 66.9586 33.5117 65.25 33.5117Z" fill="#E2E8F0" />
    <path d="M46.6875 47.2695H19.875C18.1664 47.2695 16.7812 48.5893 16.7812 50.2174C16.7812 51.8454 18.1664 53.1652 19.875 53.1652H46.6875C48.3961 53.1652 49.7812 51.8454 49.7812 50.2174C49.7812 48.5893 48.3961 47.2695 46.6875 47.2695Z" fill="#CBD5E1" />
    <path d="M65.25 60.043H19.875C18.1664 60.043 16.7812 61.3628 16.7812 62.9908C16.7812 64.6188 18.1664 65.9386 19.875 65.9386H65.25C66.9586 65.9386 68.3437 64.6188 68.3437 62.9908C68.3437 61.3628 66.9586 60.043 65.25 60.043Z" fill="#E2E8F0" />
    <path d="M46.6875 73.8008H19.875C18.1664 73.8008 16.7812 75.1206 16.7812 76.7486C16.7812 78.3766 18.1664 79.6964 19.875 79.6964H46.6875C48.3961 79.6964 49.7812 78.3766 49.7812 76.7486C49.7812 75.1206 48.3961 73.8008 46.6875 73.8008Z" fill="#CBD5E1" />
    <path d="M65.25 86.5742H19.875C18.1664 86.5742 16.7812 87.894 16.7812 89.522C16.7812 91.1501 18.1664 92.4699 19.875 92.4699H65.25C66.9586 92.4699 68.3437 91.1501 68.3437 89.522C68.3437 87.894 66.9586 86.5742 65.25 86.5742Z" fill="#E2E8F0" />
    <defs>
      <filter id="filter0_d_702_16985" x="1.5" y="0" width="111" height="125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_702_16985" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_702_16985" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default MyReportsIcon
