import * as ATypes from "../../shared/actionTypes";

export const addPhoneReducer = (state, action) => {

    if (!state) {
        state = {

        }
    }

    switch (action.type) {
        case ATypes.NOTHING:
            return {
                
            };
        default:
            return state;
    }
};