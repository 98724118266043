import React from 'react';
import '../../output.css';

const typographyVariants = {
  regular_12: 'text-xs font-normal leading-none',
  regular_14: 'text-sm font-normal leading-tight',
  regular_16: 'text-base font-normal leading-snug',
  semibold_14: 'text-sm font-semibold leading-tight',
  semibold_16: 'text-base font-semibold leading-normal',
  semibold_18: 'text-[18px] font-semibold leading-normal',
  semibold_16_underlined: 'text-base font-semibold underline leading-normal',
  semibold_24: 'text-2xl font-semibold leading-[33.60px]',
  bold_16: 'text-base font-bold leading-snug',
  bold_20: 'text-xl font-bold leading-[33.60px]',
  bold_24: 'text-2xl font-bold leading-[33.60px]',
  bold_32: 'text-[32px] font-bold leading-[44.80px]',
  italic_14: 'text-sm font-normal italic leading-snug',
};

const Typography = ({
  variant,
  children,
  className = '',
  error = false,
  ...props
}) => {
  const variantClass = typographyVariants[variant] || '';
  const defaultColorClass = error ? 'text-errorRed' : 'text-gray-700';
  const colorClass = className.includes('text-') ? '' : defaultColorClass;
  return (
    <div
      className={`font-[Open_Sans] ${colorClass} ${variantClass} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Typography;
