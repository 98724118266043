import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { bbox, envelope, lineString } from '@turf/turf';
import { SlidersHorizontal, XIcon } from 'lucide-react';
import { connect } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer';
import { translate } from '../../services/TranslationService';
import { goTo } from '../../shared/actions';
import NOVOLoader from '../../shared/components/NOVOLoader';
import { withTranslation } from '../../shared/components/withTranslation';
import { getCoordinatesForAuthority } from '../newReport/actions';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import FiltersComponent from './FiltersComponent';
import {
  getFeatureCollection,
  getFeatureCollectionMarkers,
} from './MyCityHelper';
import MapBoxContainer from './MyCityMapContainer';
import {
  getPOISForAuthority,
  getReportsForAuthority,
  setFilters,
  subscribeToPoi,
  unsubscribeFromPoi,
} from './actions';

const MIN_ZOOM = 14;
const MAX_ZOOM = 17;

class MyCity extends Component {
  constructor() {
    super();
    this.state = {
      center: [],
      filters: {},
      markerHtmlArray: {},
      userLocation: {},
      zoom: [MIN_ZOOM],
      selectedPoint: {},
      reportsAndPois: [],
      markersLoaded: false,
      showInfoWindow: false,
      featureCollectionPoints: [],
      results: 0,
    };
  }

  setResults(results) {
    this.setState({ results });
  }

  componentDidMount() {
    this.props.setHeaderTitle(translate('sideMenu.MyCity'));
    const {
      getReportsForAuthority,
      getPOISForAuthority,
      adoptATreeModuleEnabled,
    } = this.props;
    const fitBounds = this.getFitBounds();
    let requests = [
      getPOISForAuthority({ includeTrees: adoptATreeModuleEnabled }),
      getReportsForAuthority(),
      getCoordinatesForAuthority(),
    ];
    Promise.all(requests).then((respondArray) => {
      let res = respondArray[2];
      const { reportsAndPois, markerHtmlArray, featureCollectionPoints } =
        this.getCollectionsAndMarkers();
      this.setState({
        markerHtmlArray: markerHtmlArray,
        markersLoaded: true,
        reportsAndPois: reportsAndPois,
        center: [res.longitude, res.latitude],
        featureCollectionPoints: featureCollectionPoints,
        userLocation: { lat: res.latitude, lng: res.longitude },
        fitBounds,
        results: featureCollectionPoints.length || reportsAndPois.length,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.pois &&
        this.props.pois &&
        prevProps.pois.length !== this.props.pois.length) ||
      (!prevProps.pois && this.props.pois)
    ) {
      const { reportsAndPois, markerHtmlArray, featureCollectionPoints } =
        this.getCollectionsAndMarkers();
      this.setState({
        markerHtmlArray: markerHtmlArray,
        reportsAndPois: reportsAndPois,
        featureCollectionPoints: featureCollectionPoints,
        results: featureCollectionPoints.length,
      });
    }
  }

  getCollectionsAndMarkers() {
    let featureCollectionPoints = [];
    const activeFilters = this.props.activeFilters;
    const reportsAndPois =
      this.props.reports && this.props.reports.length > 0
        ? getFeatureCollection(this.props.reports.concat(this.props.pois))
        : getFeatureCollection(this.props.pois);
    const markerHtmlArray = getFeatureCollectionMarkers(reportsAndPois);

    reportsAndPois.forEach((point) => {
      if (!point) return;
      if (
        activeFilters.filter(
          (activeFilter) => activeFilter.name === point.properties.type
        ).length > 0
      ) {
        featureCollectionPoints.push(point);
      }
    });

    return {
      reportsAndPois: reportsAndPois,
      markerHtmlArray: markerHtmlArray,
      featureCollectionPoints: featureCollectionPoints,
    };
  }

  getFitBounds = () => {
    const { placemarks } = this.props;
    if (placemarks?.features?.length > 0) {
      const enveloped = envelope(placemarks);
      const line = lineString(enveloped.geometry.coordinates[0]);
      return bbox(line);
    }
    return null;
  };

  openInfoWindow(e) {
    const coordinates = e.features[0].geometry.coordinates.slice();
    this.setState({
      center: coordinates,
      showInfoWindow: true,
      selectedPoint: JSON.parse(e.features[0].properties.point),
    });
  }

  closeInfoWindow() {
    this.setState({ showInfoWindow: false });
  }

  onClickCluster(e) {
    const { lng, lat } = e.lngLat;
    this.setState({ center: [lng, lat] });
  }

  zoomControlClick() {
    this.setState({
      showInfoWindow: false,
      center: [
        parseFloat(this.state.selectedPoint.longitude),
        parseFloat(this.state.selectedPoint.latitude),
      ],
    });
  }

  filtersChanged(selectedPointType) {
    let featureCollectionPoints = [];
    const activeFilters = this.props.activeFilters;
    const indexFilterExist = activeFilters.findIndex(
      (filter) => filter.name === selectedPointType
    );
    if (indexFilterExist === -1)
      activeFilters.push({ name: selectedPointType });
    else activeFilters.splice(indexFilterExist, 1);

    this.state.reportsAndPois.forEach((point) => {
      if (!point) return;
      if (
        activeFilters.filter(
          (activeFilter) => activeFilter.name === point.properties.type
        ).length > 0
      ) {
        featureCollectionPoints.push(point);
      }
    });

    this.props.setFilters(activeFilters);

    this.setState({
      featureCollectionPoints: featureCollectionPoints,
      showInfoWindow: false,
      results: featureCollectionPoints.length,
    });
  }

  clearFilters() {
    this.props.setFilters([]);
    this.setState({
      featureCollectionPoints: this.state.reportsAndPois,
      showInfoWindow: false,
      results: this.state.reportsAndPois.length,
    });
  }

  render() {
    const {
      activeFilters,
      goTo,
      subscribeToPoi,
      unsubscribeFromPoi,
      pois,
      reports,
    } = this.props;

    let featureCollection = {
      type: 'FeatureCollection',
      features: this.state.featureCollectionPoints,
    };
    const isMobile = () => window?.innerWidth < 768;
    const getScreenHeight = () => window?.innerHeight - 80;

    return (
      <>
        {isMobile() ? (
          <div className="flex sm:p-[40px] sm:pt-[32px] sm:h-fit max-h-[calc(100vh-80px)] sm:pb-0 p-0">
            <div className="flex flex-col bg-white w-full h-[calc(100vh-80px)]">
              {this.state.markersLoaded ? (
                <>
                  <Drawer className="z-[100]">
                    <DrawerTrigger>
                      <NovovilleButton
                        variant="primary"
                        className="absolute bottom-[40px] right-[50%] translate-x-[50%] z-[10] w-fit"
                      >
                        <SlidersHorizontal className="w-[20px] h-[20px] text-gray-500" />
                        <Typography variant="semibold_16">
                          {translate('Filters')}
                        </Typography>
                        {activeFilters.length > 0 && (
                          <div className="bg-gray-800 rounded-full w-[16px] h-[16px] flex items-center justify-center">
                            <span className="text-[10px] font-medium text-white">
                              {activeFilters.length}
                            </span>
                          </div>
                        )}
                      </NovovilleButton>
                    </DrawerTrigger>
                    <DrawerContent
                      className="flex flex-col max-h-[calc(100vh-80px)]"
                      showHandle={false}
                    >
                      <DrawerHeader className="border-b border-gray-200">
                        <DrawerTitle className="flex items-center justify-between">
                          <Typography
                            variant="semibold_16"
                            className="flex-grow text-center"
                          >
                            {translate('Filters')}
                          </Typography>
                          <DrawerClose>
                            <XIcon className="w-[20px] h-[20px] cursor-pointer text-[#000] ml-auto" />
                          </DrawerClose>
                        </DrawerTitle>
                      </DrawerHeader>
                      <DrawerDescription className="flex-grow overflow-auto">
                        <FiltersComponent
                          setResults={this.setResults.bind(this)}
                          isMobile={isMobile}
                          reports={reports}
                          pois={pois}
                          activeFilters={activeFilters}
                          filtersChanged={this.filtersChanged.bind(this)}
                          clearFilters={this.clearFilters.bind(this)}
                          results={this.state.results}
                        />
                      </DrawerDescription>
                      <DrawerFooter className="flex flex-row justify-between border-t border-gray-200">
                        {activeFilters.length > 0 && (
                          <NovovilleButton
                            variant="secondary"
                            className="w-1/2"
                            onClick={this.clearFilters.bind(this)}
                          >
                            {translate('ClearFilters')}
                          </NovovilleButton>
                        )}
                        <DrawerClose
                          className={` ${
                            activeFilters.length > 0 ? 'w-1/2' : 'w-full'
                          }`}
                        >
                          <NovovilleButton variant="primary" className="w-full">
                            {`${this.state.results} ${translate('Results')}`}
                          </NovovilleButton>
                        </DrawerClose>
                      </DrawerFooter>
                    </DrawerContent>
                  </Drawer>
                  <MapBoxContainer
                    isMobile={isMobile}
                    getScreenHeight={getScreenHeight}
                    goTo={goTo}
                    isFromCity={true}
                    zoom={this.state.zoom}
                    center={this.state.center}
                    subscribeToPoi={subscribeToPoi}
                    markerHtmlArray={this.state.markerHtmlArray}
                    userLocation={this.state.userLocation}
                    featureCollection={featureCollection}
                    unsubscribeFromPoi={unsubscribeFromPoi}
                    selectedPoint={this.state.selectedPoint}
                    showInfoWindow={this.state.showInfoWindow}
                    onClickCluster={this.onClickCluster.bind(this)}
                    openInfoWindow={this.openInfoWindow.bind(this)}
                    closeInfoWindow={this.closeInfoWindow.bind(this)}
                    zoomControlClick={this.zoomControlClick.bind(this)}
                    placemarks={this.props.placemarks}
                    fitBounds={this.state.fitBounds}
                  />
                </>
              ) : (
                <NOVOLoader
                  style={{
                    position: ' absolute',
                    top: '50%',
                    left: '50%',
                    transform: translate('50%', '50%'),
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="flex sm:p-[40px] sm:pt-[32px] sm:h-fit max-h-[calc(100vh-80px)] sm:pb-0 p-0">
            <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px] overflow-auto">
              <div
                style={{
                  minHeight: 450,
                  position: 'relative',
                  background: '#eee',
                }}
              >
                {this.state.markersLoaded ? (
                  <MapBoxContainer
                    isMobile={isMobile}
                    getScreenHeight={getScreenHeight}
                    goTo={goTo}
                    isFromCity={true}
                    zoom={this.state.zoom}
                    center={this.state.center}
                    subscribeToPoi={subscribeToPoi}
                    markerHtmlArray={this.state.markerHtmlArray}
                    userLocation={this.state.userLocation}
                    featureCollection={featureCollection}
                    unsubscribeFromPoi={unsubscribeFromPoi}
                    selectedPoint={this.state.selectedPoint}
                    showInfoWindow={this.state.showInfoWindow}
                    onClickCluster={this.onClickCluster.bind(this)}
                    openInfoWindow={this.openInfoWindow.bind(this)}
                    closeInfoWindow={this.closeInfoWindow.bind(this)}
                    zoomControlClick={this.zoomControlClick.bind(this)}
                    placemarks={this.props.placemarks}
                    fitBounds={this.state.fitBounds}
                  />
                ) : (
                  <NOVOLoader
                    style={{
                      position: ' absolute',
                      top: '50%',
                      left: '50%',
                      transform: translate('50%', '50%'),
                    }}
                  />
                )}
              </div>
              <FiltersComponent
                reports={reports}
                pois={pois}
                activeFilters={activeFilters}
                filtersChanged={this.filtersChanged.bind(this)}
                clearFilters={this.clearFilters.bind(this)}
                results={this.state.results}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

var mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getReportsForAuthority,
      getPOISForAuthority,
      setFilters,
      goTo,
      subscribeToPoi,
      unsubscribeFromPoi,
    },
    dispatch
  );

var mapStateToProps = (state) => {
  let enabledModules = state.otherReducer.modules.reduce(
    (accum, item, index) => {
      if (item.name === 'pois') {
        accum['pois'] = true;
      }

      if (item.name === 'reports') {
        accum['reports'] = true;
      }
      if (item.name === 'adopt_a_tree') {
        accum['adopt_a_tree'] = true;
      }
      return accum;
    },
    { reports: false, pois: false, adopt_a_tree: false }
  );

  return {
    reports: enabledModules.reports ? state.myCityReducer.reports : [],
    pois: enabledModules.pois ? state.myCityReducer.pois : [],
    activeFilters: state.myCityReducer.activeFilters
      ? state.myCityReducer.activeFilters
      : [],
    adoptATreeModuleEnabled: enabledModules['adopt_a_tree'],
    placemarks: state.myCityReducer.placemarks,
  };
};

export default withNOVOHeader(
  withTranslation(connect(mapStateToProps, mapDispatchToProps)(MyCity))
);
