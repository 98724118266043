const isNovoville = () => {
  return true
}


const isPireApp = () => {
  if (window.location.href.includes("pireapp")) { return true }
  return false
}


const getApplicationName = () => {
  return "web";
}

//Some applications are authority specific
//And the authority needs to be fixed 
//If null, the application is not authority specific
const getAuthorityForCurrentApplication = () => {
  let appName = getApplicationName()
  if (appName === appNames.PIREAPP) {
    return "pireas"
  }
  return null;

}

const getPrivacyPolicyForCurrentApplication = ({ lang } = {}) => {
  let appName = getApplicationName()

  if (appName === appNames.NOVOVILLE) {
    return `https://novoville.com/${lang}/privacy-policy`
  }
  if (appName === appNames.PIREAPP) {
    return `https://novoville.com/${lang}/privacy-policy-pireapp`
  }
}

const getTermsAndConditionsForCurrentApplication = ({ lang } = {}) => {
  let appName = getApplicationName()
  if (appName === appNames.NOVOVILLE) {
    return `https://novoville.com/${lang}/terms-and-conditions`
  }
  if (appName === appNames.PIREAPP) {
    return `https://novoville.com/${lang}/terms-and-conditions-pireapp`
  }
}


const getVersion = process.env.REACT_APP_VERSION

const getEnviroment = () => {
  return process.env.ENVIRONMENT
}

const isPreview = () => {
  return process.env.ENVIRONMENT === "preview";
}

const isProduction = () => {
  return process.env.ENVIRONMENT === "production";
}

const getBrowserName = () => {
  let userAgentString = navigator.userAgent;
  if (userAgentString.indexOf("Chrome") > -1 && (!(userAgentString.indexOf("OP") > -1))) return "chrome"
  if (userAgentString.indexOf("Firefox") > -1) return "firefox"
  if (userAgentString.indexOf("Safari") > -1 && (!(userAgentString.indexOf("OP") > -1))) return "safari"
  if (userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1) return "internet_Explorer"
  if (userAgentString.indexOf("OP") > -1) return "opera";
  return "notKnown"
}


const appNames = {
  NOVOVILLE: "novoville",
  PIREAPP: "pireapp",
}


const getDefaultCreds = () => {
  let defaultCreds = {
    email: "",
    password: "",
  }

  if (!isProduction()) {
    defaultCreds.email = process.env.DEFAULT_TEST_USER_EMAIL || ""
    defaultCreds.password = process.env.DEFAULT_TEST_USER_PASSWORD || ""
  }
  return defaultCreds;
}


export {
  isNovoville,
  isPireApp,
  getApplicationName,
  getAuthorityForCurrentApplication,
  getPrivacyPolicyForCurrentApplication,
  getTermsAndConditionsForCurrentApplication,
  getVersion,
  getEnviroment,
  isProduction,
  appNames,
  isPreview,
  getDefaultCreds,
  getBrowserName
};