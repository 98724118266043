import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Search } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { translate } from '../../services/TranslationService';
import WEBAPI from '../../services/WEBAPI';
import NRMapComponent from './NRMapComponent';
import {
  getCoordinatesForAuthority,
  locationAcceptProcess,
  setNewCenterAndAddress,
} from './actions';

const NRLocation = () => {
  const dispatch = useDispatch();
  const { selectedAuthority, currentCenter, address } = useSelector(
    (state) => ({
      selectedAuthority: state.newReportReducer.selectedAuthority,
      currentCenter: {
        lat: state.newReportReducer.lat,
        lng: state.newReportReducer.lng,
      },
      address: state.newReportReducer.address,
    })
  );

  const [loading, setLoading] = useState(false);
  const [addressInput, setAddressInput] = useState(address);
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    const setCoordinatesFromCurrentLocation = (position) => {
      if (position) {
        dispatch(
          setNewCenterAndAddress({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );
      }
    };

    const setCoordinatesfromAuthority = () => {
      getCoordinatesForAuthority()
        .then((res) => {
          dispatch(
            setNewCenterAndAddress({ lat: res.latitude, lng: res.longitude })
          );
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        setCoordinatesFromCurrentLocation,
        setCoordinatesfromAuthority
      );
    } else {
      setCoordinatesfromAuthority();
    }
  }, [dispatch]);

  const onChange = (value) => {
    setAddressInput(value.substring(0, 190));
    getPredictions(value);
  };

  const getPredictions = (input) => {
    if (!input) {
      setPredictions([]);
      return;
    }

    const displaySuggestions = (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        predictions = [];
      }
      setPredictions(predictions);
    };

    const service = new window.google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input }, displaySuggestions);
  };

  const createPredictionsDropdown = () => {
    return (
      <Command className="w-full rounded-lg bg-background md:w-[440px]">
        <div className="relative">
          <Search className="absolute left-2.5 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder={translate('Search for an address')}
            value={addressInput}
            onChange={(e) => onChange(e.target.value)}
            className="w-full rounded-lg bg-background pl-8 md:w-[440px]"
          />
        </div>
        <CommandList>
          <CommandGroup>
            {predictions.map((prediction) => (
              <CommandItem
                key={prediction.id}
                onSelect={() => {
                  WEBAPI.getLocationForPlaceID(prediction.place_id).then(
                    (res) => {
                      const loc = res.geometry.location;
                      setPredictions([]);
                      setAddressInput(prediction.description);
                      dispatch(
                        setNewCenterAndAddress(
                          { lat: loc.lat(), lng: loc.lng() },
                          prediction.description
                        )
                      );
                    }
                  );
                }}
                className="cursor-pointer hover:bg-gray-100"
              >
                <span>{prediction.description}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </Command>
    );
  };

  return (
    <div className="flex sm:p-[40px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 sm:overflow-auto overflow-y-scroll">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="bold_20" className="text-gray-800">
            {translate('SelectLocationSU')}
          </Typography>
          <Typography
            variant="regular_14"
            className="text-gray-500 lg:hidden block"
          >
            {`${translate('Step')} 1/4`}
          </Typography>
        </div>
        <div className="flex flex-col gap-4">{createPredictionsDropdown()}</div>
        <NRMapComponent
          googleMapURL={
            'https://maps.googleapis.com/maps/api/js?key=' +
            process.env.REACT_APP_GOOGLE_MAP_TOKEN +
            '&v=3.exp&libraries=geometry,drawing,places'
          }
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className="flex-grow map-target rel" />}
          mapElement={
            <div
              style={{
                height: `100%`,
                width: `100%`,
                position: `absolute`,
                maxHeight: `100%`,
              }}
            />
          }
          center={currentCenter}
          zoom={17}
          onIdle={(newCenter, newAddress) => {
            if (
              newCenter.lat !== currentCenter.lat ||
              newCenter.lng !== currentCenter.lng ||
              newAddress !== address
            ) {
              dispatch(setNewCenterAndAddress(newCenter, newAddress));
              setAddressInput(newAddress);
            }
          }}
        />
        <div className="flex flex-col justify-end pt-[16px]">
          <NovovilleButton
            variant="primary"
            className="lg:w-[140px] py-[16px] w-full"
            loading={loading}
            onClick={() => {
              setLoading(true);
              dispatch(locationAcceptProcess(selectedAuthority))
                .then(() => {
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  let errDescription = get(
                    err,
                    'details.user_description',
                    'Error'
                  );
                  toast({
                    title: translate('OTHER.errorOcurred'),
                    description: errDescription,
                    variant: 'destructive',
                  });
                });
            }}
          >
            {translate('NEXT')}
          </NovovilleButton>
        </div>
      </div>
    </div>
  );
};

export default NRLocation;
