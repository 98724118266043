import React, { Component } from "react";
import tut1 from "../../resources/tut1novoville.png";
import tut2 from "../../resources/tut2novoville.png";
import tut3 from "../../resources/tut3novoville.png";
import { translate } from "../../services/TranslationService";
import { goTo } from "../../shared/actions"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "../../shared/components/Button"

class TutorialPageContainer extends Component {
    constructor(props) {
        super();
        this.props = props;
        this.changePage = this.changePage.bind(this);
        this.state = {
            pageNumber: 1
        }
    }

    changePage() {
        if (this.state.pageNumber === 3) {
            this.props.goTo("register");
            return;
        }
        this.setState({ pageNumber: this.state.pageNumber + 1 })
    }

    render() {
        var tutorialPageNumber = this.state.pageNumber;

        //TOASK, SHOULD THIS BE IN HERE OR SHOULD THEY JUST PASS IN AS PROPS?
        //OR SHOULD I MAKE A WRAPPER COMPONENT TO BEGIN WITH? OR IT DOESNT MATTER AS ITS A SMALL ONE?
        let title;
        let description;
        var tutorialImage = "";
        if (tutorialPageNumber == 1) {
            title = translate("tutorialTitle1");
            description = translate("tutorialBody1");
            tutorialImage = tut1;
        } else if (tutorialPageNumber == 2) {
            title = translate("tutorialTitle2");
            description = translate("tutorialBody2");
            tutorialImage = tut2;
        } else if (tutorialPageNumber == 3) {
            title = translate("tutorialTitle3");
            description = translate("tutorialBody3");
            tutorialImage = tut3;
        }

        return <TutorialPage title={title} description={description} onClick={this.changePage} tutorialImage={tutorialImage} />;
    }
}

var mapDispatchToProps = dispatch => {
    return bindActionCreators({
        goTo
    }, dispatch);
}

export class TutorialPage extends Component {
    render() {

        let { title, description, tutorialImage, onClick } = this.props;

        return (
            <div id="frst" style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexGrow: 1,
            }}>
                <div id="kek" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
                    <img style={{ maxWidth: "500px", width: "80%" }} src={tutorialImage} />
                    <h3 style={{ margin: 15 }}>{title}</h3>
                    <div className="text-grey" style={{ maxWidth: 500, margin: 15, textAlign: "center" }}>{description}</div>
                </div>
                {/* <div style={{ marginTop: 20, marginBottom: 20, width: "80%", margin: "0 auto" }}> */}
                <Button buttonText={translate("next")} onClick={onClick} />
                <div style={{ height: 20 }} />
                {/* </div> */}
            </div>
        )
    }
}
export default connect(undefined, mapDispatchToProps)(TutorialPageContainer);
