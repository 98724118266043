import { Typography } from '@/components/novoville';
import React from 'react';
import { connect } from 'react-redux';

const SidemenuItem = ({ onClick, name, route, icon, currentLocation }) => {
  const isSelected = route === currentLocation;

  return (
    <div
      className={`${isSelected ? 'text-black' : 'text-gray-600'} flex items-center h-[56px] gap-[12px] sm:p-[24px] p-[12px] cursor-pointer`}
      onClick={onClick}
    >
      <div className={`px-[12px] py-[6px] rounded-[8px] flex items-center h-[44px] w-full ${isSelected && 'bg-gray-100'}`}>
        {icon}
        <Typography variant='semibold_16' className={`${isSelected ? 'text-black' : 'text-gray-600'}`}>
          {name}
        </Typography>
      </div>
    </div>
  );
};

var mapStateToProps = (state) => ({
  currentLocation: state.router.location.pathname.replace('/', ''),
});

export default connect(mapStateToProps, null)(SidemenuItem);
