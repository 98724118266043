import React, { useCallback, useEffect, useState } from 'react';
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { useDispatch } from 'react-redux';
import { getLanguage } from '@/services/GNRL';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';
import { toast } from '@/components/ui/use-toast';
import '../../output.css';
import novoville_logo from '../../resources/novoville_logo.svg';
import { translate } from '../../services/TranslationService';
import { verifyRequest } from '../../shared/actions';
import AuthHeader from '../AuthHeader/AuthHeader';
import { verifyAndAddToProfile } from './actions';

const timeToWaitInSeconds = 60;

const AddPhoneVerify = (props) => {
  const dispatch = useDispatch();
  const { mobile, cameFrom, phoneVerified } = props;
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [timeRemainingTillAllowResend, setTimeRemainingTillAllowResend] =
    useState(1000 * timeToWaitInSeconds);

  const onResendPressed = useCallback(() => {
    if (timeRemainingTillAllowResend > 0) {
      return;
    }
    dispatch(verifyRequest(props.mobile));

    setTimeRemainingTillAllowResend(1000 * timeToWaitInSeconds * 2);
  }, [props, timeRemainingTillAllowResend]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemainingTillAllowResend((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [, setLanguage] = useState(getLanguage());

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const onChange = useCallback((field, value) => {
    if (field === 'pin') {
      setPin(value);
    }
  }, []);

  const onClick = () => {
    setLoading(true);
    dispatch(verifyAndAddToProfile(mobile, pin, cameFrom, phoneVerified))
      .then(() => {
        toast({
          title: translate('OTHER.success'),
          description: translate('mobile_verified'),
          variant: 'success',
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          variant: 'destructive',
          title: translate('OTHER.errorOcurred'),
          description: err?.details?.user_description,
        });
      });
  };

  return (
    <div className="flex flex-wrap bg-white">
      <div className="h-[400vh] w-screen min-h-[100vh] bg-white overflow-hidden fixed" />
      <AuthHeader
        logo={novoville_logo}
        handleLanguageChange={handleLanguageChange}
      />
      <div className="min-h-[calc(100vh-100px)] w-screen self-center flex flex-col justify-center sm:py-[74px] sm:px-8">
        <Card className="self-center sm:w-full max-w-[468px] drop-shadow-none shadow-none text-gray-800 px-[24px] border-none">
          <section key={'profile'} className="flex flex-column flex-grow">
            <div className="flex flex-column  flex-grow flex-top-bottom-margin">
              <div>
                <CardHeader className="text-center p-0 pb-[32px]">
                  <CardTitle>
                    <Typography variant="semibold_20">
                      {translate('mobile_verification_code')}
                    </Typography>
                  </CardTitle>
                  <CardDescription className="mt-[4px]">
                    <Typography variant="regular_16">
                      {translate('insert4digitpintoverify')}
                    </Typography>
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-0 flex flex-col items-center">
                  <div className="pb-[32px] gap-[32px] flex flex-col items-center  w-full">
                    <InputOTP
                      maxLength={4}
                      pattern={REGEXP_ONLY_DIGITS}
                      onChange={(e) => onChange('pin', e)}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                      </InputOTPGroup>
                    </InputOTP>
                    <NovovilleButton
                      className="drop-shadow-md"
                      variant="primary"
                      disabled={loading}
                      onClick={onClick}
                    >
                      {translate('verify_mobile_number')}
                    </NovovilleButton>
                  </div>
                </CardContent>
              </div>
              <div style={{ textAlign: 'center' }} onClick={onResendPressed}>
                {translate('pinNotReceived')}
                {formatTime(timeRemainingTillAllowResend)}
              </div>
            </div>
          </section>
        </Card>
      </div>
    </div>
  );
};

const formatTime = (milliseconds) => {
  if (milliseconds <= 0) {
    return '';
  }
  return '  ' + milliseconds / 1000;
};

export default AddPhoneVerify;
