import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { FileText } from 'lucide-react';
import { connect } from 'react-redux';
import { Typography } from '@/components/novoville';
import { translate } from '../../services/TranslationService';
import { goTo } from '../../shared/actions';
import VerifyModal from '../modal/VerifyModal';

const NewReportSection = ({
  hasVerifiedEmail,
  hasVerifiedPhoneNumber,
  goTo,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    if (hasVerifiedEmail && hasVerifiedPhoneNumber) {
      goTo('newReport');
    }
  };

  return (
    <div className="flex">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
        <div className="flex sm:flex-row md:flex-row flex-col items-center justify-between">
          <Typography variant="bold_20" className="text-center text-gray-900">
            {translate('Simplifying City Living')}
          </Typography>
        </div>
        <div className="flex flex-col rounded-[16px] bg-gray-50 items-center justify-center p-[24px]">
          <div className="bg-strongCyan rounded-full w-[40px] h-[40px] flex items-center justify-center">
            <FileText className="w-[24px] h-[24px] text-white" />
          </div>
          <Typography variant="bold_24" className="pb-[4px] pt-[16px]">
            {translate('Reports')}
          </Typography>
          <Typography
            variant="regular_16"
            className="pb-[24px] text-center text-gray-700"
          >
            {translate("Let's take proactive steps")}
          </Typography>
          <VerifyModal
            onClick={handleClick}
            onOpenChange={setIsModalOpen}
            shouldOpen={isModalOpen}
          >
            {translate('newReportLower')}
          </VerifyModal>
        </div>
      </div>
    </div>
  );
};

var mapStateToProps = (state) => {
  return {
    solved: state.homeReducer.no_completed,
    pending: state.homeReducer.no_pending,
    hasVerifiedEmail: state.profileReducer.hasVerifiedEmail,
    hasVerifiedPhoneNumber: state.profileReducer.hasVerifiedPhoneNumber,
  };
};

var mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      goTo,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewReportSection);
