import store from '@/services/store';
import translations_FRENCH from '../resources/translations/FR';
import translations_ENGLISH from '../resources/translations/GB';
import translations_GREEK from '../resources/translations/GR';
import translations_DUTCH from '../resources/translations/NL';
import { getTranslatedLanguageWithUserSettings } from './GNRL';

//TODO: remove this attrocity when you get access to the translations client
translations_GREEK['no_items'] = 'Δέν υπάρχουν δεδομένα';
translations_ENGLISH['no_items'] = 'No data';

//TOASK: What if i defined translations here as a const. Wouldnt that be an immutable reference to the translations slice
//and therefore always updated or does getState return a clone?

export function translateStatic(value) {
  let lang = getTranslatedLanguageWithUserSettings();

  let retValue;
  if (lang === 'en') {
    retValue = translations_ENGLISH[value];
  } else if (lang === 'nl') {
    retValue = translations_DUTCH[value];
  } else if (lang === 'fr') {
    retValue = translations_FRENCH[value];
  } else if (lang === 'gr' || lang === 'el') {
    retValue = translations_GREEK[value];
  } else {
    retValue = translations_ENGLISH[value];
  }

  //default to english
  if (!retValue) {
    return translations_ENGLISH[value];
  }
  return retValue;
}

// String.prototype.translate = translateStatic; THIS ISNT FP BECASE, GET THIS: YOU'RE MUTATING THE ENV OBJECT!! nice way to think about it. also practically it might mess up because globals but you know..
export function translate(value) {
  var translated = translateStatic(value);
  if (!translated) {
    return translateLookupEverything(value);
  }
  return translated
    .replace('%%app%%', getAppTranslation())
    .replace('%%app%%', getAppTranslation());
}
const getAppTranslation = () => 'novoville';

function translateLookupEverything(value) {
  const translations = store.getState().translationsReducer.translations;
  for (var key in translations) {
    if (translations[key][value]) {
      return translations[key][value];
    }
  }
  return value;
}

export function translateCountry(value) {
  const translations = store.getState().translationsReducer.translations;
  return translations.countries[value];
}

export function translateAuthority(value) {
  const translations = store.getState().translationsReducer.translations;
  return translations.authorities[value];
}

export function translateAPICode(value) {
  const translations = store.getState().translationsReducer.translations;
  if (translations) {
    return translations.responses[value];
  }
  return value;
}

export function translateIssue(value) {}

export function translatePOIType(value) {
  const translations = store.getState().translationsReducer.translations;
  return translations.pois[value];
}
