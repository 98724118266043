import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goToAddMobile, goToVerifyEmail } from '@/shared/actions';
import { translate } from '@/services/TranslationService';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from '@/components/ui/dialog';
import UnverifiedIcon from '../../resources/Unverified.svg';
import VerifiedIcon from '../../resources/Verified.svg';

const VerifyModal = ({ onClick, shouldOpen, onOpenChange, children }) => {
  const hasVerifiedEmail = useSelector(
    (state) => state.profileReducer.hasVerifiedEmail
  );
  const hasVerifiedPhoneNumber = useSelector(
    (state) => state.profileReducer.hasVerifiedPhoneNumber
  );

  const handleIcon = (verified) => {
    return verified ? VerifiedIcon : UnverifiedIcon;
  };

  const handleVerificationText = (verified) => {
    return verified
      ? `(${translate('verified')})`
      : `(${translate('needs verification')})`;
  };
  const dispatch = useDispatch();

  return (
    <Dialog
      open={shouldOpen}
      onOpenChange={onOpenChange}
      style={{
        height: 'inherit',
      }}
    >
      <DialogTrigger asChild>
        <NovovilleButton
          onClick={onClick}
          className="w-fit min-w-[170px]"
          variant="primary"
        >
          {children}
        </NovovilleButton>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] flex flex-col">
        <DialogHeader className="text-start">
          <Typography variant="bold_20">{translate('Verification')}</Typography>
          <Typography variant="normal_14">
            {translate('verifyScreen_text')}
          </Typography>
        </DialogHeader>
        <div className="flex flex-col py-[32px] gap-[24px]">
          <div className="flex flex-row">
            <img src={handleIcon(hasVerifiedEmail)} alt="verified_icon" />
            <div className="flex flex-col">
              <Typography variant="semibold_16">Email</Typography>
              <Typography variant="italic_14">
                {handleVerificationText(hasVerifiedEmail)}
              </Typography>
            </div>
          </div>
          <div className="flex flex-row">
            <img
              src={handleIcon(hasVerifiedPhoneNumber)}
              alt="unverified_icon"
            />
            <div className="flex flex-col">
              <Typography variant="semibold_16">
                {translate('phoneNumber')}
              </Typography>
              <Typography variant="italic_14">
                {handleVerificationText(hasVerifiedPhoneNumber)}
              </Typography>
            </div>
          </div>
        </div>
        <DialogFooter>
          <NovovilleButton
            type="submit"
            variant={'primary'}
            onClick={() => {
              !hasVerifiedEmail
                ? dispatch(goToVerifyEmail('home'))
                : dispatch(goToAddMobile('home'));
            }}
          >
            {translate('continue')}
          </NovovilleButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyModal;
