import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { initialProcess } from '@/shared/actions';
import store from '@/services/store';
import App from './App';

var boundInitialProcess = bindActionCreators(
  { initialProcess },
  store.dispatch
).initialProcess;

console.mobileWarn = console.warn();

if (!process.env.REACT_APP_WITH_LOGS) {
  console.info = () => {};
  console.warn = () => {};
}

class AppContainer extends Component {
  constructor() {
    super();
    this.state = {
      initialProcessDone: false,
    };
  }

  componentDidMount() {
    boundInitialProcess()
      .then(() => {
        this?.setState?.({ initialProcessDone: true });
      })
      .catch((err) => {
        console.error('Error during initial process:', err);
        if (err?.details?.error_code === '108') {
          this.setState({ initialProcessDone: true });
        } else {
          this.setState({
            errorDesc: err.details
              ? err.details.user_description
              : 'Unknown error',
          });
        }
      });
  }

  componentDidCatch(err) {
    console.error('An error was caught at the top level component');
    console.log(err);
    throw err;
  }

  render() {
    return this.props.children(this.state);
  }
}

const NovovilleAppContainer = (props) => (
  <AppContainer {...props}>
    {(props2) => {
      return <App {...props2} />;
    }}
  </AppContainer>
);

export { AppContainer };
export default NovovilleAppContainer;
