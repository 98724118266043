import React from 'react'

const PollsIcon = () => (
  <svg width="114" height="125" viewBox="0 0 114 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_702_17063)">
      <path d="M101.344 6H12.6562C9.80853 6 7.5 8.19964 7.5 10.913V114.087C7.5 116.8 9.80853 119 12.6562 119H101.344C104.191 119 106.5 116.8 106.5 114.087V10.913C106.5 8.19964 104.191 6 101.344 6Z" fill="white" />
    </g>
    <path d="M89.291 20.7383H47.3965C44.7267 20.7383 42.5625 22.0581 42.5625 23.6861C42.5625 25.3141 44.7267 26.6339 47.3965 26.6339H89.291C91.9608 26.6339 94.125 25.3141 94.125 23.6861C94.125 22.0581 91.9608 20.7383 89.291 20.7383Z" fill="#E2E8F0" />
    <path d="M91.0312 33.5117H45.6562C43.9476 33.5117 42.5625 34.8315 42.5625 36.4595C42.5625 38.0876 43.9476 39.4074 45.6562 39.4074H91.0312C92.7399 39.4074 94.125 38.0876 94.125 36.4595C94.125 34.8315 92.7399 33.5117 91.0312 33.5117Z" fill="#F1F5F9" />
    <path d="M89.291 47.2695H47.3965C44.7267 47.2695 42.5625 48.5893 42.5625 50.2174C42.5625 51.8454 44.7267 53.1652 47.3965 53.1652H89.291C91.9608 53.1652 94.125 51.8454 94.125 50.2174C94.125 48.5893 91.9608 47.2695 89.291 47.2695Z" fill="#E2E8F0" />
    <path d="M91.0312 60.043H45.6562C43.9476 60.043 42.5625 61.3628 42.5625 62.9908C42.5625 64.6188 43.9476 65.9386 45.6562 65.9386H91.0312C92.7399 65.9386 94.125 64.6188 94.125 62.9908C94.125 61.3628 92.7399 60.043 91.0312 60.043Z" fill="#F1F5F9" />
    <path d="M89.291 73.8008H47.3965C44.7267 73.8008 42.5625 75.1206 42.5625 76.7486C42.5625 78.3766 44.7267 79.6964 47.3965 79.6964H89.291C91.9608 79.6964 94.125 78.3766 94.125 76.7486C94.125 75.1206 91.9608 73.8008 89.291 73.8008Z" fill="#E2E8F0" />
    <path d="M91.0312 86.5742H45.6562C43.9476 86.5742 42.5625 87.894 42.5625 89.522C42.5625 91.1501 43.9476 92.4699 45.6562 92.4699H91.0312C92.7399 92.4699 94.125 91.1501 94.125 89.522C94.125 87.894 92.7399 86.5742 91.0312 86.5742Z" fill="#F1F5F9" />
    <rect x="16.7812" y="22.7031" width="14.4375" height="13.7565" rx="2" fill="#CBD5E1" />
    <rect x="16.7812" y="49.2344" width="14.4375" height="13.7565" rx="2" fill="#CBD5E1" />
    <rect x="16.7812" y="75.7656" width="14.4375" height="13.7565" rx="2" fill="#CBD5E1" />
    <mask id="mask0_702_17063" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="13" y="17" width="26" height="25">
      <rect x="13.6875" y="17.793" width="24.75" height="23.5826" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_702_17063)">
      <path d="M24 33.9084L19.875 29.978L21.3188 28.6023L24 31.1571L30.8063 24.6719L32.25 26.0475L24 33.9084Z" fill="#94A3B8" />
    </g>
    <defs>
      <filter id="filter0_d_702_17063" x="1.5" y="0" width="111" height="125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_702_17063" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_702_17063" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default PollsIcon
