import React from 'react';
import { translate } from '@/services/TranslationService';
import { Badge } from '../ui/badge';
import Typography from './Typography';

const reportStatus = {
  pending: 'pending',
  inProgress: 'in_progress',
  completed: 'completed',
};

const StatusBadge = ({ status, className }) => {
  if (status === reportStatus.pending) {
    return (
      <Badge
        className={`bg-pendingColor py-[4px] px-[12px] hover:bg-pendingColor cursor-default ${className}`}
      >
        <Typography variant="regular_12" className="text-gray-800">
          {translate('pending')}
        </Typography>
      </Badge>
    );
  } else if (status === reportStatus.inProgress) {
    return (
      <Badge
        className={`bg-inProgressColor py-[4px] px-[12px] hover:bg-inProgressColor cursor-default ${className}`}
      >
        <Typography variant="regular_12" className="text-gray-800">
          {translate('In progress')}
        </Typography>
      </Badge>
    );
  } else if (status === reportStatus.completed) {
    return (
      <Badge
        className={`bg-completedColor py-[4px] px-[12px] hover:bg-completedColor cursor-default ${className}`}
      >
        <Typography variant="regular_12" className="text-gray-800">
          {translate('completed')}
        </Typography>
      </Badge>
    );
  }
  return (
    <Badge
      className={`bg-rejectedColor py-[4px] px-[12px] hover:bg-rejectedColor cursor-default ${className}`}
    >
      <Typography variant="regular_12" className="text-gray-800">
        {translate('rejected')}
      </Typography>
    </Badge>
  );
};

export default StatusBadge;
