import * as ATypes from "../../shared/actionTypes";
import WEBAPI from "../../services/WEBAPI"
// REMOVE import _ and change to specifics cause you're losing tree shaking
import _ from "lodash";

import * as AnalyticsActions from "../../services/analyticsActions";


const { getSelectedAuthFromProfile, getSelectedCountryFromProfile } = window



export const setNewReportNavigation = (navigationData) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.NR_SET_NAVIGATION,
      payload: {
        navigationData
      }
    })
  };
};

// export const setStepsCompleted = (input) => {
//     return (dispatch, getState) => {
//         dispatch({
//             type: ATypes.INCREMENT_STEPS_COMPLETED,
//             payload: input
//         })
//     };
// }

//LOCATION

export const getAuthForCoordinates = ({ lat, lng }) => {
  return (dispatch, getState) => {
    return WEBAPI.reverseGeocode(lat, lng)
      .then(res => {
        let revGeoAuth = res.result.authority;
        if (revGeoAuth === "") revGeoAuth = "other"

        var a = {
          type: ATypes.NR_SET_SELECTED_AUTHORITY,
          payload: {
            authority: revGeoAuth,
            country: res.result.country,
          }
        }

        dispatch(a)
        return { authority: res.result.authority, country: res.result.country }
      })
  };
};

export const setNewCenterAndAddress = ({ lat, lng }, address) => {
  return (dispatch, getState) => {
    var a = {
      type: ATypes.NR_SET_NEW_CENTER_AND_ADDRESS,
      payload: {
        lat,
        lng,
        address
      }
    }
    dispatch(a)
  };
}

export const getIssues = (authorityId) => {
  return (dispatch, getState) => {
    return WEBAPI.getIssues(authorityId)
      .then(res => {
        dispatch({
          type: ATypes.NR_SET_SELECTED_AUTHORITY_ISSUES,
          payload: res
        })
      })
  };
};

//LOCATION COMBINED ACTIONS

export const locationAcceptProcess = () => {
  return (dispatch, getState) => {

    const { lat, lng } = getState().newReportReducer
    return dispatch(getAuthForCoordinates({ lat, lng }))
      .then((authorityData) => {
        if (_.get(getState(), "otherReducer.params.setAuthority") === "athens") {
          if (authorityData.authority !== "athens") {
            var err = new Error();
            // TODO: ADD TRANSLATION TO TRANSLATIONS XLSX
            err.details = { user_description: "Πρέπει να επιλέξετε μία τοποθεσία μέσα στα όρια του Δήμου Αθηνών για να συνεχίσετε. Για αναφορά σε άλλο Δήμο παρακαλώ κατεβάστε την εφαρμογή novoville." };
            throw err;
          }
        }

        return dispatch(getIssues(authorityData))
      })
      .then(() => dispatch(setNewReportNavigation("SELECT_ISSUE")))
  };
}

//SELECT ISSUES

export const setSelectedIssue = (selectedIssue) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.NR_SET_SELECTED_ISSUE,
      payload: selectedIssue
    })
  };
}

//DESCRIPTION 

export const setDescription = ({ description }) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.NR_SET_DESCRIPTION,
      payload: {
        description
      }
    })
  };
}

//URGENT ISSUE

export const setUrgent = ({ urgent }) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.NR_SET_URGENT,
      payload: {
        urgent
      }
    })
  };
}

//ADD PHOTO

export const addPhoto = (blobPhoto, photoFile) => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.NR_ADD_PHOTO,
      payload: {
        blobPhoto,
        photoFile: _.toPlainObject(photoFile)
      }
    })
  };
}

export const postReport = (captchaKey, captchaValueParam) => {
  
  return (dispatch, getState) => {
    var nrr = getState().newReportReducer
    dispatch({
      type: ATypes.POST_REPORT_REQ,
    })
    var report = {
      country: nrr.selectedCountry,
      authority: nrr.selectedAuthority,
      address: nrr.address,
      longitude: nrr.lng,
      latitude: nrr.lat,
      issue: nrr.selectedIssue,
      description: nrr.description,
      urgent: nrr.urgent ? 1 : 0,
      photo: nrr.blobPhoto,
    }
    return WEBAPI.postReport(report, captchaKey, captchaValueParam)
      .then(res => {
        let authorityOnNovo = res.code === "140" || true;
        AnalyticsActions.newReportEvent(true, "SUCCESS VIEW", authorityOnNovo, false, "ACTIVITY", false, nrr.selectedIssue, nrr.authority);
      })
      .catch(err => {
        AnalyticsActions.newReportEvent(false, "SUCCESS VIEW", false, false, "ACTIVITY", false, nrr.selectedIssue, nrr.authority);
        throw err;
      })
  };
};
export const purgeReducer = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ATypes.NR_PURGE_REDUCER,
    })
  }
}

//TOASK: This is an action with no dispatch that wraps some functionality i dont want to 
//expose to the component. Is this still considered an 'action'?

export const getCoordinatesForAuthority = (authority, country) => {

  let authName = getSelectedAuthFromProfile();
  authName = authName.name ? authName.name : authName;
  if (!authority) {
    authority = authName;
  }

  let countryIso = getSelectedCountryFromProfile();
  countryIso = countryIso.country_iso_code ? countryIso.country_iso_code : countryIso;
  if (!country) {
    country = countryIso
  }
  return WEBAPI.getCoordinatesForAuthority(authority, country)
    .then((res) => {
      return res["result"];
    })
}


//TODO: MOVE THIS
function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
}

