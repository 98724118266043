import React from 'react'

const AnnouncementsIcon = () => (
  <svg width="137" height="136" viewBox="0 0 137 136" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M128.134 59.8516H136.5V75.9169H128.134V59.8516Z" fill="#CBD5E1" />
    <path d="M100.782 70.9251L110.038 72.6722C112.209 73.0819 113.637 75.1753 113.227 77.3479L103.482 129.066C103.072 131.239 100.981 132.668 98.81 132.258L89.5538 130.511C87.383 130.101 85.9551 128.008 86.3645 125.835L96.1101 74.1171C96.5195 71.9445 98.6112 70.5154 100.782 70.9251Z" fill="#CBD5E1" />
    <path d="M91.5374 98.3594L89.3668 97.9492L85.47 109.427L89.3142 110.153L91.5374 98.3594Z" fill="#CBD5E1" />
    <path d="M127.507 48.418L133.363 55.3267V67.2583V68.514V80.4456L127.507 87.3543H100.734V68.514V67.2583V48.418H127.507Z" fill="white" />
    <path d="M89.7009 48.418H100.734V87.3543H89.7009V48.418Z" fill="#CBD5E1" />
    <path d="M89.7012 48.4181V87.3545C89.7012 87.3545 54.1692 85.1295 26.9529 112.37V23.4023C54.1692 50.6408 89.7012 48.4181 89.7012 48.4181Z" fill="white" />
    <g filter="url(#filter0_d_702_17035)">
      <path d="M25.5401 115.769C26.662 115.769 27.5801 114.85 27.5801 113.727V22.0417C27.5801 20.9189 26.662 20 25.5401 20C24.4182 20 23.5 20.9189 23.5 22.0417V113.73C23.5 114.852 24.4182 115.771 25.5401 115.771V115.769Z" fill="#CBD5E1" />
    </g>
    <path d="M27.5802 32.6508C27.5802 32.6508 54.16 54.667 89.7012 54.667V48.4182C89.7012 48.4182 55.8497 50.9297 27.5802 24.0234V32.6508Z" fill="#F1F5F9" />
    <path d="M27.5802 103.137C27.5802 103.137 54.16 81.1211 89.7012 81.1211V87.3699C89.7012 87.3699 55.8497 84.8585 27.5802 111.765V103.137Z" fill="#F1F5F9" />
    <path d="M133.361 59.8516H134.597V75.9169H133.361V59.8516Z" fill="#94A3B8" />
    <path d="M91.5371 98.3604L90.8686 98.2344L88.3386 109.969L89.3139 110.155L91.5371 98.3604Z" fill="#94A3B8" />
    <defs>
      <filter id="filter0_d_702_17035" x="0.5" y="0" width="44.0801" height="135.77" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-3" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_702_17035" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_702_17035" result="shape" />
      </filter>
    </defs>
  </svg>

)

export default AnnouncementsIcon
