import AnnouncementsIcon from '@/resources/AnnouncementsIcon';
import EventsIcon from '@/resources/EventsIcon';
import PollsIcon from '@/resources/PollsIcon';
import React from 'react';
import { translate } from '@/services/TranslationService';
import { Typography } from '@/components/novoville';

const NoMessages = ({ type }) => {
  if (type === 'announcements' || type === 'ANNOUNCEMENT') {
    return (
      <div className="flex flex-col items-center text-center">
        <div className="w-[140px] h-[140px]">
          <AnnouncementsIcon />
        </div>
        <Typography variant="semibold_18" className="text-gray-700 pt-[24px]">
          {translate('NoAnnouncementsTitle')}
        </Typography>
        <Typography variant="regular_16" className="text-gray-700">
          {translate('NoAnnouncementsBody')}
        </Typography>
      </div>
    );
  } else if (type === 'events' || type === 'EVENT') {
    return (
      <div className="flex flex-col items-center text-center">
        <div className="w-[140px] h-[140px]">
          <EventsIcon />
        </div>
        <Typography variant="semibold_18" className="text-gray-700 pt-[24px]">
          {translate('NoEventsTitle')}
        </Typography>
        <Typography variant="regular_16" className="text-gray-700">
          {translate('NoEventsBody')}
        </Typography>
      </div>
    );
  } else if (type === 'polls' || type === 'POLL') {
    return (
      <div className="flex flex-col items-center text-center">
        <div className="w-[140px] h-[140px]">
          <PollsIcon />
        </div>
        <Typography variant="semibold_18" className="text-gray-700 pt-[24px]">
          {translate('NoPollsTitle')}
        </Typography>
        <Typography variant="regular_16" className="text-gray-700">
          {translate('NoPollsBody')}
        </Typography>
      </div>
    );
  } else {
    return null;
  }
};

export default NoMessages;
