export const getItem = (key) => {

        let localStorageIsAvailable
    try {
        localStorageIsAvailable = true
    }
    catch (ex) {
        localStorageIsAvailable = false
    }
    let promise = new Promise((res, rej) => {
        if (localStorageIsAvailable) {
            let val = window.localStorage.getItem(key)
            res(val)
        }
        rej("Couldn't get item")
    })
    return promise;
    

}

export const setItem = (key, value) => {
        let localStorageIsAvailable
    try {
        localStorageIsAvailable = true
    }
    catch (ex) {
        localStorageIsAvailable = false
    }
    let promise = new Promise((res, rej) => {
        if (localStorageIsAvailable) {
            let val = window.localStorage.setItem(key, value)
            res(val)
        }
        else {
            rej("Couldn't set Item")
        }
    })
    return promise;
    
}