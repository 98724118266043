import {
  POST_REGISTER_REQ,
  POST_REGISTER_RES,
  POST_LOGIN_REQ,
  POST_LOGIN_RES,
  POST_ACCOUNT_REQ,
  POST_ACCOUNT_RES,
  SET_FORGOT_PASS_URL,
  REFRESH_TOKEN_RES,
  SET_AUTH_TOKEN_FROM_OLD_APP,
  SET_UUID_FOR_PUSHWOOSH,
} from "../../shared/actionTypes";

export const authReducer = (state = { auth_token: "", loading: false }, action) => {
  switch (action.type) {
    case POST_REGISTER_REQ:
      return {
        ...state,
        loading: true
      };
    case POST_REGISTER_RES:
      return {
        ...state,
        loading: false,
        user_sso_id: action.payload.user_sso_id
      };
    case POST_LOGIN_REQ:
      return {
        ...state,
        loading: true
      };
    case POST_LOGIN_RES:
      return {
        ...state,
        loading: false,
        user_sso_id: action.payload.user_sso_id,
        auth_token: action.payload.token,
        login_type: action.payload.login_type,
        appleUser: action.payload.appleUser,
      };
    case POST_ACCOUNT_REQ:
      return {
        ...state,
        loading: true
      };
    case POST_ACCOUNT_RES:
      return {
        ...state,
        loading: false,
        user_uuid: action.payload
      };
    case SET_FORGOT_PASS_URL:
      return {
        ...state,
        forgotPassUrl: action.payload.forgotPassUrl,
        parkingFinesURL: action.payload.parkingFinesURL,
        minPireAppVersion: action.payload.minPireAppVersion,
        modulrStatus: action.payload.modulrStatus,

      }
    case REFRESH_TOKEN_RES:
      return {
        ...state,
        auth_token: action.data,
      }
    case SET_AUTH_TOKEN_FROM_OLD_APP:
      //these are taken from local storage and this should only ever happen once
      return {
        ...state,
        auth_token: action.payload.token,
        user_sso_id: action.payload.user_sso_id,
        login_type: action.payload.login_type,
      }
    case SET_UUID_FOR_PUSHWOOSH:
      return {
        ...state,
        uuid: action.payload
      }
    case "persist/PURGE":
      return {
        parkingFinesURL: state.parkingFinesURL,
        forgotPassUrl: state.forgotPassUrl,
      }
    default:
      return state;
  }
};
