import React from "react";
import { translate } from "../../../services/TranslationService";

const InformationScreen = ({ title = translate("nodata") }) => {
    return (
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div>
                <i style={{ height: "20%", opacity: 0.15, fontSize: "5em" }} className="material-icons">info</i>
            </div>
            <div style={{ color: "gray" }}>
                {title}
            </div>
        </div>
    )
}

export default InformationScreen;