import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@/components/novoville';
import { toggleSideMenu } from '../../features/sideMenu/actions';
import novoville_logo from '../../resources/novoville_logo.svg';
import { isPireApp } from '../../services/platformCheck';
import { goTo } from '../actions';

const NavigationHeader = ({ isOpen, title }) => {
  const dispatch = useDispatch();
  const municipality = useSelector(
    (state) => state.profileReducer.profile.authority.name
  );

  const allTranslatedAuthorities = useSelector(
    (state) => state.translationsReducer.translations.authorities
  );

  const [currentMunicipality, setCurrentMunicipality] = useState('');

  useEffect(() => {
    const translatedMunicipality = allTranslatedAuthorities[municipality];
    setCurrentMunicipality(translatedMunicipality);
  }, [allTranslatedAuthorities, municipality]);

  const handleToggleSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  return (
    <>
      <div
        className={
          'flex flex-row justify-between items-center bg-white min-h-[80px] w-full border-b border-gray-200'
        }
      >
        <HeaderButton isOpen={isOpen} toggleSideMenu={handleToggleSideMenu} />
        <HeaderLogo municipality={currentMunicipality} />
        <Typography
          variant="bold_24"
          className="hidden sm:inline-block text-black pl-[40px]"
        >
          {title}
        </Typography>
        <div className="sm:hidden block w-[18px] mr-5" />
      </div>
    </>
  );
};

const HeaderButton = ({ toggleSideMenu, isOpen }) => {
  return (
    <div
      className="sm:hidden ml-5 inline-block cursor-pointer"
      onClick={toggleSideMenu}
    >
      <div className={isOpen ? 'bar1menuOpen' : 'bar1'} />
      <div className={isOpen ? 'bar2menuOpen' : 'bar2'} />
      <div
        className={isOpen ? 'bar3menuOpen' : 'bar3'}
        style={{ transformOrigin: '0% 100%' }}
      />
    </div>
  );
};

const HeaderLogo = ({ municipality }) => {
  const dispatch = useDispatch();
  return (
    <div
      className="sm:hidden inline-block self-center"
      onClick={() => dispatch(goTo('home'))}
    >
      <div className="flex flex-col self-center justify-center items-center">
        <img src={novoville_logo} alt="logo" className="w-[116px]" />
        <Typography variant="semibold_14" className="pt-[8px] text-gray-600">
          {municipality}
        </Typography>
      </div>
    </div>
  );
};

export default NavigationHeader;
