import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translate } from '@/services/TranslationService';
import { NovovilleButton, Typography } from '@/components/novoville';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@/components/ui/dialog';
import { deleteAccount } from '../profile/actions';

const DeleteModal = ({ isOpen, onClose }) => {
  const [countdown, setCountdown] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setCountdown(10);
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isOpen]);

  const handleDelete = () => {
    dispatch(deleteAccount());
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onClose}
      className="border-[1px] border-gray-300 rounded-[8px]"
    >
      <DialogContent className="sm:max-w-[425px] flex flex-col">
        <DialogHeader className="text-start">
          <Typography variant="bold_20" className="pb-[8px]">
            {translate('DeleteAccount')}
          </Typography>
        </DialogHeader>
        <Typography variant="regular_16" className="pb-[32px]">
          {translate('del_acc_ver')}
        </Typography>
        <div className="flex flex-row justify-between">
          <Typography variant="semibold_16">
            {`${translate('PleaseWait')}:`}
          </Typography>
          <Typography variant="semibold_16">{`${countdown} ${translate('Seconds')}`}</Typography>
        </div>
        <DialogFooter>
          <div className="flex flex-col gap-[16px] w-full">
            <NovovilleButton
              onClick={handleDelete}
              variant="primary"
              className="w-full bg-errorRed text-white hover:bg-mirarakiRed"
              disabled={countdown > 0}
            >
              {translate('DeleteAccount')}
            </NovovilleButton>
            <NovovilleButton
              onClick={onClose}
              variant="secondary"
              className="w-full"
            >
              {translate('Cancel_NP')}
            </NovovilleButton>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteModal;
