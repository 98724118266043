import MyReportsIcon from '@/resources/MyReportsIcon';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '@/config';
import { getLanguage } from '@/services/GNRL';
import {
  NovovilleBadge,
  NovovilleButton,
  Typography,
} from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import { translate } from '../../services/TranslationService';
import { goTo, goToVerifyEmail } from '../../shared/actions';
import VerifyModal from '../modal/VerifyModal';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import Splash from '../startup/Splash';
import { getReports } from './actions';

const Reports = ({ setHeaderTitle }) => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const myReports = useSelector((state) => state.reportsReducer.myReports);
  const hasVerifiedEmail = useSelector(
    (state) => state.profileReducer.hasVerifiedEmail
  );
  const hasVerifiedPhoneNumber = useSelector(
    (state) => state.profileReducer.hasVerifiedPhoneNumber
  );

  const handleClick = () => {
    if (hasVerifiedEmail && hasVerifiedPhoneNumber) {
      dispatch(goTo('newReport'));
    } else {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.MyReports'));
    dispatch(getReports()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  if (loading) return <Splash />;

  return (
    <div className="flex p-0 sm:p-[40px] max-h-[calc(100vh-80px)] bg-white sm:bg-gray-100 md:bg-gray-100">
      {myReports.length === 0 ? (
        <div className="flex flex-col items-center w-full text-center p-[24px]">
          <MyReportsIcon />
          <Typography variant="semibold_16" className="text-gray-700 pt-[24px]">
            {translate('no_reports_title')}
          </Typography>
          <Typography variant="regular_16" className="text-gray-700 pb-[24px]">
            {translate('no_report_desc')}
          </Typography>
          <VerifyModal
            onClick={handleClick}
            onOpenChange={setIsModalOpen}
            shouldOpen={isModalOpen}
          >
            {translate('newReportLower')}
          </VerifyModal>
        </div>
      ) : (
        <MyReports myReports={myReports} goTo={goTo} />
      )}
    </div>
  );
};

const MyReports = (props) => {
  const { myReports, goTo } = props;
  const noReports = myReports?.length === 0;
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col bg-white w-full p-[24px] sm:p-[32px] rounded-0 sm:rounded-[16px] overflow-scroll">
      {noReports && (
        <div className="flex flex-col items-center text-center">
          <MyReportsIcon />
          <Typography variant="semibold_16" className="text-gray-700 pt-[24px]">
            {translate('no_reports_title')}
          </Typography>
          <Typography variant="regular_16" className="text-gray-700 pt-[8px]">
            {translate('no_reports_desc')}
          </Typography>
          <NovovilleButton
            variant="primary"
            text={translate('newReportLower')}
            className="mt-[24px] w-fit"
            onClick={() => dispatch(goToVerifyEmail({ from: 'reports' }))}
          />
        </div>
      )}
      {!noReports && (
        <>
          {myReports.map((report, index) => (
            <Fragment key={report.id}>
              <MyReportsSectionItem
                key={report.id}
                title={report.issue_name}
                reportStatus={report.status_name}
                reportId={report.id}
                location={report.address}
                reportImage={report.photo}
                reportDate={report.created_at}
                description={report.description}
                goTo={goTo}
              />
              {index < myReports.length - 1 && (
                <Separator className="my-[24px]" />
              )}
            </Fragment>
          ))}
        </>
      )}
    </div>
  );
};

const MyReportsSectionItem = ({
  title,
  reportImage,
  reportStatus,
  reportId,
  location,
  description,
  reportDate,
}) => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('en');
  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'en' ? 'en-GB' : 'el-GR', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <div
      onClick={() =>
        dispatch(
          goTo('reports', reportId, {
            report: {
              id: reportId,
              issue_name: title,
              status_name: reportStatus,
              address: location,
              photo: reportImage,
              created_at: reportDate,
              description: description,
            },
          })
        )
      }
      className="flex flex-grow gap-[8px] w-full cursor-pointer flex-col lg:flex-row"
    >
      <div className="min-w-[172px] min-h-[90px]">
        <img
          src={reportImage}
          alt={title}
          className="w-full h-full sm:w-[172px] sm:h-[134px] rounded-lg object-cover"
        />
      </div>
      <div className="flex flex-col justify-between gap-[8px] w-full">
        <div className="flex flex-col md:flex-row lg:flex-col xl:flex-row items-start md:items-center lg:items-start xl:items-center justify-between gap-[8px]">
          <Typography className="font-open-sans break-words p-0 text-left text-[16px] font-semibold leading-[24px] text-gray-800 no-underline hover:underline">
            {translate(title)}
          </Typography>
          <div className="flex flex-row items-end md:items-center lg:items-start xl:items-center justify-between gap-[16px]">
            <NovovilleBadge status={reportStatus} />
            <div>
              <Typography variant="regular_16" className="text-gray-500">
                {`#${reportId}`}
              </Typography>
            </div>
          </div>
        </div>
        <div>
          <Typography variant="regular_16" className="text-gray-700 text-wrap">
            {description}
          </Typography>
        </div>
        <div>
          <Typography variant="regular_16" className="text-gray-700 text-wrap">
            {location}
          </Typography>
        </div>
        <div>
          <Typography variant="regular_14" className="text-gray-500 text-wrap">
            {`${translate('Reported')} ${formatDate(reportDate)}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withNOVOHeader(Reports);
