import * as ATypes from "../../shared/actionTypes";
import WEBAPI from "../../services/WEBAPI"
import { getAuthorityForCurrentApplication } from '../../services/platformCheck'

export const getCalendar = (id) => {
    return (dispatch, getState) => {
        const authority = getAuthorityForCurrentApplication() ?? getState()?.profileReducer?.profile?.authority?.name;

        dispatch({
            type: ATypes.GET_CALENDAR_REQ
        })

        return WEBAPI.getCalendar(authority)
            .then(res => {
                dispatch({
                    type: ATypes.GET_CALENDAR_RES,
                    payload: res.result.data || []
                })
            })
    };
};