import EventsIcon from '@/resources/EventsIcon';
import React, { useEffect, useState } from 'react';
import { CalendarIcon } from 'lucide-react';
import moment from 'moment';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage } from '@/services/GNRL';
import { Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';
import { translate } from '../../services/TranslationService';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import './Calendar.css';
import { getCalendar } from './actions';

const CalendarItem = ({ event, onClick }) => {
  const eventTime = event?.start_at?.substr(0, 10);

  return (
    <div className="flex space-y-[16px] sm:p-[20px] p-0">
      <div
        key={event.id}
        className={`flex sm:flex-row flex-col items-start gap-4 bg-white p-4 transition-shadow w-full`}
        onClick={onClick}
      >
        <div className="min-w-[172px] min-h-[90px]">
          <img
            src={event.photo}
            alt={event.title}
            className={`mr-4 sm:h-[134px] sm:w-[172px] w-full h-full rounded-lg object-cover`}
          />
        </div>
        <div className="w-full">
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <CalendarIcon className="h-5 w-5 text-purpleLink" />
              <Typography variant="semibold_16" className="text-gray-800">
                {translate(event.app_type)}
              </Typography>
            </div>
            <Typography variant="regular_14" className="text-gray-500">
              {eventTime}
            </Typography>
          </div>
          <Separator className="my-[8px] bg-gray-300" />
          <Typography
            variant="bold_16"
            className="text-gray-800 break-words pb-[6px]"
          >
            {event.title}
          </Typography>
          <Typography variant="regular_16" className="text-gray-700">
            {event.app_type === 'POLL'
              ? `This poll will be active until ${moment(event.valid_until).format('DD-MM-YYYY')}.`
              : event.body}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const NOVOCalendar = (props) => {
  const [selectedDay, setSelectedDay] = useState(moment());
  const [eventsForSelectedDay, setEventsForSelectedDay] = useState([]);
  const dispatch = useDispatch();
  const calendarData = useSelector(
    (state) => state.calendarReducer.calendarData
  );
  const [language, setLanguage] = useState(getLanguage());

  const { setHeaderTitle } = props;

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.Calendar'));
    setLanguage(getLanguage());
    dispatch(getCalendar());
  }, [dispatch, language]);

  const dateChanged = (newDate) => {
    const selectedMoment = moment(newDate);
    const eventsOnThisDay = calendarData.filter((item) => {
      const startAtMoment = moment(item.start_at);
      return selectedMoment.isSame(startAtMoment, 'day');
    });

    setSelectedDay(selectedMoment);
    setEventsForSelectedDay(eventsOnThisDay);
  };

  const getTileClassName = ({ date, view }) => {
    if (view === 'month') {
      const currentMoment = moment(date).format('YYYY-MM-DD');
      const eventStartDate = calendarData.some((item) => {
        const startAtMoment = moment(item.start_at).format('YYYY-MM-DD');
        return currentMoment === startAtMoment;
      });

      if (eventStartDate) {
        return 'event-start-date';
      }
    }
    return '';
  };

  return (
    <div className="flex sm:p-[40px] sm:h-[calc(100vh-120px)] h-[calc(100vh-80px)] sm:pb-0 p-0">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 items-center">
        <Calendar
          onChange={dateChanged}
          showToday={false}
          showDateInput={false}
          next2Label={null}
          prev2Label={null}
          value={selectedDay.toDate()}
          tileClassName={getTileClassName}
          locale={language === 'gr' ? 'el' : 'en'}
        />
        <div className="sm:overflow-auto overflow-y-scroll mt-[32px]">
          {eventsForSelectedDay.length > 0 ? (
            eventsForSelectedDay.map((item) => {
              return (
                <CalendarItem
                  key={item.id}
                  event={item}
                  // TODO: uncomment when backend included the correct id for the message
                  // onClick={() => goTo(`calendar/${item.id}`)}
                />
              );
            })
          ) : (
            <div className="flex flex-col items-center text-center">
              <div className="w-[140px] h-[140px]">
                <EventsIcon />
              </div>
              <Typography
                variant="semibold_16"
                className="text-gray-500 pt-[24px]"
              >
                {translate('no_events_today')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withNOVOHeader(NOVOCalendar);
