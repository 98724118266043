import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';

import { loginProcessAndRoute } from './actions';
import * as AnalyticsActions from '../../services/analyticsActions';
import { translate } from '../../services/TranslationService';
import { areFieldsValid } from '../../services/GNRL';
import Login from './Login';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    if (process.env.ENVIRONMENT === 'development') {
      this.state = {
        email: 'user2@novoville.com',
        password: 'qwerty',
        loading: false,
        errorMessage: '',
      };
    } else {
      this.state = {
        email: '',
        password: '',
        loading: false,
        errorMessage: '',
      };
    }
  }

  onChange = (field, value) => {
    this.setState({ errorMessage: '' });
    if (value.length > 50) return;
    this.setState({
      [field]: value,
    });
  };

  login = (loginType) => {
    const { loginProcessAndRoute } = this.props;
    const { email, password } = this.state;

    let args = [];
    if (loginType === 'EMAIL') {
      args = [email, password, null];
    } else if (loginType === 'FACEBOOK') {
      args = [null, null, 'FACEBOOK'];
    } else if (loginType === 'GOOGLE') {
      args = [null, null, 'GOOGLE'];
    }

    this.setState({ loading: true, errorMessage: '' });
    try {
      loginProcessAndRoute(...args).catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          errorMessage: get(
            err,
            'details.user_description',
            'An error ocurred'
          ),
        });
      });
    } catch (Err) {
      console.log('CAUGHT ERROROAORSORO ', Err);
    }
  };

  forgotPassword = (email) => {
    AnalyticsActions.forgotPassword(email);
  };

  render() {
    const { forgotPassUrl } = this.props;
    const { email, password, loading, errorMessage } = this.state;
    let fieldsValid = areFieldsValid(this.state);

    let childProperties = {
      errorMessage,
      loading,
      email,
      password,
      fieldsValid,
      forgotPassUrl,
      translate,
      onChange: this.onChange,
      login: this.login,
      forgotPassword: this.forgotPassword,
    };

    return this.props.children(childProperties);
  }
}

var mapStateToProps = (state) => {
  return {
    forgotPassUrl: state.authReducer.forgotPassUrl,
  };
};

var mapDispatchToProps = (dispatch) =>
  bindActionCreators({ loginProcessAndRoute }, dispatch);

const NovovilleLoginContainer = (props) => {
  return (
    <LoginContainer {...props}>
      {(props2) => {
        return <Login {...props2} />;
      }}
    </LoginContainer>
  );
};

// THE LOGIN CONTAINER, PLAIN WITHOUT THE NOVOVILLE LOGIC RENDERING COMPONENT
const ConnectedLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
export { ConnectedLoginContainer as LoginContainer };
// THE LOGIN CONTAINER WITH THE NOVOVILLE LOGIC RENDERING COMPONENT
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NovovilleLoginContainer);
