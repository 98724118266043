import MyReportsIcon from '@/resources/MyReportsIcon';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import config from '@/config';
import { goTo } from '@/shared/actions';
import { getLanguage } from '@/services/GNRL';
import { translate } from '@/services/TranslationService';
import { NovovilleBadge, Typography } from '@/components/novoville';
import { Separator } from '@/components/ui/separator';

const MyReportsSection = ({ reports }) => {
  const isScreenMobile = window.innerWidth < config.customMedia.mobile;
  const noReports = reports.length === 0;
  const dispatch = useDispatch();

  return (
    <div className="flex">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
        <div className="flex sm:flex-row md:flex-row flex-col items-center justify-between">
          <Typography variant="bold_20" className="text-gray-900">
            {translate('My Reports')}
          </Typography>
          {!isScreenMobile && !noReports && (
            <Typography
              variant="semibold_16"
              className="leading-[24px] text-gray-500 hover:text-gray-700 cursor-pointer hover:underline"
              onClick={() => dispatch(goTo('reports'))}
            >
              {translate('See all reports')}
            </Typography>
          )}
        </div>
        {noReports && (
          <div className="flex flex-col items-center text-center">
            <MyReportsIcon />
            <Typography
              variant="semibold_18"
              className="text-gray-700 pt-[24px]"
            >
              {translate('no_reports_title')}
            </Typography>
            <Typography variant="regular_16" className="text-gray-700">
              {translate('no_report_desc')}
            </Typography>
          </div>
        )}
        {reports.map((report, index) => (
          <Fragment key={report.id}>
            <MyReportsSectionItem
              key={report.id}
              title={report.issue_name}
              reportStatus={report.status_name}
              reportId={report.id}
              location={report.address}
              reportDate={report.created_at}
            />
            {index < reports.length - 1 && <Separator />}
          </Fragment>
        ))}
        {isScreenMobile && (
          <Typography
            variant="semibold_16"
            className="leading-[24px] text-gray-500 hover:text-gray-700 cursor-pointer hover:underline "
            onClick={() => dispatch(goTo('reports'))}
          >
            {translate('See all reports')}
          </Typography>
        )}
      </div>
    </div>
  );
};

const MyReportsSectionItem = ({
  title,
  reportStatus,
  reportId,
  location,
  reportDate,
}) => {
  const [language, setLanguage] = useState('en');
  const dispatch = useDispatch();

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'en' ? 'en-GB' : 'el-GR', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <div className="flex flex-col gap-[8px]">
      <div className="flex sm:flex-row flex-col sm:items-center md:items-center items-start justify-between gap-[8px]">
        <Typography
          variant="semibold_16"
          className="font-open-sans break-words p-0 text-left text-[16px] font-semibold leading-[24px] text-gray-800 no-underline hover:underline cursor-pointer hover:underline"
          onClick={() => dispatch(goTo(`reports/${reportId}`))}
        >
          {translate(title)}
        </Typography>
        <div className="flex flex-row sm:items-center md:items-center items-end justify-between gap-[24px]">
          <NovovilleBadge status={reportStatus} />
          <div>
            <Typography variant="regular_16" className="text-gray-500">
              {`#${reportId}`}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography variant="regular_16" className="text-gray-700">
          {location}
        </Typography>
      </div>
      <div>
        <Typography variant="regular_14" className="text-gray-500">
          {`${translate('Reported')} ${formatDate(reportDate)}`}
        </Typography>
      </div>
    </div>
  );
};

export default MyReportsSection;
