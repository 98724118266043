import * as ATypes from "../../shared/actionTypes";
import WEBAPI from "../../services/WEBAPI"
import { getAuthorityForCurrentApplication } from "../../services/platformCheck";

export const getReportsForAuthority = () => {
    return (dispatch, getState) => {

        var authorityName = getState().profileReducer.profile.authority.name
        return WEBAPI.getReportsFromAuthority(authorityName)
            .then(res => {
                dispatch({
                    type: ATypes.GET_REPORTS_FOR_AUTHORITY_RES,
                    payload: res.result.data
                })
            })
    };
};

export const getPOISForAuthority = ({ includeTrees } = {}) => {
    return (dispatch, getState) => {

        var authorityName = getState().profileReducer.profile.authority.name
        return WEBAPI.getPOISFromAuthority(authorityName, includeTrees)
            .then(res => {
                dispatch({
                    type: ATypes.GET_POIS_FOR_AUTHORITY_RES,
                    payload: res.result.data
                })
            })
    };
};

export const getPOITYPESForAuthority = () => {
    return (dispatch, getState) => {

        var authorityName = getState().profileReducer.profile.authority.name
        return WEBAPI.getPOITypesForAuthority(authorityName)
            .then(res => {
                dispatch({
                    type: ATypes.GET_POI_TYPES_FOR_AUTHORITY_RES,
                    payload: res.result
                })
            })
    };
};


export const subscribeToPoi = (poi) => {
    return (dispatch, getState) => {
        var authorityName = getState()?.profileReducer?.profile?.authority?.name ?? getAuthorityForCurrentApplication()

        return WEBAPI.subscribeToPOI({ id: poi.id, }, authorityName)
            .then(res => {
                let adoptionId = res.result.adoption_id;
                dispatch({
                    type: ATypes.SUBSCRIBE_TO_POI_RES,
                    payload: { poiId: poi.id, adoptionId }
                })
                return { adoptionId }
            })
    };
}
export const waterTheTree = (tree) => {
    return (dispatch, getState) => {

        let authorityName = getState().profileReducer.profile.authority.name ?? getAuthorityForCurrentApplication()

        return WEBAPI.waterTheTree({ id: tree.id, authority: authorityName })
            .then(res => {
                dispatch({
                    type: ATypes.WATER_THE_TREE_RES,
                    payload: tree.id
                })
            })
    };
}

export const unsubscribeFromPoi = (poi) => {
    return (dispatch, getState) => {
        let authorityName = getState().profileReducer.profile.authority.name ?? getAuthorityForCurrentApplication()
        return WEBAPI.unsubscribeFromPOI({ id: poi.id }, authorityName)
            .then(res => {
                dispatch({
                    type: ATypes.UNSUBSCRIBE_FROM_POI_RES,
                    payload: poi.id
                })

            })
    };
}

export const setFilters = (activeFilters) => {
    return (dispatch, getState) => {
        dispatch({
            type: ATypes.SET_ACTIVE_FILTERS,
            payload: activeFilters
        })
    }
}
