import { get } from "lodash"

const store = window.store;

export const getLanguage = () => {

        let lang = navigator.language.split("-")[0];
    const isAthens = get(store?.getState(), "otherReducer.params.setCountry") === "el"
    if (isAthens) {
        // console.log("\n\n\nFORCING GREECE LANGUAGE\n\n\n")
        //forcing greek in athens
        lang = "el";
    }
    return lang;
    
}