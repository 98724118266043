import React from "react";
import { usedColors } from "../../services/NOVOGNRL";
import { isPireApp } from "../../services/platformCheck";

const NOVOLOADER = ({ size = 22, isDark, borderSize = 6, style }) => {

    let finalStyle = {
        height: size,
        width: size,
        ...style
    }

    finalStyle["border"] = `${borderSize}px solid ${isDark ? "black" : "white"}`
    finalStyle["borderTop"] = `${borderSize}px solid ${isPireApp() ? usedColors.pireAppWebColor : usedColors.reports.teal}`
    return (
        <div className="novoloader" style={finalStyle}>
        </div>
    )
}

export default NOVOLOADER
