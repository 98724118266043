import * as ATypes from "../../shared/actionTypes";

export const sideMenuReducer = (state = {
  isOpen: false
}, action) => {

  switch (action.type) {
    case ATypes.TOGGLE_SIDE_MENU:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    default:
      return state;
  }
};