import React from 'react';
import { Check } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@/components/novoville';
import { translate } from '../../services/TranslationService';
import { setNewReportNavigation } from './actions';

const NewReportNavigationHeader = () => {
  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.newReportReducer.navigation);
  const navigationStepsCompleted = useSelector(
    (state) => state.newReportReducer.navigationStepsCompleted
  );

  const getTitle = (navigation) => {
    switch (navigation) {
      case 'SELECT_LOCATION':
        return translate('NR.location');
      case 'SELECT_ISSUE':
        return translate('NR.selectIssue');
      case 'ADD_DESCRIPTION':
        return translate('NR.addDescription');
      case 'ADD_PHOTO':
        return translate('NR.addPhotos');
      default:
        return '';
    }
  };

  const title = getTitle(navigation);

  const steps = [
    {
      step: 1,
      label: translate('SelectLocationSU'),
      navigation: 'SELECT_LOCATION',
    },
    { step: 2, label: translate('Select Issue'), navigation: 'SELECT_ISSUE' },
    {
      step: 3,
      label: translate('Add description'),
      navigation: 'ADD_DESCRIPTION',
    },
    { step: 4, label: translate('Add photo'), navigation: 'ADD_PHOTO' },
  ];

  return (
    <div className="flex flex-row items-center max-w-[620px] space-x-[12px] p-[8px] mt-[8px] px-[40px]">
      {steps.map((item, index) => (
        <div
          key={index}
          className={`flex items-center rounded-full ${item.step <= navigationStepsCompleted ? 'cursor-pointer' : 'cursor-default'} ${navigationStepsCompleted >= item.step ? 'text-black' : 'text-gray-400'}`}
          onClick={() => {
            if (item.step <= navigationStepsCompleted) {
              dispatch(setNewReportNavigation(item.navigation));
            }
          }}
        >
          <div
            className={`min-w-[24px] min-h-[24px] m-[16px] flex items-center justify-center rounded-full ${navigationStepsCompleted >= item.step ? 'bg-strongCyan' : 'border border-gray-200'} ${navigationStepsCompleted > item.step && 'border-0 bg-strongCyan bg-opacity-30'}`}
          >
            {navigationStepsCompleted > item.step ? (
              <Check className="text-gray-800" size={12} />
            ) : (
              <Typography
                variant="semibold_14"
                className={`${navigationStepsCompleted >= item.step ? 'text-gray-800' : 'text-gray-400'}`}
              >
                {item.step}
              </Typography>
            )}
          </div>
          <Typography
            variant={`${navigation === item.navigation ? 'semibold_14' : 'regular_14'}`}
            className={`${navigation === item.navigation ? 'font-bold' : ''} ${navigationStepsCompleted >= item.step ? 'text-gray-800' : 'text-gray-400'} whitespace-nowrap`}
          >
            {item.label}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default NewReportNavigationHeader;
