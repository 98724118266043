import React from 'react'

const EventsIcon = () => (
  <svg width="126" height="113" viewBox="0 0 126 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_702_17049)">
      <path d="M17.0281 21.293H15.9012C15.5642 21.293 15.291 21.5662 15.291 21.9032V31.1845C15.291 31.5215 15.5642 31.7947 15.9012 31.7947H17.0281C17.3652 31.7947 17.6384 31.5215 17.6384 31.1845V21.9032C17.6384 21.5662 17.3652 21.293 17.0281 21.293Z" fill="#94A3B8" />
      <path d="M32.8973 21.293H31.7704C31.4334 21.293 31.1602 21.5662 31.1602 21.9032V31.1845C31.1602 31.5215 31.4334 31.7947 31.7704 31.7947H32.8973C33.2343 31.7947 33.5075 31.5215 33.5075 31.1845V21.9032C33.5075 21.5662 33.2343 21.293 32.8973 21.293Z" fill="#94A3B8" />
      <path d="M31.1602 22.6838L33.0341 23.1789V21.668H31.1602V22.6838Z" fill="#94A3B8" />
      <path d="M33.508 21.668H33.0339V23.6342H33.508V21.668Z" fill="#CBD5E1" />
      <path d="M48.7664 21.293H47.6395C47.3025 21.293 47.0293 21.5662 47.0293 21.9032V31.1845C47.0293 31.5215 47.3025 31.7947 47.6395 31.7947H48.7664C49.1034 31.7947 49.3766 31.5215 49.3766 31.1845V21.9032C49.3766 21.5662 49.1034 21.293 48.7664 21.293Z" fill="#94A3B8" />
      <path d="M47.0293 22.6838L48.9032 23.1789V21.668H47.0293V22.6838Z" fill="#94A3B8" />
      <path d="M49.3771 21.668H48.9031V23.6342H49.3771V21.668Z" fill="#CBD5E1" />
      <path d="M64.6356 21.293H63.5087C63.1716 21.293 62.8984 21.5662 62.8984 21.9032V31.1845C62.8984 31.5215 63.1716 31.7947 63.5087 31.7947H64.6356C64.9726 31.7947 65.2458 31.5215 65.2458 31.1845V21.9032C65.2458 21.5662 64.9726 21.293 64.6356 21.293Z" fill="#94A3B8" />
      <path d="M62.8984 22.6838L64.7723 23.1789V21.668H62.8984V22.6838Z" fill="#94A3B8" />
      <path d="M65.2462 21.668H64.7722V23.6342H65.2462V21.668Z" fill="#CBD5E1" />
      <path d="M80.5047 21.293H79.3778C79.0408 21.293 78.7676 21.5662 78.7676 21.9032V31.1845C78.7676 31.5215 79.0408 31.7947 79.3778 31.7947H80.5047C80.8417 31.7947 81.1149 31.5215 81.1149 31.1845V21.9032C81.1149 21.5662 80.8417 21.293 80.5047 21.293Z" fill="#94A3B8" />
      <path d="M78.7676 22.6838L80.6415 23.1789V21.668H78.7676V22.6838Z" fill="#94A3B8" />
      <path d="M81.1151 21.668H80.6411V23.6342H81.1151V21.668Z" fill="#CBD5E1" />
      <path d="M96.3738 21.293H95.2469C94.9099 21.293 94.6367 21.5662 94.6367 21.9032V31.1845C94.6367 31.5215 94.9099 31.7947 95.2469 31.7947H96.3738C96.7109 31.7947 96.9841 31.5215 96.9841 31.1845V21.9032C96.9841 21.5662 96.7109 21.293 96.3738 21.293Z" fill="#94A3B8" />
      <path d="M94.6367 22.6838L96.5106 23.1789V21.668H94.6367V22.6838Z" fill="#94A3B8" />
      <path d="M96.9845 21.668H96.5105V23.6342H96.9845V21.668Z" fill="#CBD5E1" />
      <path d="M112.243 21.293H111.116C110.779 21.293 110.506 21.5662 110.506 21.9032V31.1845C110.506 31.5215 110.779 31.7947 111.116 31.7947H112.243C112.58 31.7947 112.853 31.5215 112.853 31.1845V21.9032C112.853 21.5662 112.58 21.293 112.243 21.293Z" fill="#94A3B8" />
      <path d="M110.506 22.6838L112.38 23.1789V21.668H110.506V22.6838Z" fill="#94A3B8" />
      <path d="M112.854 21.668H112.38V23.6342H112.854V21.668Z" fill="#CBD5E1" />
      <path d="M114.135 72.9661L117.402 70.0109V30.4531L114.135 31.8507V72.9661Z" fill="#E2E8F0" />
      <path d="M115.357 23.6367H11.6512C10.7764 23.6368 9.92095 23.8933 9.19041 24.3744L6.50171 26.146L6.56425 32.9308L114.137 32.3937L117.403 30.9605V25.6813C117.403 25.1388 117.187 24.6187 116.804 24.2353C116.42 23.8519 115.9 23.6366 115.357 23.6367Z" fill="#E2E8F0" />
      <path d="M6.5 97.3301C6.5 97.8685 6.71387 98.3848 7.09456 98.7655C7.47526 99.1462 7.99158 99.3601 8.52997 99.3601H114.135V26.1445H6.5V97.3301Z" fill="white" />
      <path d="M116.48 31.8778L115.125 32.4735C115.014 32.5219 114.921 32.6015 114.855 32.7024C114.789 32.8033 114.754 32.9211 114.754 33.0416V73.5638H116.982V32.2169C116.982 32.1571 116.967 32.0983 116.939 32.0455C116.911 31.9927 116.871 31.9477 116.821 31.9142C116.772 31.8808 116.715 31.86 116.655 31.8536C116.596 31.8473 116.536 31.8556 116.48 31.8778Z" fill="#FFFAFD" />
      <path d="M117.869 50.4727H116.291V58.5937H117.869C118.301 58.5937 118.716 58.4219 119.022 58.1161C119.328 57.8103 119.5 57.3955 119.5 56.9629V52.104C119.5 51.8898 119.458 51.6776 119.376 51.4797C119.294 51.2818 119.174 51.1019 119.022 50.9505C118.871 50.799 118.691 50.6788 118.493 50.5968C118.295 50.5149 118.083 50.4727 117.869 50.4727Z" fill="#94A3B8" />
      <path d="M116.449 33.332H116.13V74.0336H116.449V33.332Z" fill="#E2E8F0" />
      <path d="M108.887 39.3438H12.0237C11.1841 39.3438 10.5034 40.0244 10.5034 40.864V92.6717C10.5034 93.5113 11.1841 94.192 12.0237 94.192H108.887C109.727 94.192 110.407 93.5113 110.407 92.6717V40.864C110.407 40.0244 109.727 39.3438 108.887 39.3438Z" fill="#F8FAFC" />
      <path d="M117.029 36.4766H115.451V44.6011H117.029C117.461 44.6011 117.876 44.4292 118.182 44.1233C118.488 43.8174 118.66 43.4024 118.66 42.9698V38.1108C118.661 37.8963 118.619 37.6839 118.537 37.4856C118.455 37.2874 118.335 37.1072 118.183 36.9554C118.032 36.8036 117.852 36.6832 117.654 36.601C117.456 36.5189 117.243 36.4766 117.029 36.4766Z" fill="#CBD5E1" />
      <path d="M117.029 63.6641H115.451V71.7851H117.029C117.243 71.7852 117.455 71.743 117.653 71.6611C117.851 71.5791 118.031 71.459 118.183 71.3075C118.334 71.156 118.454 70.9761 118.536 70.7781C118.618 70.5802 118.66 70.368 118.66 70.1538V65.2954C118.66 65.0812 118.618 64.869 118.536 64.671C118.454 64.4731 118.334 64.2932 118.183 64.1417C118.031 63.9902 117.851 63.87 117.653 63.7881C117.455 63.7061 117.243 63.664 117.029 63.6641Z" fill="#94A3B8" />
      <path d="M115.609 33.8125H115.29V73.562H115.609V33.8125Z" fill="#E2E8F0" />
      <path d="M10.5034 33.3325H108.567C73.4587 31.1781 10.5034 33.3325 10.5034 33.3325Z" fill="#F1F5F9" />
      <path d="M13.3158 31.1024C14.4066 31.1024 15.2909 30.2181 15.2909 29.1274C15.2909 28.0366 14.4066 27.1523 13.3158 27.1523C12.2251 27.1523 11.3408 28.0366 11.3408 29.1274C11.3408 30.2181 12.2251 31.1024 13.3158 31.1024Z" fill="#CBD5E1" />
      <path d="M13.9126 21.293H12.7862C12.7061 21.293 12.6267 21.3088 12.5526 21.3395C12.4785 21.3702 12.4112 21.4152 12.3545 21.4719C12.2979 21.5286 12.2529 21.596 12.2223 21.6701C12.1917 21.7442 12.1759 21.8236 12.176 21.9038V28.0632C12.176 28.3745 12.2997 28.673 12.5198 28.8931C12.7399 29.1132 13.0384 29.2369 13.3497 29.2369C13.661 29.2369 13.9595 29.1132 14.1796 28.8931C14.3997 28.673 14.5234 28.3745 14.5234 28.0632V21.9038C14.5234 21.8236 14.5076 21.7441 14.4769 21.67C14.4462 21.5959 14.4012 21.5286 14.3445 21.4719C14.2878 21.4152 14.2204 21.3702 14.1463 21.3395C14.0722 21.3088 13.9928 21.293 13.9126 21.293Z" fill="#CBD5E1" />
      <path d="M14.513 21.778C14.5768 21.1329 14.1057 20.5583 13.4606 20.4944C12.8155 20.4306 12.2409 20.9018 12.1771 21.5469C12.1133 22.1919 12.5844 22.7666 13.2295 22.8304C13.8746 22.8942 14.4492 22.423 14.513 21.778Z" fill="#CBD5E1" />
      <path d="M16.6542 22.8243C17.2941 22.7204 17.7286 22.1175 17.6247 21.4777C17.5208 20.8379 16.918 20.4034 16.2781 20.5073C15.6383 20.6111 15.2038 21.214 15.3077 21.8538C15.4115 22.4937 16.0144 22.9281 16.6542 22.8243Z" fill="#CBD5E1" />
      <path d="M14.8338 20C12.1287 20 12.1761 21.6693 12.1761 21.6693H17.6382C17.6382 21.6693 17.7119 19.9994 14.8338 20Z" fill="#CBD5E1" />
      <path d="M15.291 22.6838L17.1649 23.1789V21.668H15.291V22.6838Z" fill="#94A3B8" />
      <path d="M17.6388 21.668H17.1648V23.6342H17.6388V21.668Z" fill="#CBD5E1" />
      <path d="M29.941 30.9521C30.9488 30.5347 31.4273 29.3794 31.0099 28.3717C30.5925 27.3639 29.4372 26.8854 28.4294 27.3028C27.4217 27.7202 26.9431 28.8755 27.3605 29.8833C27.778 30.891 28.9333 31.3696 29.941 30.9521Z" fill="#CBD5E1" />
      <path d="M29.7817 21.293H28.6554C28.5752 21.293 28.4958 21.3088 28.4217 21.3395C28.3477 21.3702 28.2804 21.4152 28.2237 21.4719C28.167 21.5286 28.1221 21.596 28.0914 21.6701C28.0608 21.7442 28.0451 21.8236 28.0452 21.9038V28.0632C28.0452 28.3744 28.1687 28.6728 28.3887 28.8929C28.6087 29.113 28.9071 29.2367 29.2183 29.2369C29.5295 29.2369 29.8281 29.1132 30.0482 28.8931C30.2683 28.673 30.3919 28.3745 30.3919 28.0632V21.9038C30.3919 21.7419 30.3277 21.5866 30.2132 21.4721C30.0988 21.3575 29.9436 21.2931 29.7817 21.293Z" fill="#CBD5E1" />
      <path d="M30.3029 22.1186C30.5509 21.5198 30.2665 20.8332 29.6677 20.5852C29.0688 20.3371 28.3823 20.6215 28.1342 21.2204C27.8861 21.8192 28.1705 22.5058 28.7694 22.7538C29.3683 23.0019 30.0548 22.7175 30.3029 22.1186Z" fill="#CBD5E1" />
      <path d="M30.7029 20C27.9979 20 28.0452 21.6693 28.0452 21.6693H33.5074C33.5074 21.6693 33.581 19.9994 30.7029 20Z" fill="#CBD5E1" />
      <path d="M45.8099 30.9521C46.8177 30.5347 47.2962 29.3794 46.8788 28.3717C46.4614 27.3639 45.3061 26.8854 44.2983 27.3028C43.2906 27.7202 42.812 28.8755 43.2294 29.8833C43.6469 30.891 44.8022 31.3696 45.8099 30.9521Z" fill="#CBD5E1" />
      <path d="M45.6509 21.293H44.5245C44.4443 21.293 44.3649 21.3088 44.2909 21.3395C44.2168 21.3702 44.1495 21.4152 44.0928 21.4719C44.0362 21.5286 43.9912 21.596 43.9606 21.6701C43.93 21.7442 43.9142 21.8236 43.9143 21.9038V28.0632C43.9143 28.3745 44.038 28.673 44.2581 28.8931C44.4782 29.1132 44.7767 29.2369 45.088 29.2369C45.3993 29.2369 45.6978 29.1132 45.9179 28.8931C46.138 28.673 46.2617 28.3745 46.2617 28.0632V21.9038C46.2617 21.7418 46.1973 21.5864 46.0828 21.4719C45.9682 21.3573 45.8128 21.293 45.6509 21.293Z" fill="#CBD5E1" />
      <path d="M46.1725 22.1186C46.4206 21.5198 46.1362 20.8332 45.5373 20.5852C44.9385 20.3371 44.2519 20.6215 44.0038 21.2204C43.7558 21.8192 44.0402 22.5058 44.639 22.7538C45.2379 23.0019 45.9244 22.7175 46.1725 22.1186Z" fill="#CBD5E1" />
      <path d="M46.5721 20C43.867 20 43.9144 21.6693 43.9144 21.6693H49.3765C49.3765 21.6693 49.4502 19.9994 46.5721 20Z" fill="#CBD5E1" />
      <path d="M61.6793 30.9521C62.6871 30.5347 63.1656 29.3794 62.7482 28.3717C62.3308 27.3639 61.1754 26.8854 60.1677 27.3028C59.16 27.7202 58.6814 28.8755 59.0988 29.8833C59.5162 30.891 60.6716 31.3696 61.6793 30.9521Z" fill="#CBD5E1" />
      <path d="M61.5202 21.293H60.3939C60.3137 21.293 60.2343 21.3088 60.1602 21.3395C60.0862 21.3702 60.0189 21.4152 59.9622 21.4719C59.9055 21.5286 59.8606 21.596 59.83 21.6701C59.7993 21.7442 59.7836 21.8236 59.7837 21.9038V28.0632C59.7837 28.3744 59.9073 28.6728 60.1272 28.8929C60.3472 29.113 60.6456 29.2367 60.9568 29.2369C61.2681 29.2369 61.5666 29.1132 61.7867 28.8931C62.0068 28.673 62.1305 28.3745 62.1305 28.0632V21.9038C62.1305 21.7419 62.0662 21.5866 61.9518 21.4721C61.8373 21.3575 61.6821 21.2931 61.5202 21.293Z" fill="#CBD5E1" />
      <path d="M62.0414 22.1186C62.2895 21.5198 62.0051 20.8332 61.4062 20.5852C60.8073 20.3371 60.1208 20.6215 59.8727 21.2204C59.6247 21.8192 59.9091 22.5058 60.5079 22.7538C61.1068 23.0019 61.7933 22.7175 62.0414 22.1186Z" fill="#CBD5E1" />
      <path d="M62.4412 20C59.7362 20 59.7835 21.6693 59.7835 21.6693H65.2456C65.2456 21.6693 65.3193 19.9994 62.4412 20Z" fill="#CBD5E1" />
      <path d="M78.1892 30.5255C78.9605 29.7542 78.9605 28.5037 78.1892 27.7324C77.4179 26.9611 76.1674 26.9611 75.3961 27.7324C74.6248 28.5036 74.6248 29.7542 75.3961 30.5255C76.1674 31.2968 77.4179 31.2967 78.1892 30.5255Z" fill="#CBD5E1" />
      <path d="M77.3894 21.293H76.263C76.1829 21.293 76.1035 21.3088 76.0294 21.3395C75.9553 21.3702 75.888 21.4152 75.8314 21.4719C75.7747 21.5286 75.7297 21.596 75.6991 21.6701C75.6685 21.7442 75.6528 21.8236 75.6528 21.9038V28.0632C75.6528 28.3745 75.7765 28.673 75.9966 28.8931C76.2167 29.1132 76.5152 29.2369 76.8265 29.2369C77.1378 29.2369 77.4363 29.1132 77.6564 28.8931C77.8765 28.673 78.0002 28.3745 78.0002 28.0632V21.9038C78.0002 21.7418 77.9358 21.5864 77.8213 21.4719C77.7067 21.3573 77.5514 21.293 77.3894 21.293Z" fill="#CBD5E1" />
      <path d="M77.9105 22.1186C78.1586 21.5198 77.8742 20.8332 77.2754 20.5852C76.6765 20.3371 75.9899 20.6215 75.7419 21.2204C75.4938 21.8192 75.7782 22.5058 76.3771 22.7538C76.9759 23.0019 77.6625 22.7175 77.9105 22.1186Z" fill="#CBD5E1" />
      <path d="M78.3106 20C75.6055 20 75.6529 21.6693 75.6529 21.6693H81.115C81.115 21.6693 81.1887 19.9994 78.3106 20Z" fill="#CBD5E1" />
      <path d="M94.0583 30.5255C94.8296 29.7542 94.8296 28.5036 94.0583 27.7324C93.287 26.9611 92.0365 26.9611 91.2652 27.7324C90.4939 28.5036 90.4939 29.7542 91.2652 30.5255C92.0365 31.2968 93.287 31.2967 94.0583 30.5255Z" fill="#CBD5E1" />
      <path d="M93.2585 21.293H92.1322C92.052 21.293 91.9726 21.3088 91.8985 21.3395C91.8245 21.3702 91.7572 21.4152 91.7005 21.4719C91.6438 21.5286 91.5989 21.596 91.5683 21.6701C91.5376 21.7442 91.5219 21.8236 91.522 21.9038V28.0632C91.522 28.3744 91.6456 28.6728 91.8655 28.8929C92.0855 29.113 92.3839 29.2367 92.6951 29.2369C93.0063 29.2369 93.3049 29.1132 93.525 28.8931C93.7451 28.673 93.8687 28.3745 93.8687 28.0632V21.9038C93.8687 21.7419 93.8045 21.5866 93.69 21.4721C93.5756 21.3575 93.4204 21.2931 93.2585 21.293Z" fill="#CBD5E1" />
      <path d="M93.7799 22.1186C94.028 21.5198 93.7436 20.8332 93.1447 20.5852C92.5459 20.3371 91.8593 20.6215 91.6113 21.2203C91.3632 21.8192 91.6476 22.5058 92.2464 22.7538C92.8453 23.0019 93.5319 22.7175 93.7799 22.1186Z" fill="#CBD5E1" />
      <path d="M94.1795 20C91.4744 20 91.5218 21.6693 91.5218 21.6693H96.9839C96.9839 21.6693 97.0576 19.9994 94.1795 20Z" fill="#CBD5E1" />
      <path d="M109.927 30.5255C110.699 29.7542 110.699 28.5036 109.927 27.7324C109.156 26.9611 107.906 26.9611 107.134 27.7324C106.363 28.5036 106.363 29.7542 107.134 30.5255C107.906 31.2968 109.156 31.2967 109.927 30.5255Z" fill="#CBD5E1" />
      <path d="M109.127 21.293H108.001C107.921 21.293 107.842 21.3088 107.767 21.3395C107.693 21.3702 107.626 21.4152 107.569 21.4719C107.513 21.5286 107.468 21.596 107.437 21.6701C107.407 21.7442 107.391 21.8236 107.391 21.9038V28.0632C107.391 28.3745 107.515 28.673 107.735 28.8931C107.955 29.1132 108.253 29.2369 108.565 29.2369C108.876 29.2369 109.174 29.1132 109.394 28.8931C109.615 28.673 109.738 28.3745 109.738 28.0632V21.9038C109.738 21.7418 109.674 21.5864 109.559 21.4719C109.445 21.3573 109.289 21.293 109.127 21.293Z" fill="#CBD5E1" />
      <path d="M109.649 22.1186C109.897 21.5198 109.612 20.8332 109.014 20.5852C108.415 20.3371 107.728 20.6215 107.48 21.2203C107.232 21.8192 107.516 22.5058 108.115 22.7538C108.714 23.0019 109.401 22.7175 109.649 22.1186Z" fill="#CBD5E1" />
      <path d="M110.049 20C107.344 20 107.391 21.6693 107.391 21.6693H112.853C112.853 21.6693 112.927 19.9994 110.049 20Z" fill="#CBD5E1" />
      <path d="M106.237 53.5471H19.802C18.633 53.5471 17.464 53.529 16.2985 53.5471H16.1448C15.8876 53.5471 15.8876 53.9469 16.1448 53.9469H102.579C103.748 53.9469 104.917 53.965 106.083 53.9469C106.134 53.9469 106.185 53.9469 106.237 53.9469C106.493 53.9469 106.494 53.5471 106.237 53.5471Z" fill="#F1F5F9" />
      <path d="M28.3732 42.5994C28.3732 42.3422 27.974 42.3416 27.974 42.5994V90.5868C27.974 91.2373 27.9594 91.8896 27.974 92.5396C27.974 92.567 27.974 92.598 27.974 92.6226C27.974 92.8797 28.3732 92.8803 28.3732 92.6226V44.6352C28.3732 43.9846 28.3879 43.3329 28.3732 42.6824C28.3727 42.6578 28.3732 42.6274 28.3732 42.5994Z" fill="#F1F5F9" />
      <path d="M41.7055 42.5994C41.7055 42.3422 41.3063 42.3416 41.3063 42.5994V90.5868C41.3063 91.2373 41.2917 91.8896 41.3063 92.5396C41.3063 92.567 41.3063 92.598 41.3063 92.6226C41.3063 92.8797 41.7055 92.8803 41.7055 92.6226V44.6352C41.7055 43.9846 41.7201 43.3329 41.7055 42.6824C41.7049 42.6578 41.7055 42.6274 41.7055 42.5994Z" fill="#F1F5F9" />
      <path d="M55.0386 42.5994C55.0386 42.3422 54.6388 42.3416 54.6388 42.5994V90.5868C54.6388 91.2373 54.6242 91.8896 54.6388 92.5396C54.6388 92.567 54.6388 92.598 54.6388 92.6226C54.6388 92.8797 55.0386 92.8803 55.0386 92.6226V44.6352C55.0386 43.9846 55.0526 43.3329 55.0386 42.6824C55.038 42.6578 55.0386 42.6274 55.0386 42.5994Z" fill="#F1F5F9" />
      <path d="M68.3711 42.5994C68.3711 42.3422 67.9713 42.3416 67.9713 42.5994V90.5868C67.9713 91.2373 67.9567 91.8896 67.9713 92.5396C67.9713 92.567 67.9713 92.598 67.9713 92.6226C67.9713 92.8797 68.3711 92.8803 68.3711 92.6226V44.6352C68.3711 43.9846 68.3857 43.3329 68.3711 42.6824C68.3705 42.6578 68.3711 42.6274 68.3711 42.5994Z" fill="#F1F5F9" />
      <path d="M81.7063 42.5994C81.7063 42.3422 81.3065 42.3416 81.3065 42.5994V90.5868C81.3065 91.2373 81.2919 91.8896 81.3065 92.5396C81.3065 92.567 81.3065 92.598 81.3065 92.6226C81.3065 92.8797 81.7063 92.8803 81.7063 92.6226V44.6352C81.7063 43.9846 81.721 43.3329 81.7063 42.6824C81.7028 42.6578 81.7063 42.6274 81.7063 42.5994Z" fill="#F1F5F9" />
      <path d="M95.0357 42.5994C95.0357 42.3422 94.6359 42.3416 94.6359 42.5994V90.5868C94.6359 91.2373 94.6218 91.8896 94.6359 92.5396C94.6359 92.567 94.6359 92.598 94.6359 92.6226C94.6359 92.8797 95.0357 92.8803 95.0357 92.6226V44.6352C95.0357 43.9846 95.0503 43.3329 95.0357 42.6824C95.0328 42.6578 95.0357 42.6274 95.0357 42.5994Z" fill="#F1F5F9" />
      <path d="M106.237 68.594H19.802C18.633 68.594 17.464 68.5759 16.2985 68.594C16.2471 68.594 16.1962 68.594 16.1448 68.594C15.8876 68.594 15.8876 68.9938 16.1448 68.9938H102.579C103.748 68.9938 104.917 69.0119 106.083 68.9938H106.237C106.493 68.9938 106.494 68.594 106.237 68.594Z" fill="#F1F5F9" />
      <path d="M106.556 83.6409H20.1216C18.9526 83.6409 17.7836 83.6227 16.6181 83.6409H16.4644C16.2072 83.6409 16.2072 84.0407 16.4644 84.0407H102.899C104.068 84.0407 105.237 84.0588 106.402 84.0407C106.454 84.0407 106.505 84.0407 106.556 84.0407C106.813 84.0383 106.813 83.6409 106.556 83.6409Z" fill="#F1F5F9" />
      <g style={{ mixBlendMode: "multiply" }}>
        <path d="M102.133 65.678C102.038 65.2328 101.77 64.8436 101.388 64.5961C101.005 64.3486 100.54 64.2631 100.095 64.3583L89.3492 66.6577C89.1881 66.3978 88.9507 66.194 88.6694 66.0742C88.3881 65.9544 88.0767 65.9244 87.7777 65.9884C87.4788 66.0524 87.2069 66.2072 86.9992 66.4316C86.7916 66.656 86.6584 66.9391 86.6178 67.2422L76.7094 69.3627C76.264 69.4581 75.8747 69.7264 75.6271 70.1087C75.3795 70.491 75.2938 70.956 75.389 71.4015L80.3935 94.7873C80.4889 95.2326 80.7573 95.6217 81.1396 95.8692C81.5219 96.1167 81.9869 96.2023 82.4322 96.1071L105.818 91.1026C106.263 91.0074 106.652 90.7393 106.9 90.3574C107.147 89.9754 107.233 89.5107 107.138 89.0656L102.133 65.678Z" fill="#E0E0E0" />
      </g>
      <path d="M101.557 65.9594C101.51 65.7389 101.42 65.5298 101.292 65.3441C101.164 65.1585 101 64.9998 100.811 64.8773C100.622 64.7547 100.41 64.6706 100.188 64.6299C99.9665 64.5891 99.7388 64.5924 99.5183 64.6396L76.1325 69.6441C75.912 69.6912 75.7029 69.7813 75.5173 69.9093C75.3316 70.0372 75.1729 70.2005 75.0504 70.3898C74.9278 70.579 74.8438 70.7906 74.803 71.0124C74.7622 71.2341 74.7655 71.4618 74.8127 71.6823L79.8166 95.0687C79.9121 95.514 80.1804 95.9031 80.5627 96.1506C80.945 96.3981 81.41 96.4836 81.8553 96.3885L105.241 91.384C105.462 91.3368 105.671 91.2466 105.856 91.1186C106.042 90.9906 106.201 90.8273 106.323 90.638C106.446 90.4486 106.53 90.237 106.571 90.0152C106.611 89.7934 106.608 89.5658 106.561 89.3452L101.557 65.9594Z" fill="#E2E8F0" />
      <path d="M103.028 78.3876L100.631 67.1991C100.588 66.9973 100.506 66.8059 100.388 66.636C100.271 66.4662 100.122 66.321 99.9485 66.2089C99.7752 66.0968 99.5816 66.02 99.3786 65.9828C99.1756 65.9456 98.9673 65.9487 98.7655 65.9921L77.3681 70.5705C76.9605 70.6577 76.6042 70.9032 76.3775 71.2531C76.1508 71.6029 76.0724 72.0285 76.1594 72.4362L78.2776 82.3382L79.7389 89.164C79.8263 89.5714 80.072 89.9274 80.4219 90.1537C80.7717 90.3801 81.1971 90.4582 81.6046 90.371L103.005 85.7937C103.207 85.7506 103.399 85.6681 103.568 85.5509C103.738 85.4338 103.884 85.2843 103.996 85.111C104.108 84.9377 104.185 84.7441 104.222 84.5411C104.259 84.3381 104.256 84.1298 104.212 83.928L103.028 78.3876Z" fill="#FFFAFD" />
      <path d="M103.028 78.3864L97.5955 76.1115C97.4165 76.0365 97.2188 76.0183 97.0292 76.0594C96.8395 76.1004 96.6671 76.1988 96.5352 76.3412L92.6623 80.5163L83.9702 74.6053C83.8629 74.5323 83.7416 74.4821 83.6141 74.458C83.4865 74.4338 83.3553 74.4361 83.2287 74.4648C83.1021 74.4934 82.9827 74.5479 82.878 74.6246C82.7733 74.7014 82.6855 74.7988 82.62 74.911L78.2795 82.337L79.7408 89.1628C79.8283 89.5702 80.0739 89.9262 80.4238 90.1526C80.7736 90.3789 81.1991 90.457 81.6065 90.3698L103.006 85.7926C103.207 85.7494 103.399 85.6669 103.569 85.5498C103.739 85.4326 103.884 85.2831 103.996 85.1099C104.108 84.9366 104.185 84.7429 104.222 84.5399C104.259 84.337 104.256 84.1286 104.213 83.9269L103.028 78.3864Z" fill="#CBD5E1" />
      <path d="M87.753 69.1824C88.5627 69.0509 89.1125 68.288 88.9811 67.4784C88.8496 66.6687 88.0867 66.1189 87.2771 66.2503C86.4674 66.3817 85.9176 67.1446 86.049 67.9543C86.1804 68.764 86.9433 69.3138 87.753 69.1824Z" fill="#94A3B8" />
      <path d="M56.4045 70.7578C55.1338 70.8016 56.6231 73.1233 55.8346 73.0794C55.0461 73.0356 55.3968 73.474 55.3968 73.474C55.3968 73.474 63.7622 81.4904 64.245 81.9346C64.7278 82.3788 65.7471 82.7453 65.3555 81.9621C64.9639 81.1788 65.6402 81.4582 65.6402 81.4582C65.6402 81.4582 66.4386 81.7102 66.9202 81.8855L66.9348 81.8908C67.2855 82.0118 67.5515 81.5582 67.2855 81.2998L56.4045 70.7578Z" fill="#CBD5E1" />
      <path d="M65.6635 72.0544L66.1171 70.8445C66.1292 70.8128 66.1302 70.7779 66.12 70.7456C66.1097 70.7132 66.0889 70.6853 66.0608 70.6663C66.0326 70.6473 65.9989 70.6384 65.9651 70.6411C65.9313 70.6437 65.8993 70.6577 65.8745 70.6808L56.256 79.526C54.9414 81.1579 56.8989 79.9223 55.8772 81.615C55.0542 82.9804 56.256 82.7139 56.256 82.7139C56.256 82.7139 67.6438 72.1894 67.5538 71.8743C67.4637 71.5593 65.6635 72.0544 65.6635 72.0544Z" fill="#CBD5E1" />
      <path d="M26.4234 72.9492H16.9318C16.4973 72.9492 16.145 73.3015 16.145 73.736V75.794C16.145 76.2285 16.4973 76.5807 16.9318 76.5807H26.4234C26.8579 76.5807 27.2102 76.2285 27.2102 75.794V73.736C27.2102 73.3015 26.8579 72.9492 26.4234 72.9492Z" fill="#CBD5E1" />
      <path d="M26.4234 76.9844H16.9318C16.4973 76.9844 16.145 77.3366 16.145 77.7711V79.8291C16.145 80.2636 16.4973 80.6159 16.9318 80.6159H26.4234C26.8579 80.6159 27.2102 80.2636 27.2102 79.8291V77.7711C27.2102 77.3366 26.8579 76.9844 26.4234 76.9844Z" fill="#E2E8F0" />
      <path d="M17.5512 71.2587C17.7998 71.0101 17.7998 70.6071 17.5512 70.3585C17.3026 70.1099 16.8996 70.1099 16.651 70.3585C16.4024 70.6071 16.4024 71.0101 16.651 71.2587C16.8996 71.5073 17.3026 71.5073 17.5512 71.2587Z" fill="#E2E8F0" />
      <path d="M26.8266 70.4766H18.4302C18.2892 70.4766 18.1748 70.5909 18.1748 70.732V70.8863C18.1748 71.0274 18.2892 71.1417 18.4302 71.1417H26.8266C26.9676 71.1417 27.082 71.0274 27.082 70.8863V70.732C27.082 70.5909 26.9676 70.4766 26.8266 70.4766Z" fill="#E2E8F0" />
      <g opacity="0.83">
        <path d="M70.0391 46.3512C70.3906 46.3512 70.6756 46.0662 70.6756 45.7146C70.6756 45.3631 70.3906 45.0781 70.0391 45.0781C69.6876 45.0781 69.4026 45.3631 69.4026 45.7146C69.4026 46.0662 69.6876 46.3512 70.0391 46.3512Z" fill="#CBD5E1" />
        <path d="M79.7646 45.3828H71.3682C71.2272 45.3828 71.1128 45.4972 71.1128 45.6382V45.7925C71.1128 45.9336 71.2272 46.048 71.3682 46.048H79.7646C79.9056 46.048 80.02 45.9336 80.02 45.7925V45.6382C80.02 45.4972 79.9056 45.3828 79.7646 45.3828Z" fill="#CBD5E1" />
      </g>
      <g opacity="0.83">
        <path d="M70.0391 48.5387C70.3906 48.5387 70.6756 48.2537 70.6756 47.9021C70.6756 47.5506 70.3906 47.2656 70.0391 47.2656C69.6876 47.2656 69.4026 47.5506 69.4026 47.9021C69.4026 48.2537 69.6876 48.5387 70.0391 48.5387Z" fill="#CBD5E1" />
        <path d="M79.7646 47.5703H71.3682C71.2272 47.5703 71.1128 47.6847 71.1128 47.8257V47.98C71.1128 48.1211 71.2272 48.2355 71.3682 48.2355H79.7646C79.9056 48.2355 80.02 48.1211 80.02 47.98V47.8257C80.02 47.6847 79.9056 47.5703 79.7646 47.5703Z" fill="#CBD5E1" />
      </g>
      <g opacity="0.83">
        <path d="M70.0391 50.7262C70.3906 50.7262 70.6756 50.4412 70.6756 50.0896C70.6756 49.7381 70.3906 49.4531 70.0391 49.4531C69.6876 49.4531 69.4026 49.7381 69.4026 50.0896C69.4026 50.4412 69.6876 50.7262 70.0391 50.7262Z" fill="#CBD5E1" />
        <path d="M79.7646 49.7578H71.3682C71.2272 49.7578 71.1128 49.8722 71.1128 50.0132V50.1675C71.1128 50.3086 71.2272 50.423 71.3682 50.423H79.7646C79.9056 50.423 80.02 50.3086 80.02 50.1675V50.0132C80.02 49.8722 79.9056 49.7578 79.7646 49.7578Z" fill="#CBD5E1" />
      </g>
    </g>
    <defs>
      <filter id="filter0_d_702_17049" x="0.5" y="15" width="125" height="91.3594" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_702_17049" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_702_17049" result="shape" />
      </filter>
    </defs>
  </svg>
)

export default EventsIcon

