import WEBAPI from '../../services/WEBAPI.js';
import * as ATypes from '../../shared/actionTypes';
import { getDerivedAuthority } from '../authoritySelection/utilities';
import { getMessages } from '../messages/actions';

export const getHomeData = (num = 3) => {
  return (dispatch, getState) => {
    const store = getState();
    const authorityName = getDerivedAuthority(store);

    dispatch({
      type: ATypes.GET_HOME_DATA,
    });

    return WEBAPI.getHomeData(num, authorityName).then((res) => {
      dispatch({
        type: ATypes.SET_HOME_DATA,
        payload: res.result,
      });
    });
  };
};

export const setCameFrom = (from) => {
  return {
    type: ATypes.SET_CAME_FROM,
    payload: from,
  };
};

export const searchAuthorityByLocation = (lat, lng) => {
  return (dispatch, getState) => {
    const store = getState();
    const authorityName = getDerivedAuthority(store);

    dispatch({
      type: ATypes.POST_AUTHORITY_PROMPT_OPTIONS_REQ,
    });

    return WEBAPI.searchAuthorityByLocation(lat, lng, authorityName).then(
      (res) => res.result
    );
  };
};

export const setUserDeclinedChangingAuthorityPrompt = (authorityRoute) => {
  return (dispatch) => {
    dispatch({
      type: ATypes.SET_USER_DECLINED_CHANGING_AUTHORITY,
      payload: authorityRoute,
    });
  };
};
