import React, { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { ImagePlus } from 'lucide-react';
import Pica from 'pica';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '@/config';
import { getLanguage } from '@/services/GNRL';
import { NovovilleButton, Typography } from '@/components/novoville';
import { toast } from '@/components/ui/use-toast';
import { translate } from '../../services/TranslationService';
import {
  addPhoto,
  postReport,
  purgeReducer,
  setNewReportNavigation,
} from './actions';

const pica = new Pica();

class NRPhoto extends Component {
  constructor(props) {
    super();
    this.props = props;

    this.state = {
      loading: false,
      captchaResponse: null,
      language: getLanguage(),
      recaptchaKey: 0,
    };
  }
  recaptchaRef = createRef();

  blobToFile = (blob, name) => {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
  };

  onChange(field, value) {
    if (field === 'avatar') {
      if (value) {
        var reader = new FileReader();
        let imageName = value.name;
        reader.readAsDataURL(value);
        reader.onload = (res) => {
          var image = new Image();
          image.src = res.target.result;
          image.onload = () => {
            var canvas = document.createElement('canvas');
            if (image.width >= image.height) {
              canvas.width = Math.min(800, image.width);
              canvas.height = Math.round(
                image.height * (canvas.width / image.width)
              );
            } else {
              canvas.height = Math.min(800, image.height);
              canvas.width = Math.round(
                image.width * (canvas.height / image.height)
              );
            }
            pica
              .resize(image, canvas, { quality: 0 })
              .then((resizeResult) => {
                console.log(resizeResult);
                return pica.toBlob(resizeResult, 'image/jpeg', 0.8);
              })
              .then((nextResult) => {
                this.props.addPhoto(
                  nextResult,
                  this.blobToFile(nextResult, imageName)
                );
              });
          };
        };
      }
    } else {
      this.setState({
        [field]: value,
      });
    }
  }

  onCaptchaChange = (captchaResponse) => {
    this.setState({ captchaResponse });
  };

  onCaptchaExpired = () => {
    this.setState({ captchaResponse: null });
  };

  handleLanguageChange = (newLanguage) => {
    this.setState((prevState) => ({
      language: newLanguage,
      recaptchaKey: prevState.recaptchaKey + 1,
    }));
  };

  render() {
    const { setNewReportNavigation, blobPhoto, postReport, purgeReducer } =
      this.props;
    const { loading, language, recaptchaKey } = this.state;

    let imageToShow = blobPhoto ? (
      <img
        src={URL.createObjectURL(blobPhoto)}
        alt="report_photo"
        className="w-[170px] h-[170px] object-cover"
      />
    ) : (
      <ImagePlus className="w-[40px] h-[40px] text-gray-300" />
    );

    const isScreenMobile =
      window.innerWidth < config.customMedia.recaptchaCompactThreshold;

    return (
      <div className="flex sm:p-[40px] sm:pt-[32px] sm:pb-0 p-0 sm:h-fit h-[calc(100vh-80px)]">
        <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
          <div className="flex flex-col h-full lg:max-w-fit w-full">
            <div className="flex flex-row justify-between items-center pb-[24px]">
              <Typography variant="bold_20" className="text-gray-800">
                {translate('Add photo')}
              </Typography>
              <Typography
                variant="regular_14"
                className="text-gray-500 lg:hidden block"
              >
                {`${translate('Step')} 4/4`}
              </Typography>
            </div>
            <div className="flex w-full items-center justify-center bg-gray-50">
              <div className="flex flex-col items-center justify-center min-h-[170px] h-fit gap-[16px] p-[16px]">
                {imageToShow}
                <input
                  type="file"
                  id="picture"
                  style={{ display: 'none' }}
                  onChange={(e) => this.onChange('avatar', e.target.files[0])}
                  ref={(fileInput) => (this.fileInput = fileInput)}
                />
                <NovovilleButton
                  variant="secondary"
                  onClick={() => this.fileInput.click()}
                  className="w-full"
                >
                  {translate('Add photo')}
                </NovovilleButton>
              </div>
            </div>
            <div
              style={{ zIndex: 10 }}
              className={
                'flex flex-col text-center mt-20 lg:max-w-fit w-full h-full justify-between'
              }
            >
              <ReCAPTCHA
                key={recaptchaKey}
                style={{
                  height: '100px',
                  paddingBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                size={isScreenMobile ? 'compact' : 'normal'}
                sitekey={process.env.REACT_APP_NV_CAPTCHA_KEY}
                onChange={this.onCaptchaChange}
                onExpired={this.onCaptchaExpired}
                ref={this.recaptchaRef}
                hl={language === 'gr' ? 'el' : 'en'}
              />
              <div className="flex flex-col justify-end h-full sm:h-fit">
                <NovovilleButton
                  variant="primary"
                  className="lg:w-[140px] py-[16px] w-full"
                  loading={loading}
                  disabled={!blobPhoto || !this.state.captchaResponse}
                  onClick={() => {
                    this.setState({ loading: true });
                    const captchaValueParam =
                      this.recaptchaRef.current.getValue();

                    postReport(
                      process.env.REACT_APP_NV_CAPTCHA_KEY,
                      captchaValueParam
                    )
                      .then(() => {
                        setNewReportNavigation('SELECT_LOCATION');
                        this.setState({ loading: false });
                        this.props.history.push('/home');
                        this.props.addPhoto(null, null);
                        this.fileInput.value = '';
                        purgeReducer();
                        toast({
                          title: translate('OTHER.success'),
                          description: translate(
                            'Report submitted successfully'
                          ),
                          variant: 'success',
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({ loading: false });
                        toast({
                          title: translate('OTHER.errorOcurred'),
                          description: err?.details?.user_description,
                          variant: 'destructive',
                        });
                      });
                  }}
                >
                  {translate('SUBMIT')}
                </NovovilleButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

var mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNewReportNavigation,
      addPhoto,
      postReport,
      purgeReducer,
    },
    dispatch
  );

var mapStateToProps = (state) => {
  return {
    blobPhoto: state.newReportReducer.blobPhoto,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NRPhoto)
);
