import WEBAPI from "../../services/WEBAPI";
import * as ATypes from "../../shared/actionTypes";

export const checkVerification = (mobileOrEmail) => {
    return (dispatch, getState) => {
        return WEBAPI.checkIfVerified(mobileOrEmail)
            .then((res) => {
                if (mobileOrEmail?.includes('@')) {
                    dispatch({
                        type: ATypes.CHECK_EMAIL_VERIFY_RES,
                        payload: res.result[`${mobileOrEmail}`]
                    })
                } else {
                    dispatch({
                        type: ATypes.CHECK_MOBILE_VERIFY_RES,
                        payload: res.result[`${mobileOrEmail}`]
                    })
                }

                return res
            })
    };
}