import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NovovilleButton, Typography } from '@/components/novoville';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { withTranslation } from '../../shared/components/withTranslation';
import { setDescription, setNewReportNavigation, setUrgent } from './actions';

const NRDescription = ({ translate }) => {
  const dispatch = useDispatch();
  const { description, urgent } = useSelector(
    (state) => state.newReportReducer
  );

  const onChange = (field, value) => {
    if (value.length > 500) return;
    dispatch(setDescription({ [field]: value }));
  };

  return (
    <div className="flex sm:p-[40px] sm:pt-[32px] sm:h-fit h-[calc(100vh-80px)] sm:pb-0 p-0">
      <div className="flex flex-col bg-white w-full sm:p-[32px] p-[24px] sm:rounded-[16px] rounded-0 gap-[24px]">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="bold_20" className="text-gray-800">
            {translate('Add description')}
          </Typography>
          <Typography
            variant="regular_14"
            className="text-gray-500 lg:hidden block"
          >
            {`${translate('Step')} 3/4`}
          </Typography>
        </div>
        <Textarea
          value={description}
          className="form-control full-width text-light h-[90px]"
          rows="5"
          placeholder={translate('NR.addIssueDescription')}
          onChange={(e) => onChange('description', e.target.value)}
        />
        <div className="flex gap-[8px]">
          <Checkbox
            className="data-[state=checked]:bg-strongCyan data-[state=checked]:border-strongCyan  "
            id="urgent"
            checked={urgent}
            onCheckedChange={() => dispatch(setUrgent(!urgent))}
          />
          <Label htmlFor="urgent">
            <Typography variant="regular_14" className="text-gray-900">
              {translate('Urgent issue')}
            </Typography>
          </Label>
        </div>
        <div className="flex flex-col justify-end h-full sm:h-fit">
          <NovovilleButton
            variant="primary"
            className="lg:w-[140px] py-[16px] w-full"
            onClick={() => dispatch(setNewReportNavigation('ADD_PHOTO'))}
          >
            {translate('NEXT')}
          </NovovilleButton>
        </div>
      </div>
    </div>
  );
};

export default withTranslation(NRDescription);
