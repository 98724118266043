import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from '@/services/TranslationService';
import { getIconForReport } from '../myCity/MyCityHelper';
import MapBoxContainer from '../myCity/MyCityMapContainer';
import { withNOVOHeader } from '../sideMenu/HeaderHOC';
import { ReportItem } from './ReportItem';
import { getSingleReport } from './actions';

const ReportDetails = (props) => {
  const [markerHtmlArray, setMarkerHtmlArray] = useState([]);
  const [report, setReport] = useState(null);
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reportsReducer.myReports);
  const fetchedReport = useSelector((state) => state.reportsReducer.report);
  const { setHeaderTitle } = props;

  useEffect(() => {
    setHeaderTitle(translate('sideMenu.MyReports'));

    const { location } = props;
    const data = location.pathname.split('/');
    const reportId = data[2];

    const foundReport = reports.find(
      (report) => report.id.toString() === reportId.toString()
    );
    setReport(foundReport);

    if (!foundReport || foundReport.id.toString() !== reportId.toString()) {
      dispatch(getSingleReport(reportId));
    }
  }, []);

  useEffect(() => {
    if (
      fetchedReport &&
      (!report || report.id.toString() !== fetchedReport.id.toString())
    ) {
      setReport(fetchedReport);
    }
  }, [fetchedReport]);

  useEffect(() => {
    if (report) {
      const imageElement = new Image(40, 40);
      imageElement.src = '../' + getIconForReport(report.status_name);
      setMarkerHtmlArray([`${report.status_name}`, imageElement]);
    }
  }, [report]);

  if (!report) {
    return <div>Report not found</div>;
  }

  return (
    <ReportItem
      key={report.id}
      report={report}
      showDescription={true}
      map={
        <MapBoxContainer
          zoom={[14]}
          center={[parseFloat(report.longitude), parseFloat(report.latitude)]}
          selectedPoint={{
            latitude: report.latitude,
            longitude: report.longitude,
          }}
          markerHtmlArray={markerHtmlArray}
          onClick={() =>
            window.open(
              `https://www.google.com/maps/@${parseFloat(report.latitude)},${parseFloat(report.longitude)}/?q=${parseFloat(report.latitude)},${parseFloat(report.longitude)}`,
              '_blank'
            )
          }
        />
      }
    />
  );
};

export default withNOVOHeader(withRouter(ReportDetails));
