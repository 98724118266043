import _ from 'lodash';
import { getDeviceType, getLanguage, getPlatformData } from '../services/GNRL';
import * as ATypes from '../shared/actionTypes';
import { translate, translateAPICode } from './TranslationService';
import { getBrowserName } from './platformCheck';
// !!! TODO: REMOVE THESE ITS UGLY AS SIN AND STORE SHOULDNT BE EXPOSING THIS
// !!! ONLY KEEPING IT HERE TO TALK ABOUT THIS WITH SOMEONE
import { dispatch } from './store';
import { getAuthToken } from './store';

let Platform = undefined;

//TODO: make logout process an action also in web
const { logout = () => {} } = window.Storage;

let BASE_URL_APP = process.env.REACT_APP_BASE_URL_APP;
let BASE_URL_SSO = process.env.REACT_APP_BASE_URL_SSO;
let BASE_URL_CHAT = process.env.REACT_APP_BASE_URL_CHAT;

//adds bundle id, type of app, if its web etc
function createMetadataHeader() {
  let data = getBrowserName();

  data += ',' + getDeviceType();
  console.log('header to send:');
  console.log(data);
  return data;
}

function createAmbassadorHeader(constructedURL) {}

class WEBAPI {
  // constructor() {
  //   //TOASK SHOULD THE CLASS BE LIKE THIS OR A SINGLETON AND ADDING THE BASEURL IN THE CONSTRUCTOR?
  // }

  static createHeaders(
    needsCrossAuthorityQuerying,
    constructedURL,
    captchaKey,
    captchaValueParam
  ) {
    let headersObj = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: 0,
      ClientNOVOData: createMetadataHeader(),
      'NV-ambassador': createAmbassadorHeader(constructedURL),
    };

    if (needsCrossAuthorityQuerying) {
      headersObj['X-Novoville-Cross-Authority-Request'] = true;
    }

    if (captchaKey) {
      headersObj['NV-Captcha-Key'] = captchaKey;
    }

    if (captchaValueParam) {
      headersObj['NV-Captcha-Value-Param'] = captchaValueParam;
    }

    const token = getAuthToken();
    if (token) headersObj['Authorization'] = `Bearer ${token}`;
    return headersObj;
  }

  static createHeadersURLFormEncoded(
    needsCrossAuthorityQuerying,
    constructedURL,
    captchaKey,
    captchaValueParam
  ) {
    let headersObj = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ClientNOVOData: createMetadataHeader(),
      'NV-ambassador': createAmbassadorHeader(constructedURL),
    };

    if (needsCrossAuthorityQuerying) {
      headersObj['X-Novoville-Cross-Authority-Request'] = true;
    }

    if (captchaKey) {
      headersObj['NV-Captcha-Key'] = captchaKey;
    }

    if (captchaValueParam) {
      headersObj['NV-Captcha-Value-Param'] = captchaValueParam;
    }

    const token = getAuthToken();
    if (token) headersObj['Authorization'] = `Bearer ${token}`;
    return headersObj;
  }

  static createHeadersMultipart(
    needsCrossAuthorityQuerying,
    constructedURL,
    captchaKey,
    captchaValueParam
  ) {
    let headersObj = {
      Accept: 'application/json',
      ClientNOVOData: createMetadataHeader(),
      'NV-ambassador': createAmbassadorHeader(constructedURL),
    };

    if (captchaKey) {
      headersObj['NV-Captcha-Key'] = captchaKey;
    }

    if (captchaValueParam) {
      headersObj['NV-Captcha-Value-Param'] = captchaValueParam;
    }

    if (needsCrossAuthorityQuerying) {
      headersObj['X-Novoville-Cross-Authority-Request'] = true;
    }

    const token = getAuthToken();
    if (token) headersObj['Authorization'] = `Bearer ${token}`;
    return headersObj;
  }

  static createBaseUrl(authority, apiVersion = 'v5') {
    const splittedUrl = this.baseUrlAPP.split('://');

    if (authority) {
      const authorityReplacedChars = authority.replace(/_/g, '-');
      return {
        url: `https://${authorityReplacedChars}.${splittedUrl[1]}/${apiVersion}/`,
        needsCrossAuthorityQuerying: false,
      };
    }

    return {
      url: `${this.baseUrlAPP}/${apiVersion}/`,
      needsCrossAuthorityQuerying: true,
    };
  }

  static constructURL(url, serviceName, baseURL) {
    let newURL = '';
    if (!baseURL) {
      if (serviceName === 'SSO') {
        newURL = this.baseUrlSSO + url;
      } else if (serviceName === 'CHAT') {
        newURL = this.baseUrlChat + url;
      } else {
        newURL = this.baseUrlAPP + url;
      }

      return newURL;
    } else {
      return baseURL;
    }
  }

  static getURL(
    url,
    serviceName,
    baseURL,
    needsCrossAuthorityQuerying,
    mockData
  ) {
    if (mockData) {
      return new Promise((res, rej) => {
        setTimeout(() => {
          res(mockData);
        }, 0);
      });
    }

    let constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    let headers = this.createHeaders(
      needsCrossAuthorityQuerying,
      constructedURL
    );

    let that = this;
    if (that.enableLogging) {
      console.log('getting url ' + constructedURL);
    }

    let fetchChain = fetch(constructedURL, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log('get url ' + constructedURL + ' response');
          console.log(jsonData);
        }
        if (jsonData['error'] === true) {
          if (jsonData['code'] === '109') {
            return WEBAPI.refreshToken().then((res) => {
              return WEBAPI.getURL(url, serviceName, baseURL);
            });
          } else if (jsonData['code'] === '108') {
            if (logout) logout();
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        } else {
          return jsonData;
        }
      })
      .catch((err) => {
        //here it could be that the error comes from our API, or that something happened with fetch. if so
        if (!_.get(err, 'details.user_description')) {
          err.details = {
            ...err.details,
            user_description: translate('OTHER.errorOcurred'),
          };
        }
        console.log('ERROR IN GET');
        console.log(err);
        throw err;
      });

    return fetchChain;
  }

  static postURL(
    url,
    data,
    serviceName,
    baseURL,
    needsCrossAuthorityQuerying,
    mockData,
    captchaKey,
    captchaValueParam
  ) {
    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData);
      });
    }
    let constructedURL = this.constructURL(url, serviceName, baseURL);
    let headers = this.createHeaders(
      needsCrossAuthorityQuerying,
      constructedURL,
      captchaKey,
      captchaValueParam
    );

    let that = this;
    if (that.enableLogging) {
      console.log('post url ' + constructedURL);
      console.log('with data');
      console.log(data);
    }

    return fetch(constructedURL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data), //TODO is this required?
      // body: data //TODO is this required?
    })
      .then((response) => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log('post url ' + constructedURL + ' response');
          console.log(jsonData);
        }

        if (jsonData['error'] === true) {
          if (jsonData['code'] === '109') {
            return WEBAPI.refreshToken().then((res) => {
              return WEBAPI.postURL(
                url,
                data,
                serviceName,
                baseURL,
                captchaKey,
                captchaValueParam
              );
            });
          } else if (jsonData['code'] === '108') {
            if (logout) logout();
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }

        return jsonData;
      })
      .catch((err) => {
        debugger;
        if (!_.get(err, 'details.user_description')) {
          err.details = {
            ...err.details,
            user_description: translate('OTHER.errorOcurred'),
          };
        }
        console.log('ERROR IN POSTING');
        console.log(err);

        throw err;
      });
  }

  static putURL(
    url,
    data,
    serviceName,
    baseURL,
    needsCrossAuthorityQuerying,
    mockData
  ) {
    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData);
      });
    }

    let constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    let headers = this.createHeadersURLFormEncoded(
      needsCrossAuthorityQuerying,
      constructedURL
    );

    let that = this;
    if (that.enableLogging) {
      console.log('put url ' + constructedURL);
      console.log('with data');
      console.log(data);
    }

    return fetch(constructedURL, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data), //TODO is this required?
    })
      .then((response) => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log('put url ' + constructedURL + ' response');
          console.log(jsonData);
        }

        if (jsonData['error'] === true) {
          if (jsonData['code'] === '109') {
            return WEBAPI.refreshToken().then((res) => {
              return WEBAPI.postURL(url, data, serviceName, baseURL);
            });
          } else if (jsonData['code'] === '108') {
            if (logout) logout();
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }

        return jsonData;
      })
      .catch((err) => {
        if (!_.get(err, 'details.user_description')) {
          err.details = {
            ...err.details,
            user_description: translate('OTHER.errorOcurred'),
          };
        }
        console.log('ERROR IN PUT-TING');
        console.log(err);

        throw err;
      });
  }

  static deleteURL(
    url,
    data,
    serviceName,
    baseURL,
    needsCrossAuthorityQuerying,
    mockData
  ) {
    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData);
      });
    }

    let constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    let headers = this.createHeadersURLFormEncoded(
      needsCrossAuthorityQuerying,
      constructedURL
    );

    let that = this;
    if (that.enableLogging) {
      console.log('delete url ' + constructedURL);
    }

    return fetch(constructedURL, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(data), //TODO is this required?
    })
      .then((response) => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log('delete url ' + constructedURL + ' response');
          console.log(jsonData);
        }

        if (jsonData['error'] === true) {
          if (jsonData['code'] === '109') {
            return WEBAPI.refreshToken().then((res) => {
              return WEBAPI.postURL(url, data, serviceName, baseURL);
            });
          } else if (jsonData['code'] === '108') {
            if (logout) logout();
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }

        return jsonData;
      })
      .catch((err) => {
        if (!_.get(err, 'details.user_description')) {
          err.details = {
            ...err.details,
            user_description: translate('OTHER.errorOcurred'),
          };
        }
        console.log('ERROR IN DELETING');
        console.log(err);

        throw err;
      });
  }

  static postPutPatchUrl({
    url,
    data,
    serviceName,
    baseURL,
    mockData,
    method = 'POST',
    needsCrossAuthorityQuerying = false,
  } = {}) {
    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData);
      });
    }

    let constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    let headers = this.createHeadersURLFormEncoded(
      needsCrossAuthorityQuerying,
      constructedURL
    );

    let that = this;
    if (that.enableLogging) {
      console.log(method + ' url ' + constructedURL);
      console.log('with data');
      console.log(data);
    }

    return fetch(constructedURL, {
      method: method,
      headers: headers,
      body: JSON.stringify(data), //TODO is this required?
    })
      .then((response) => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log(method + ' url ' + constructedURL + ' response');
          console.log(jsonData);
        }

        if (jsonData['error'] === true) {
          if (jsonData['code'] === '109') {
            return WEBAPI.refreshToken().then((res) => {
              return WEBAPI.postURL(url, data, serviceName, baseURL);
            });
          } else if (jsonData['code'] === '108') {
            if (logout) logout();
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }

        return jsonData;
      })
      .catch((err) => {
        if (!_.get(err, 'details.user_description')) {
          err.details = {
            ...err.details,
            user_description: translate('OTHER.errorOcurred'),
          };
        }
        console.log(`ERROR IN ${method}-ING`);
        console.log(err);

        throw err;
      });
  }

  static postURLMultipart(
    url,
    data,
    serviceName,
    baseURL,
    needsCrossAuthorityQuerying,
    mockData,
    captchaKey,
    captchaValueParam
  ) {
    if (mockData) {
      return new Promise((res, rej) => {
        res(mockData);
      });
    }

    let constructedURL = WEBAPI.constructURL(url, serviceName, baseURL);
    let headers = this.createHeadersMultipart(
      needsCrossAuthorityQuerying,
      constructedURL,
      captchaKey,
      captchaValueParam
    );

    let that = this;
    if (that.enableLogging) {
      console.log('post url ' + constructedURL);
      console.log('with data');
      console.log(data);
    }

    let formData = new FormData();
    for (let name in data) {
      console.log(name, ' ', data[name]);

      if (data[name] || data[name] === 0) {
        formData.append(name, data[name]);
      }
    }

    return fetch(constructedURL, {
      method: 'POST',
      headers: headers,
      body: formData,
    })
      .then((response) => {
        checkResponseStatus(response);
        return response.json();
      })
      .then(function (jsonData) {
        if (that.enableLogging) {
          console.log('post url ' + constructedURL + ' response');
          console.log(jsonData);
        }
        if (jsonData['error'] === true) {
          if (jsonData['code'] === '109') {
            return WEBAPI.refreshToken().then((res) => {
              return WEBAPI.postURLMultipart(url, data, serviceName, baseURL);
            });
          } else if (jsonData['code'] === '108') {
            if (logout) logout();
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          } else {
            let err = createWEBAPIError(serviceName, jsonData);
            throw err;
          }
        }
        return jsonData;
      })
      .catch((err) => {
        if (!_.get(err, 'details.user_description')) {
          err.details = {
            ...err.details,
            user_description: translate('OTHER.errorOcurred'),
          };
        }
        console.log('ERROR IN POSTING MULTIPART');
        console.log(err);

        throw err;
      });
  }

  //#region REFRESH TOKEN

  static refreshToken() {
    return this.getURL('auth/refresh_token', 'SSO', null).then((res) => {
      return dispatch({
        type: ATypes.REFRESH_TOKEN_RES,
        data: res.result.token,
      });
    });
  }

  //#endregion
  //#region SPECIFIC ROUTES

  static getCountries() {
    return this.getURL('countries', 'SSO', null).then((jsonData) => {
      return jsonData;
    });
  }

  static getSystemStatus() {
    return this.getURL('system', 'SSO', null).then((jsonData) => {
      if (jsonData['result']['system_status'] !== 'NORMAL') {
        let err = Error('SYSTEM STATUS ERROR');
        throw err;
      }

      let forgotPassUrl = jsonData['result']['forgot_password_url'];
      const { url } = this.createBaseUrl();
      return this.getURL('', '', `${url}system`, false).then((jsonData) => {
        if (jsonData['result']['system_status'] !== 'NORMAL') {
          let err = Error('SYSTEM STATUS ERROR');
          throw err;
        }

        let parkingFinesURL =
          jsonData['result']['parking_app_web_payment_endpoint'];
        //this is for myAthensPass
        let minParkingCitizenAppVersion =
          jsonData['result']['min_parking_citizen_app_version'];
        //this is for swiftParking
        let minSwiftParkingAppVersion =
          jsonData['result']['min_swiftparking_app_version'];
        let minNovovilleAppVersion = jsonData['result']['min_app_version'];
        let minPireAppVersion = jsonData['result']['min_pireapp_app_version'];
        const minKioskParkingAppVersion =
          jsonData['result']['min_kiosk_parking_app_version'];
        const minBBBAppVersion = jsonData?.result?.min_bbbapp_app_version;
        //this is for Shared repairs ModulR
        const modulrStatus = jsonData['result']['modulr_status'];
        const allowAuthWithSocial =
          jsonData['result']['allow_login_with_social_network'];
        return {
          forgotPassUrl,
          parkingFinesURL,
          minParkingCitizenAppVersion,
          minSwiftParkingAppVersion,
          minNovovilleAppVersion,
          minPireAppVersion,
          minKioskParkingAppVersion,
          modulrStatus,
          allowAuthWithSocial,
          minBBBAppVersion,
        };
      });
    });
  }

  static getTranslations(language) {
    let currentlang = language ? language : getLanguage();
    currentlang = currentlang === 'gr' ? 'el' : currentlang;
    const { url } = this.createBaseUrl();

    return this.getURL(`system/translations/${currentlang}`, 'SSO', null)
      .then((translationDataSSO) => {
        return translationDataSSO;
      })
      .then((translationDataSSO) => {
        return this.getURL(
          '',
          '',
          `${url}system/translations/${currentlang}`,
          false
        ).then((translationDataNOVOVILLE) => {
          let responses = {
            ...translationDataSSO.result.responses,
            ...translationDataNOVOVILLE.result.responses,
          };
          let allTranslationsData = {
            ...translationDataSSO.result,
            ...translationDataNOVOVILLE.result,
            responses,
          };
          return allTranslationsData;
        });
      });
  }

  static postRegister(
    email,
    password,
    name,
    surname,
    application,
    skipRegistration,
    captchaKey,
    captchaValueParam
  ) {
    let payload = {
      email,
      password,
      name,
      surname,
      application,
      skipRegistration,
    };
    if (captchaValueParam) {
      payload['recaptcha-hash'] = captchaValueParam;
    }

    return this.postURL(
      'auth/register',
      payload,
      'SSO',
      null,
      false,
      null,
      captchaKey,
      'recaptcha-hash'
    );
  }

  static postLogin(
    email,
    password,
    social_token,
    name,
    surname,
    social_type,
    application
  ) {
    let payload = {
      email,
      password,
      device_type: Platform
        ? Platform.OS === 'ios'
          ? 'ios'
          : 'android'
        : 'webapp',
    };

    //social login logic
    if (social_token) {
      payload['social_token'] = social_token;
    }

    if (name) {
      payload['name'] = name;
    }

    if (surname) {
      payload['surname'] = surname;
    }

    if (social_type) {
      payload['social_type'] = social_type;
    }

    if (application) {
      payload['application'] = application;
    }

    return this.postURL('auth/login', payload, 'SSO', null);
  }

  static postAccount() {
    let device_id = 'error_unspecified';

    device_id = 'browser';
    let data = {
      device_id,
      device_type: getDeviceType(),
      device_version: getPlatformData().name,
      os_version: getPlatformData().os.family,
      notifications: true,
    };
    console.log('data will be send by post account');
    console.log(data);
    return this.postURL('accounts', data, 'SSO', null);
  }

  static getProfile() {
    return this.getURL('profiles', 'SSO', null);
  }

  static postProfile(profileObject) {
    let data = profileObject;
    //TOASK WHOSE JOB IS IT TO ENHANCE THE PROFILE WITH THE REST OF THE FIELDS
    //ASSUMING THE APY DOESNT HANDLE THEIR ABSENCE WELL?
    return this.postURLMultipart('profiles', data, 'SSO', null);
  }
  static deleteAccount() {
    return this.postURL('profiles/delete', null, 'SSO', null, false);
  }

  static getAllAuthoritiesForCountry(countryCode) {
    let postFix = countryCode ? '/' + countryCode : '';

    return this.getURL('authorities' + postFix, 'SSO', null).then(
      (jsonData) => {
        return jsonData;
      }
    );
  }

  static getPropertyFactorsForCountry(countryCode) {
    let postFix = countryCode ? `/${countryCode}` : ``;

    return this.getURL(
      `authorities/property-factor${postFix}`,
      'SSO',
      null
    ).then((jsonData) => {
      return jsonData;
    });
  }

  static getPermissions(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}permissions`,
      needsCrossAuthorityQuerying
    );
  }

  //TODO: add consts to message types, scan document for other places where this is needed
  static getMessages(authorityName, messageId, typesArray) {
    let messagesUrl;
    const { url: baseURL, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);

    if (messageId) {
      messagesUrl = `${baseURL}messages/${messageId}`;
    } else {
      messagesUrl = `${baseURL}messages`;
    }

    //if there are type restrictions add them
    if (typesArray) {
      messagesUrl += `?types=${typesArray.join(',')}`;
    }
    return this.getURL('', '', messagesUrl, needsCrossAuthorityQuerying);
  }

  //these are my reports
  static getReports(authorityName, reportId) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);

    if (authorityName && reportId) {
      //to get a specific report you also need to specify the authority other than its id
      return this.getURL(
        '',
        '',
        `${url}reports/${reportId}`,
        needsCrossAuthorityQuerying
      );
    } else {
      //this will return all reports for your authority
      return this.getURL('', '', `${url}reports`, needsCrossAuthorityQuerying);
    }
  }

  static getMoreReports(nextUrl) {
    return this.getURL('', '', `${nextUrl}`);
  }

  //these are the authority reports ( includes other users )
  static getReportsFromAuthority(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}reports/authorities`,
      needsCrossAuthorityQuerying
    );
  }

  static postReport(report, captchaKey, captchaValueParam) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(
      report.authority
    );

    const payload = {
      ...report,
      'recaptcha-hash': captchaValueParam,
    };

    return this.postURLMultipart(
      '',
      payload,
      '',
      `${url}reports`,
      needsCrossAuthorityQuerying,
      null,
      captchaKey,
      'recaptcha-hash'
    );
  }

  static postPoll({ message_id, answer, authority }) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURLMultipart(
      '',
      { message_id, answer: JSON.stringify(answer), authority },
      '',
      `${url}polls`,
      needsCrossAuthorityQuerying
    );
  }

  static getFAQS(authorityName, language = 'en') {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}faqs?language=${language}`,
      needsCrossAuthorityQuerying
    );
  }

  static getPhones(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL('', '', `${url}phones`, needsCrossAuthorityQuerying);
  }

  static getMoreMessages(url) {
    return this.getURL(null, null, url);
  }

  static verifyRequest(
    mobileOrEmail,
    application,
    language,
    captchaKey,
    captchaValueParam
  ) {
    const data = {
      ...(mobileOrEmail.indexOf('@') !== -1
        ? { email: mobileOrEmail }
        : { mobile: mobileOrEmail }),
      language,
      application,
      'recaptcha-hash': captchaValueParam,
    };

    return this.postURL(
      'verifications/verify_request',
      data,
      'SSO',
      null,
      false,
      null,
      captchaKey,
      'recaptcha-hash'
    );
  }

  static otpVerify({ pin, reason }) {
    return this.postURL(
      'otp/verify',
      { otp: pin, reason },
      'SSO',
      null,
      false,
      null,
      undefined,
      undefined
    );
  }

  static otpRequest({ mobile, reason }) {
    return this.postURL(
      'otp/request',
      { mobile, reason },
      'SSO',
      null,
      false,
      null,
      undefined,
      undefined
    );
  }

  static verify(mobileOrEmail, token) {
    console.log('mobileOrEmail', mobileOrEmail);

    let createdRoute = 'verifications/verify?';
    if (mobileOrEmail.indexOf('@') !== -1) {
      createdRoute = encodeURIComponent(
        createdRoute + 'email=' + mobileOrEmail + '&token=' + token
      );
    } else {
      createdRoute =
        createdRoute +
        'mobile=' +
        mobileOrEmail.replace('+', '') +
        '&token=' +
        token;
    }

    return this.getURL(createdRoute, 'SSO', null);
  }

  static checkIfVerified(mobileOrEmail) {
    let parameter;
    if (mobileOrEmail.indexOf('@') !== -1) {
      parameter = { email: mobileOrEmail };
    } else {
      parameter = { mobile: mobileOrEmail };
    }
    return this.postURL(
      'verifications/verified',
      parameter,
      'SSO',
      null,
      false,
      null,
      undefined,
      undefined
    );
  }

  static getPOISFromAuthority(authorityName, includeTrees) {
    let poisUrl;
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    poisUrl = `${url}pois`;
    //TODO: temporary business logic to accomodate backend
    if (includeTrees) {
      poisUrl += '?types=all';
    }
    return this.getURL('', '', poisUrl, needsCrossAuthorityQuerying);
  }

  static getPOITypesForAuthority(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL('', '', `${url}pois/types`, needsCrossAuthorityQuerying);
  }

  static getIssues(authority) {
    const authorityName = authority.authority ? authority.authority : authority;
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}issues`,
      needsCrossAuthorityQuerying
    ).then((res) => {
      return Object.keys(res.result).map((key) => {
        return { name: res.result[key], translatedName: res.result[key] };
      });
    });
  }

  static getHomeData(num, authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}home/${num}`,
      needsCrossAuthorityQuerying
    );
  }

  static getStatistics(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL('', '', `${url}statistics`, needsCrossAuthorityQuerying);
  }

  static getCoordinatesForAuthority(authority, country) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURL(
      '',
      { authority, country },
      '',
      `${url}geocoding/geocode`,
      needsCrossAuthorityQuerying
    );
  }

  static reverseGeocode(latitude, longitude, authorityName) {
    const { url } = this.createBaseUrl(authorityName);
    return this.postURL(
      '',
      { latitude, longitude },
      '',
      `${url}geocoding/reverse_geocode`,
      false
    );
  }

  static searchAuthorityByLocation(latitude, longitude, authorityName) {
    const { url } = this.createBaseUrl(authorityName);
    return this.postURL(
      '',
      { latitude, longitude },
      '',
      `${url}geoservices`,
      false
    );
  }
  static getLocationForPlaceID(placeId) {
    let request = {
      placeId,
      fields: ['formatted_address', 'geometry'],
    };

    return new Promise((res, rej) => {
      let service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
      service.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej();
        }
      });
    });
  }

  static getForms(id, authority) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    let path = `${url}forms`;

    if (id && authority) {
      path = `${url}forms/${id}`;
    } else if (authority) {
      path = `${url}forms/for-authority`;
    } else {
      path = `${url}forms`;
    }

    return this.getURL('', '', path, needsCrossAuthorityQuerying);
  }

  static getFineDetails(finePaymentCode) {
    return this.getURL('forms/fine/' + finePaymentCode);
  }

  static getFormsForAuthority(authority, serviceTypes) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    if (serviceTypes && serviceTypes.length > 1) {
      serviceTypes = serviceTypes.join(',');
    }
    return this.getURL(
      '',
      '',
      `${url}forms/supported${
        serviceTypes ? '/?service_types=' + serviceTypes : ''
      }`,
      needsCrossAuthorityQuerying
    ).then((res) => res.result);
  }

  static postForm(authority, type, data) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURL(
      '',
      { type, data },
      '',
      `${url}forms`,
      needsCrossAuthorityQuerying
    );
  }

  static postPayment(data, authority) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURL(
      '',
      data,
      `${url}forms/payment`,
      needsCrossAuthorityQuerying
    );
  }

  static getModules(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}system/modules`,
      needsCrossAuthorityQuerying
    );
  }

  static getAuthorityDetails(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL(
      '',
      '',
      `${url}authority_details`,
      needsCrossAuthorityQuerying
    );
  }

  static getCalendar(authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.getURL('', '', `${url}calendar`, needsCrossAuthorityQuerying);
  }

  static subscribeToPOI(data, authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.postURL(
      '',
      data,
      '',
      `${url}pois/subscribe`,
      needsCrossAuthorityQuerying
    );
  }
  static waterTheTree(data) {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(
      data.authority
    );
    return this.postURL(
      '',
      {},
      '',
      `${url}adopt_a_tree/water/${data.id}`,
      needsCrossAuthorityQuerying
    );
  }

  static unsubscribeFromPOI(data, authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return this.postURL(
      '',
      data,
      '',
      `${url}pois/unsubscribe`,
      needsCrossAuthorityQuerying
    );
  }

  static postError(data, token, authorityName) {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    if (token) {
      return this.postURL(
        '',
        data,
        '',
        `${url}system/errors`,
        needsCrossAuthorityQuerying
      );
    } else {
      return this.postURL(
        '',
        data,
        '',
        `${url}system/errors_anonymous`,
        needsCrossAuthorityQuerying
      );
    }
  }
  //#endregion
  //#region PARKING RELATED
  static getActiveTicket = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.getURL(
      '',
      '',
      `${url}mobility/active-ticket`,
      needsCrossAuthorityQuerying
    );
  };

  static getTickets = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.getURL('', '', `${url}tickets`, needsCrossAuthorityQuerying);
  };

  static getTicketsCARPARKS = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}car_parks/tickets`,
      needsCrossAuthorityQuerying
    );
  };

  static getPricingCARPARKS = ({
    authority,
    carParkId,
    carParkZone,
    start_at,
    id,
  }) => {
    let startAtQuery = start_at ? `&start_at=${start_at}` : '';
    const idQuery = id ? `?active_ticket=${id}` : '';
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}car_parks/${carParkId}/zones/${carParkZone}${idQuery}${startAtQuery}`,
      needsCrossAuthorityQuerying
    );
  };

  static activateTicket = (data, authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}tickets`,
      needsCrossAuthorityQuerying
    );
  };

  static activateTicketCARPARKS = (data, authority, carParkId, zoneId) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}car_parks/${carParkId}/zones/${zoneId}/tickets`,
      needsCrossAuthorityQuerying
    );
  };

  static extendTicket = (data, authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}tickets/extension`,
      needsCrossAuthorityQuerying
    );
  };

  static extendTicketCARPARKS = (data, authority, carParkId, zoneId) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}car_parks/${carParkId}/zones/${zoneId}/tickets/extension`,
      needsCrossAuthorityQuerying
    );
  };

  static cancelActiveParkingTicket = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.putURL(
      '',
      undefined,
      '',
      `${url}tickets/cancel`,
      needsCrossAuthorityQuerying
    );
  };

  static cancelActiveParkingTicketCARPARKS = (authority, carParkId, zoneId) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.putURL(
      '',
      undefined,
      '',
      `${url}car_parks/${carParkId}/zones/${zoneId}/tickets/cancel`,
      needsCrossAuthorityQuerying
    );
  };

  static getParkingAvailability = (authority, timestamp) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}tickets/parking/availability${timestamp ? '/' + timestamp : ''}`,
      needsCrossAuthorityQuerying
    );
  };
  static getParkingImage = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}mobility/sign-url`,
      needsCrossAuthorityQuerying
    );
  };

  static getCarParks = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}car_parks`,
      needsCrossAuthorityQuerying
    );
  };

  static checkParkingZone = (data, authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      ``,
      data,
      '',
      `${url}tickets/check_parking_zone`,
      needsCrossAuthorityQuerying
    );
  };
  static getGeozones = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}tickets/parking/zones`,
      needsCrossAuthorityQuerying
    );
  };
  static getAddressFromPostCode = ({ postCode, authority }) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}address/lookup/search?post_code=${postCode}`,
      needsCrossAuthorityQuerying
    );
  };

  static getPlacemarks = ({ authority, typesArray } = {}) => {
    let types = `types=${typesArray.join(',')}`;
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.getURL(
      '',
      '',
      `${url}placemarks?${types}`,
      needsCrossAuthorityQuerying
    );
  };

  //#endregion

  //#region WALLET RELATED
  static getWallet = (authority) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL('', '', `${url}wallet`, needsCrossAuthorityQuerying);
  };

  //TODO: add consts to transction types
  static getTransactions = (authority, typesArray, serviceType) => {
    let types = '';
    if (typesArray) {
      types += `${!!serviceType ? ' &' : '?'}types=${typesArray.join(',')}`;
    }
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}wallet/transactions${
        serviceType ? `?service_type=${serviceType}` : ''
      }${types}`,
      needsCrossAuthorityQuerying
    );
  };

  static getMoreWalletTransactions = (url) => {
    return WEBAPI.getURL(null, null, url);
  };

  static getWalletTopupOptions = (authority, serviceType) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.getURL(
      '',
      '',
      `${url}wallet/topup_options/${serviceType ? serviceType : ''}`,
      needsCrossAuthorityQuerying
    );
  };

  static topupWallet = (authority, data) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}wallet/topup`,
      needsCrossAuthorityQuerying
    );
  };

  static updateBillingInfo = (cardId, data) => {
    return WEBAPI.postURL(`cards/${cardId}/billing_info`, data, 'SSO');
  };

  static postKioskParkingInvoices = (authority, data) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURLMultipart(
      '',
      data,
      '',
      `${url}wallet/transactions/invoices`,
      needsCrossAuthorityQuerying
    );
  };

  static postKioskParkingCheckLicensePlate = (authority, data) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURLMultipart(
      '',
      data,
      '',
      `${url}tickets/check`,
      needsCrossAuthorityQuerying
    );
  };

  static postKioskParkingCheckOffStreetLicensePlate = (
    data,
    authority,
    carParkId,
    zoneId
  ) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURL(
      '',
      data,
      '',
      `${url}car_parks/${carParkId}/zones/${zoneId}/tickets/check`,
      needsCrossAuthorityQuerying
    );
  };

  static checkLicensePlateForOnStreetParkingExtension = (authority, data) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURLMultipart(
      '',
      data,
      '',
      `${url}tickets/extension/check`,
      needsCrossAuthorityQuerying
    );
  };

  static checkLicensePlateForOffStreetParkingExtension = (
    authority,
    data,
    carParkId,
    zoneId
  ) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return this.postURLMultipart(
      '',
      data,
      '',
      `${url}car_parks/${carParkId}/zones/${zoneId}/tickets/extension/check`,
      needsCrossAuthorityQuerying
    );
  };
  //#endregion
  //#region CARDS
  static getCreditCardTokens = ({ application } = {}) => {
    return WEBAPI.getURL(
      `cards${application ? '?application=' + application : ''}`,
      'SSO'
    );
  };

  static deactivateCard = (cardId) => {
    return WEBAPI.putURL(`cards/${cardId}`, null, 'SSO');
  };

  static getRedirectPaymentPage = '/v1/cards/redirect_page';
  //#endregion
  //#region WEBCHAT

  static getDiscussions = (authorityName) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}discussion`,
      needsCrossAuthorityQuerying
    );
  };

  static postAnswer = (
    name,
    said,
    token,
    language,
    target,
    medium,
    client_key,
    preview_expiration_date,
    authorityName
  ) => {
    let data = {
      name,
      said,
      medium,
      user_token: token,
      language,
      client_key,
      preview_expiration_date,
    };
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}discussions/${target}/${medium}`,
      needsCrossAuthorityQuerying
    );
  };
  //#endregion

  //#region SHARED REPAIRS

  static getBuildings = ({ authorityName } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/user_buildings`,
      needsCrossAuthorityQuerying
    );
  };
  static getBuilding = ({ authorityName, buildingId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/buildings/${buildingId}`,
      needsCrossAuthorityQuerying
    );
  };

  static getBuildingsLatLng = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/buildings?lat=${data.latitude}&long=${data.longitude}`,
      needsCrossAuthorityQuerying
    );
  };

  static postBuilding = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURLMultipart(
      '',
      data,
      '',
      `${url}shared_repairs/buildings`,
      needsCrossAuthorityQuerying
    );
  };
  static updateBuilding = ({ authorityName, id, building } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURLMultipart(
      '',
      building,
      '',
      `${url}shared_repairs/buildings/${id}`,
      needsCrossAuthorityQuerying
    );
  };
  static leaveTenement = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURLMultipart(
      '',
      data,
      '',
      `${url}shared_repairs/leave_tenement`,
      needsCrossAuthorityQuerying
    );
  };

  static getBuildingRepairs = ({ authorityName, buildingId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/buildings/${buildingId}/repairs`,
      needsCrossAuthorityQuerying
    );
  };
  static getBuildingInvites = ({ authorityName, buildingId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/invites/building/${buildingId}`,
      needsCrossAuthorityQuerying
    );
  };

  static getBuildingJoinRequests = ({ authorityName, buildingId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/join_requests/building/${buildingId}`,
      needsCrossAuthorityQuerying
    );
  };

  static requestToJoin = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/join_requests`,
      needsCrossAuthorityQuerying
    );
  };

  static getBuildingApartments = ({ authorityName, buildingId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/buildings/${buildingId}/all_apartments`,
      needsCrossAuthorityQuerying
    );
  };

  static getMoreBuildingApartments = (nextUrl) => {
    return WEBAPI.getURL('', '', nextUrl, false);
  };

  static postApartment = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURLMultipart(
      '',
      data,
      '',
      `${url}shared_repairs/apartments`,
      needsCrossAuthorityQuerying
    );
  };
  static deleteApartment = ({ authorityName, apartmentId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.deleteURL(
      '',
      {},
      '',
      `${url}shared_repairs/apartments/${apartmentId}`,
      needsCrossAuthorityQuerying
    );
  };
  static updateApartment = ({ authorityName, apartment, id }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURLMultipart(
      '',
      apartment,
      '',
      `${url}shared_repairs/apartments/${id}`,
      needsCrossAuthorityQuerying
    );
  };

  static associateWithApartment = ({ authorityName, apartmentId, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/apartments/${apartmentId}/associate`,
      needsCrossAuthorityQuerying
    );
  };

  static changeRoleType = ({ authorityName, apartmentId, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      'NOVOVILLE',
      `${url}shared_repairs/apartments/${apartmentId}/change_role`,
      needsCrossAuthorityQuerying
    );
  };

  static getInvites = ({ authorityName } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/invites`,
      needsCrossAuthorityQuerying
    );
  };

  static postInvite = ({ authorityName, data, contractorType } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/invites${
        !!contractorType ? `/${contractorType}` : ''
      }`,
      needsCrossAuthorityQuerying
    );
  };

  static inviteTrader = ({ authorityName, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/contractors`,
      needsCrossAuthorityQuerying
    );
  };

  static getRequestsToJoin = ({ authorityName } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/join_requests/pending`,
      needsCrossAuthorityQuerying
    );
  };

  static getMyRequestsToJoin = ({ authorityName } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/join_requests/my_join_requests`,
      needsCrossAuthorityQuerying
    );
  };

  static postAcceptDeclaration = ({ authorityName, repairId, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/repairs/${repairId}/apartment_declaration_acceptance`,
      needsCrossAuthorityQuerying
    );
  };
  static postCloseVote = ({ authorityName, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/repairs/vote/decision`,
      needsCrossAuthorityQuerying
    );
  };

  static postApproveRequest = ({ authorityName, buildingId, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postPutPatchUrl({
      baseURL: `${url}shared_repairs/join_requests/${buildingId}/approve`,
      data,
      serviceName: '',
      url: null,
      mockData: null,
      method: 'PATCH',
      needsCrossAuthorityQuerying,
    });
  };

  static postAcceptInvite = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/invites/accept`,
      needsCrossAuthorityQuerying
    );
  };

  static getRepairs = ({ authorityName } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/repairs`,
      needsCrossAuthorityQuerying
    );
  };
  static getSingleRepair = ({ authorityName, repairId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/repairs/${repairId}`,
      needsCrossAuthorityQuerying
    );
  };

  static getContractors = ({
    authorityName,
    registrationType,
    selectedSpecialisms,
  } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/contractors?registration_type=${registrationType}&typologies=${encodeURIComponent(
        selectedSpecialisms
      )}`,
      needsCrossAuthorityQuerying
    );
  };

  static postRepair = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURLMultipart(
      '',
      data,
      'NOVOVILLE',
      `${url}shared_repairs/repairs`,
      needsCrossAuthorityQuerying
    );
  };

  static deleteRepair = ({ authorityName, repairId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.deleteURL(
      '',
      {},
      '',
      `${url}shared_repairs/repairs/${repairId}`,
      needsCrossAuthorityQuerying
    );
  };
  static postModulRAccount = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}modulr/create_customer`,
      needsCrossAuthorityQuerying
    );
  };
  static voteInRepair = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/repairs/vote`,
      needsCrossAuthorityQuerying
    );
  };
  static voteInPlaceOfAnother = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/repairs/voteFORANOTHER`,
      needsCrossAuthorityQuerying
    );
  };

  static postAuthorizePaymentToTrader = ({ authorityName, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}modulr/outbound_payment`,
      needsCrossAuthorityQuerying
    );
  };

  static postAuthorizePaymentToCheck = ({ authorityName, data }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}modulr/outbound_payment_check`,
      needsCrossAuthorityQuerying
    );
  };

  static getRepairLocationAndTypeTree = ({ authorityName, date } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}authority_details`,
      needsCrossAuthorityQuerying
    );
  };
  static getJobTemplates = ({ authorityName } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}shared_repairs/job_templates`,
      needsCrossAuthorityQuerying
    );
  };

  static postApartmentsInRepairs = ({ authorityName, data } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      data,
      '',
      `${url}shared_repairs/repairs/${data.repair_id}/apartments`,
      needsCrossAuthorityQuerying
    );
  };

  static deleteImage = ({ authorityName, imageId } = {}) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.deleteURL(
      '',
      null,
      '',
      `${url}shared_repairs/assets/images/${imageId}`,
      needsCrossAuthorityQuerying
    );
  };

  static getChatHistory = ({ id, lastId, authority }) => {
    const withLastId = lastId ? `&last_id=${lastId}` : '';
    return WEBAPI.getURL(
      `v2/chatService/chatMessages/${id}?authority=${authority}${withLastId}`,
      'CHAT'
    );
  };

  static postChatMessage = ({ text, groupId, authority }) => {
    let data = { text: text, authority };
    return WEBAPI.postURL(
      `chatService/chatMessages/${groupId}`,
      data,
      'CHAT',
      null
    );
  };

  //#endregion
  //GDPR CHANGES
  static getSubscriptions = () => {
    return WEBAPI.getURL('subscriptions', 'SSO');
  };

  static patchSubscriptions = ({ data }) => {
    return WEBAPI.postPutPatchUrl({
      url: 'subscriptions',
      data,
      serviceName: 'SSO',
      method: 'PATCH',
    });
  };

  //#region get volunteering
  static getMatchesUnmatched = ({ authorityName }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}get_volunteering/matches/not_matched`,
      needsCrossAuthorityQuerying
    );
  };

  static getMatchesMine = ({ authorityName }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}get_volunteering/matches/my_matches`,
      needsCrossAuthorityQuerying
    );
  };

  static getLogForms = ({ authorityName }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}get_volunteering/logforms`,
      needsCrossAuthorityQuerying
    );
  };

  static acceptMatch = ({ authorityName, match_id }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postPutPatchUrl({
      baseURL: `${url}get_volunteering/matches/${match_id}/accept`,
      method: 'PATCH',
      needsCrossAuthorityQuerying,
    });
  };

  static dropMatch = ({ authorityName, match_id }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postPutPatchUrl({
      baseURL: `${url}get_volunteering/matches/${match_id}/drop`,
      method: 'PATCH',
      needsCrossAuthorityQuerying,
    });
  };
  //#endregion get volunteering

  static getFinesList = ({ authorityName }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}fines/paid`,
      needsCrossAuthorityQuerying
    );
    // convertToAPIResponce([getSingleFine(), getSingleFine(), getSingleFine()]))
  };

  static checkIfFineIsPaid = ({ authorityName, fineId }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}fines/${fineId}/payment/details`,
      needsCrossAuthorityQuerying
    );
    //   convertToAPIResponce(getSingleFine(fineId === "paid")))
  };

  static payAFine = ({ authorityName, fineId, cardId }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      { payment_id: fineId, card_id: cardId },
      '',
      `${url}payments/pay`,
      needsCrossAuthorityQuerying
    );
  };

  static getBillsList = ({ authorityName }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}bills/paid`,
      needsCrossAuthorityQuerying
    );
  };

  static checkIfBillIsPaid = ({ authorityName, billId }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.getURL(
      '',
      '',
      `${url}bills/${billId}/payment/details`,
      needsCrossAuthorityQuerying
    );
  };

  static payAPayment = ({ authorityName, fineId, cardId }) => {
    const { url, needsCrossAuthorityQuerying } =
      this.createBaseUrl(authorityName);
    return WEBAPI.postURL(
      '',
      { payment_id: fineId, card_id: cardId },
      '',
      `${url}payments/pay`,
      needsCrossAuthorityQuerying
    );
  };

  //#region KIOSKPARKING
  static postMeta = ({ authority, transaction_id, meta }) => {
    const { url, needsCrossAuthorityQuerying } = this.createBaseUrl(authority);
    return WEBAPI.postURL(
      '',
      { transaction_id, meta },
      '',
      `${url}tickets/pos-meta`,
      needsCrossAuthorityQuerying
    );
  };
  //#endregion
}

function customErrorHandle(status) {
  const MAINTENANCE_ERROR_CODE = 503;
  if (status === MAINTENANCE_ERROR_CODE) return translate('maintenanceError');
  return translate('defaultErrorContent');
}

function checkResponseStatus(response) {
  if (response.status >= 500) {
    console.log(response);
    let err = Error('Server response not ok.');
    err.details = {
      response: response.status,
      user_description: customErrorHandle(response.status),
    };
    throw err;
  }
}

function serviceAndCodeToTranslatable(serviceName, apiCode) {
  if (serviceName === 'SSO') {
    return 'ssoapi_' + apiCode;
  } else {
    return 'api_' + apiCode;
  }
}

function createWEBAPIError(serviceName, jsonData) {
  let code = serviceAndCodeToTranslatable(serviceName, jsonData['code']);
  let err = new WebApiError('API Error', code);
  let user_description = translateAPICode(
    serviceAndCodeToTranslatable(serviceName, jsonData['code'])
  );
  let dynamicTranslationArgs = jsonData['args'];

  if (dynamicTranslationArgs) {
    Object.keys(dynamicTranslationArgs).forEach((key) => {
      let regex = new RegExp(key, 'g');
      user_description = user_description.replace(
        regex,
        dynamicTranslationArgs[key]
      );
    });
  }

  err.details = {
    error_code: jsonData['code'],
    error_result: jsonData['result'],
    user_description,
  };
  return err;
}

WEBAPI.baseUrlAPP = BASE_URL_APP;
WEBAPI.baseUrlSSO = BASE_URL_SSO;
WEBAPI.baseUrlChat = BASE_URL_CHAT;
WEBAPI.enableLogging = true;
WEBAPI.redirectPaymentPage = WEBAPI.constructURL('cards/redirect_page', 'SSO');
// WEBAPI.enableLogging = false;

export default WEBAPI;

class WebApiError extends Error {
  constructor(message, code) {
    super(message);
    this.type = 'WEBAPI Error';
    this.code = code;
  }
}
