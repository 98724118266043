import * as ATypes from '../../shared/actionTypes';

const ATHENS_LAT = 37.979683;
const ATHENS_LNG = 23.728984;
const PIREAPP_LNG = 23.64619;
const PIREAPP_LAT = 37.94203;

export const newReportReducer = (state, action) => {
  const defaultState = {
    navigation: 'SELECT_LOCATION',
    urgent: false,
    description: '',
    lat: ATHENS_LAT,
    lng: ATHENS_LNG,
    address: '',
    navigationStepsCompleted: 1,
    loading: false,
    reportPostedSuccesfuly: false,
    reportPosted: false,
    selectedIssue: null,
    selectedAuthority: null,
    selectedAuthorityIssues: [],
    blobPhoto: null,
    photo: null,
  };

  if (!state) {
    state = defaultState;
  }

  switch (action.type) {
    case ATypes.NR_SET_NAVIGATION:
      let navigationStepsCompleted;
      switch (action.payload.navigationData) {
        case 'SELECT_LOCATION':
          navigationStepsCompleted = 1;
          break;
        case 'SELECT_ISSUE':
          navigationStepsCompleted = 2;
          break;
        case 'ADD_DESCRIPTION':
          navigationStepsCompleted = 3;
          break;
        case 'ADD_PHOTO':
          navigationStepsCompleted = 4;
          break;
        default:
          navigationStepsCompleted = 1;
      }

      return {
        ...state,
        navigation: action.payload.navigationData,
        navigationStepsCompleted,
      };

    case ATypes.NR_SET_NEW_CENTER_AND_ADDRESS:
      return {
        ...state,
        address: action.payload.address,
        lat: action.payload.lat,
        lng: action.payload.lng,
      };
    case ATypes.NR_SET_SELECTED_AUTHORITY:
      return {
        ...state,
        selectedAuthority: action.payload.authority,
        selectedCountry: action.payload.country,
      };
    case ATypes.NR_SET_SELECTED_AUTHORITY_ISSUES:
      return {
        ...state,
        selectedAuthorityIssues: action.payload,
      };
    case ATypes.NR_SET_SELECTED_ISSUE:
      return {
        ...state,
        selectedIssue: action.payload,
      };
    case ATypes.NR_SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description,
      };
    case ATypes.NR_SET_URGENT:
      return {
        ...state,
        urgent: action.payload.urgent,
      };
    case ATypes.NR_ADD_PHOTO:
      return {
        ...state,
        blobPhoto: action.payload.blobPhoto,
        photo: action.payload.photoFile,
      };
    case ATypes.POST_REPORT_REQ:
      return {
        ...state,
      };
    case ATypes.POST_REPORT_RESP:
      return {
        ...state,
      };
    case ATypes.NR_PURGE_REDUCER:
      return defaultState;
    default:
      return state;
  }
};
