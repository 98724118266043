import * as ATypes from "../../shared/actionTypes";
import { properlyFormatPollsApiMessages } from "../../services/NOVOGNRL";

export const messagesReducer = (state, action) => {

    const defaultState = {
        messages: [],
        nextUrl: null,
        loading: true,
        atLastQuestion: false,
        currentQuestion: 0
    }

    if (!state) {
        state = defaultState
    }

    switch (action.type) {
        case ATypes.GET_MESSAGES_RES:
            return {
                ...state,
                messages: properlyFormatPollsApiMessages(action.payload.result.data),
                nextUrl: action.payload.result.next_page_url,
            };
        case ATypes.GET_MORE_MESSAGES_RES:
            return {
                ...state,
                messages: [...state.messages, ...properlyFormatPollsApiMessages(action.payload.result.data)],
                nextUrl: action.payload.result.next_page_url,
            };
        case ATypes.GET_SINGLE_MESSAGE_REQ:
            {
                return {
                    ...state,
                    loading: true
                }
            }
        case ATypes.GET_SINGLE_MESSAGE_RES:
            {
                let activeMessage;
                if (action.payload.result.data) {
                    //it means the result is an empty array
                    activeMessage = properlyFormatPollsApiMessages(action.payload.result.data[0])
                } else {
                    activeMessage = null;
                }

                return {
                    ...state,
                    activeMessage,
                    loading: false
                }
            }
        case ATypes.POST_POLL_RES:
        //TODO: Change POLL locally as answered
        //KEEP IN MIND YOU ALSO NEED TO DO THAT TO THE MESSAGES REDUCER AS THE SAME
        //POLL MIGHT EXIST. IDEA: ONLY HAVE ONE REDUCER FOR MESSAGES AND DECORATE
        //THE MESSAGE OBJECTS WITH PROPERTIES TO KNOW WHICH BELONG TO WHAT PAGE.
        case "persist/PURGE":
            return defaultState;

        default:
            return state;
    }
};