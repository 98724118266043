//TODO: RENAME, SHOULDNT BE CAPITAL ITS NOT A COMPONENT

var gasStationPin = 'images2/mycity/gasStationPin.png';
var landmarksPin = 'images2/mycity/landmarksPin.png';
var museumsPin = 'images2/mycity/museumsPin.png';
var pharmaciesPin = 'images2/mycity/pharmaciesPin.png';
var policePin = 'images2/mycity/policePin.png';
var publicBuildingPin = 'images2/mycity/publicBuildingPin.png';
var ic_poi_adopt_a_tree = 'images2/mycity/ic_poi_adopt_a_tree.png';
var ic_poi_adopt_a_tree_owned_and_mine =
  'images2/mycity/ic_poi_adopt_a_tree_owned_and_mine.png';
var ic_poi_adopt_a_tree_owned5 =
  'images2/mycity/ic_poi_adopt_a_tree_owned5.png';
var redPin = 'images2/other/redPin.png';
var bluePin = 'images2/other/bluePin.png';
var yellowPin = 'images2/other/yellowPin.png';
var rejectedPin = 'images2/other/rejectedPin.png';

export function getIconForPOI(poi) {
  const type = poi.type;
  switch (type) {
    case 'PHARMACY':
      return pharmaciesPin;
    case 'MUSEUM':
      return museumsPin;
    case 'PUBLIC_BUILDING':
      return publicBuildingPin;
    case 'GAS_STATION':
      return gasStationPin;
    case 'LANDMARK':
      return landmarksPin;
    case 'POLICE':
      return policePin;
    case 'ADOPT_A_TREE':
      if (poi.user_subscriptions_count) {
        if (poi.is_subscribed) {
          return ic_poi_adopt_a_tree_owned_and_mine;
        } else {
          return ic_poi_adopt_a_tree_owned5;
        }
      } else {
        return ic_poi_adopt_a_tree;
      }
    default:
      return pharmaciesPin;
  }
}

export function getIconForReport(status) {
  switch (status) {
    case 'completed':
      return bluePin;
    case 'inprogress':
      return yellowPin;
    case 'pending':
      return redPin;
    case 'rejected':
      return rejectedPin;
    default:
      return undefined;
  }
}
export const getAdoptTreeType = (poi) => {
  if (poi.user_subscriptions_count) {
    if (poi.is_subscribed) {
      return 'ADOPT_A_TREE_OWNED_MINE';
    } else {
      return 'ADOPT_A_TREE_OWNED';
    }
  } else {
    return 'ADOPT_A_TREE_';
  }
};

export const getFeatureCollection = (reportsAndPois = []) => {
  return reportsAndPois.map((item) => {
    if (!item) return;
    if (item.type) {
      return {
        type: 'Feature',
        id: item.type + item.id,
        properties: {
          type: item.type,
          id: item.id,
          iconName:
            item.type === 'ADOPT_A_TREE' ? getAdoptTreeType(item) : item.type,
          iconSrc: getIconForPOI(item),
          point: item,
          status: null,
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
        },
      };
    } else {
      return {
        type: 'Feature',
        id: 'REPORTS' + item.id,
        properties: {
          type: 'REPORTS',
          id: item.id,
          iconName: item.status_name,
          iconSrc: getIconForReport(item.status_name),
          point: item,
          status: item.status_name,
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
        },
      };
    }
  });
};

export const getFeatureCollectionMarkers = (featureCollection = []) => {
  const markerHtmlArray = [];

  featureCollection.forEach((point) => {
    if (!point) return;
    const imageElement = new Image(40, 40);
    imageElement.src = point.properties.iconSrc;
    const exist = markerHtmlArray.some(
      (img) => img[0] === point.properties.iconName
    );
    if (!exist) markerHtmlArray.push([point.properties.iconName, imageElement]);
  });
  return markerHtmlArray;
};
